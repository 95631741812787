import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import * as XLSX from 'exceljs/dist/exceljs.min.js';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { AutoComplete } from 'primeng/autocomplete';
import { Table } from 'primeng/table';

import { CreateBondComponent } from '../create-bond/create-bond.component';
import { EditBondComponent } from './edit-bond/edit-bond.component';

import { AmountTypeEnum, BLCTypeEnum, BondStatusEnum } from '../../models/enums';
import { CommonFunctions } from '../../functions';
import {
    AmountSubType, AmountType, Bank, BankWithFinancialGuarantee, Bond, BondsFilter,
    Community, Company, Document, ExportSearchResult, User
} from '../../models';
import {
    AmountSubTypeService, AmountTypeService, AuthorizationService, BankService, BondService,
    CommunityService, CompanyService, DocumentService, ExcelService, UserService
} from '../../services';

@Component({
    selector: 'app-search-bond',
    templateUrl: './search-bond.component.html',
    styleUrls: ['./search-bond.component.css'],
    providers: [MessageService]
})
export class SearchBondComponent implements OnInit {
    @ViewChild('communityNumber') communityAutocomplete: AutoComplete;
    @ViewChild(CreateBondComponent) addModal: CreateBondComponent;
    @ViewChild(EditBondComponent) editModal: EditBondComponent;
    @ViewChild('tableBonds', { static: true }) tableBonds!: Table;

    advancedSearchAvailable: boolean = true;
    allAmountSubTypeList: AmountSubType[] = [];
    amountTypeList: AmountType[];
    availableCommitment: any = null;
    bankData: BankWithFinancialGuarantee;
    blcType = BLCTypeEnum;
    bondAndLetterOfCreditNumber: string;
    bondData: Bond;
    bondId: number;
    bondModifierId: number;
    bondStatus = BondStatusEnum;
    bondType: number;
    cols: any[] = [
        { field: 'number', subfield: '', header: 'Bond/LC', width: '6%' },
        { field: 'companyName', subfield: '', header: 'Company', width: '11%' }, // 17%
        { field: 'communityName', subfield: '', header: 'Community', width: '15%' }, // 32%
        { field: 'bank', subfield: 'name', header: 'Bank/Surety', width: '14%' }, // 53%
        { field: 'beneficiary', subfield: '', header: 'Beneficiary', width: '12%' }, // 65%
        { field: 'amountType', subfield: 'name', header: 'Bond/LC Type', width: '9%' }, // 74%
        { field: 'currentAmount', subfield: '', header: 'Current Amount', width: '6%' }, // 80%
        { field: 'dateIssued', subfield: '', header: 'Issue Date', width: '7%' }, // 87%
        { field: 'dateCanceled', subfield: '', header: 'Cancel Date', width: '7%' } // 94% 6% Remainder for edit column
    ];
    communityList: Community[];
    companyList: Company[];
    currentUser: User;
    displayAddDialog: boolean = false;
    displayAmountSubTypeList: AmountSubType[];
    displayBankList: Bank[];
    displayEditDialog: boolean = false;
    documents: Document[];
    exportHeader: string[] = [];
    filteredCommunities: Community[] = [];
    formattedExportList: any[];
    fullBankList: Bank[];
    isAddClicked: boolean = false;
    isAdvancedSearchClicked: boolean = false;
    isAmountTypeFinancialGuarantee: boolean;
    isAmountTypeSelected: boolean = false;
    isBondAndLetterOfCreditNumberAvailable: boolean = true;
    isBondsAndLettersOfCreditFound: boolean = false;
    isCompanySelected: boolean = false;
    isEditClicked: boolean = false;
    isLetterOfCredit: boolean = false;
    isSearchComponent: boolean = false;
    isSearchEditClicked: boolean = false;
    isStatusCanceled: boolean = false;
    maxAmount: number = 999999999999.99; // 999 billion instead of 922337203685477.58 = Max value for SQL DB Type Money
    onlyBanksList: Bank[];
    onlySuretiesList: Bank[];
    rateMaxAmount: number = 10000; // Equals 100% due to (bps)
    searchFilter: BondsFilter;
    searchResults: Bond[] = null;
    selectedCommunity: Community = null;
    singleBondLimit = null;
    showNoResultsMessage: boolean = false;
    showSearchResults: boolean = false;
    totalExportRow: ExportSearchResult;

    constructor(private amountSubTypeService: AmountSubTypeService,
        private amountTypeService: AmountTypeService,
        private authService: AuthorizationService,
        private bankService: BankService,
        private bondService: BondService,
        private commonFunctions: CommonFunctions,
        private communityService: CommunityService,
        private companyService: CompanyService,
        private currencyPipe: CurrencyPipe,
        private datePipe: DatePipe,
        private documentService: DocumentService,
        private excelService: ExcelService,
        private messageService: MessageService,
        private primengConfig: PrimeNGConfig,
        private userService: UserService) { }

    ngOnInit(): void {
        this.authService.checkBlcUserAccess(false);
        this.getUser();

        this.displayAmountSubTypeList = [];
        this.amountTypeList = [];
        this.exportHeader = [];
        this.cols.forEach(col => {
            this.exportHeader.push(col.header);
        });
        this.companyList = [];
        this.displayBankList = [];
        this.bondAndLetterOfCreditNumber = null;
        this.primengConfig.ripple = true;
        this.clearSearchFilter();
        this.showSearchResults = false;

        this.loadData();
    }

    clearSearch(): void {
        if (this.isAddClicked) {
            this.isSearchComponent = false;
        } else {
            this.isSearchComponent = true;
        }
        this.displayBankList = this.fullBankList;
        this.searchResults = null;
        this.displayAmountSubTypeList = [];
        this.advancedSearchAvailable = true;
        this.bondAndLetterOfCreditNumber = null;
        this.communityList = [];
        this.clearSelectedCommunityValue();
        this.clearCommunitySuggestions();
        this.isAmountTypeSelected = false;
        this.isBondAndLetterOfCreditNumberAvailable = true;
        this.isCompanySelected = false;
        this.clearSearchFilter();
        this.showSearchResults = false;
    }

    clearSearchFilter(): void {
        this.searchFilter = {
            isLetterOfCredit: -1,
            status: 1,
            marketNumber: '-1',
            communityNumber: '-1',
            bankId: 0,
            amountTypeId: 0,
            amountSubTypeId: -1,
            beneficiary: null,
            rate: null,
            fromAmount: null,
            toAmount: null,
            fromDateIssued: null,
            toDateIssued: null,
            fromDateCanceled: null,
            toDateCanceled: null,
            number: null,
            id: null
        };
    }

    clearSelectedCommunityValue(): void {
        this.communityAutocomplete.value = null;
        this.communityAutocomplete.inputEL.nativeElement.value = null;

        this.selectedCommunity = null;
        this.searchFilter.communityNumber = null;
    }

    clearCommunitySuggestions(): void {
        this.filteredCommunities = [];
    }

    formatResultsForExport(results: Bond[]): void {
        // Reset the export list
        this.formattedExportList = [];
        const decimalFormat = { minimumFractionDigits: 2, maximumFractionDigits: 2 };

        // Define default total row
        this.totalExportRow = {
            number: undefined,
            company: undefined,
            community: undefined,
            bank: undefined,
            beneficiary: undefined,
            bondType: 'Total: ',
            currentAmount: '0',
            dateIssued: undefined,
            dateCanceled: undefined
        };

        // Prepare to track total amount for total row
        let totalCurrentAmount = 0;

        results.forEach((res: Bond) => {
            // Format the date objects to remove timestamps
            this.commonFunctions.trimTimeFromDatePropertiesInObject(res);

            const blc = {
                number: res.number,
                company: res.company?.companyName,
                community: res.community?.communityName,
                bank: res.bank?.name,
                beneficiary: res.beneficiary,
                bondType: AmountTypeEnum[res.amountTypeId],
                currentAmount: '$' + (+res.currentAmount).toLocaleString('en', decimalFormat),
                dateIssued: res.dateIssued,
                dateCanceled: res.dateCanceled
            };

            // Add to total amount
            totalCurrentAmount += res.currentAmount;

            // Add to list for export
            this.formattedExportList.push(blc);
        });

        // Update the total row and prepare for export
        this.totalExportRow.currentAmount = '$' + (+totalCurrentAmount)
            .toLocaleString('en', decimalFormat);
    }

    getAllBanks(): void {
        this.bankService.getBanks(true).subscribe((banks: Bank[]) => {
            banks.sort((a, b) => a.name.localeCompare(b.name))
            this.fullBankList = banks;
            this.onlyBanksList = banks.filter(bank => bank.isSurety == false);
            this.onlySuretiesList = banks.filter(bank => bank.isSurety == true);

            this.displayBankList = banks;
            this.sortAndFilterBankList();
        }, () => {
            this.messageService.add({ severity: 'error', summary: 'Unexpected Error Occured', detail: 'An error occurred getting Banks.' });
        });
    }

    getAmountSubTypes(selectedValue: number = 0): void {
        this.isAmountTypeSelected = false;
        if (!this.allAmountSubTypeList || this.allAmountSubTypeList.length == 0) {
            this.amountSubTypeService.getAmountSubTypes().subscribe((amountSubTypes) => {
                this.allAmountSubTypeList = amountSubTypes;
                this.allAmountSubTypeList.sort((a, b) => a.name.localeCompare(b.name));
            });
        }
        if (selectedValue > 0) {
            this.displayAmountSubTypeList = this.allAmountSubTypeList.filter(sub => sub.amountTypeId.toString() == selectedValue.toString());
            this.isAmountTypeSelected = true;
        } else {
            this.displayAmountSubTypeList = [];
        }
    }

    getBondType(): BLCTypeEnum {
        if (this.bondData.isLetterOfCredit === false) {
            this.bondData.bondType = this.blcType.Bond;
        } else {
            this.bondData.bondType = this.blcType.LetterOfCredit;
        }

        return this.bondData.bondType;
    }

    getBondStatus(): void {
        if (Number(this.bondData.isActive) === 1 && Number(this.bondData.isExpiring) === 1) {
            this.bondData.status = this.bondStatus.Expiring;
            this.isStatusCanceled = false;
        } else if (Number(this.bondData.isActive) === 1) {
            this.bondData.status = this.bondStatus.Active;
            this.isStatusCanceled = false;
        } else if (Number(this.bondData.isExpired) === 1) {
            this.bondData.status = this.bondStatus.Expired;
            this.isStatusCanceled = false;
        } else if (Number(this.bondData.isExpiring) === 1) {
            this.bondData.status = this.bondStatus.Expiring;
            this.isStatusCanceled = false;
        } else if (Number(this.bondData.isCanceled) === 1) {
            this.bondData.status = this.bondStatus.Canceled;
            this.isStatusCanceled = true;
        } else if (Number(this.bondData.isPending) === 1) {
            this.bondData.status = this.bondStatus.Pending;
            this.isStatusCanceled = false;
        }
    }

    getUser(): void {
        this.userService.getUser(this.authService.getUsername()).subscribe((user: User) => {
            if (user) {
                this.currentUser = user;
                this.bondModifierId = user.id;
            }
            else {
                this.messageService.add({ severity: 'error', summary: 'User data not found!', detail: `Couldn\'t find \'${this.authService.getUsername()}\' in the dbo.Users table` });
            }
        }, (err) => {
            this.messageService.add({ severity: 'error', summary: 'Unexpected Error Occured', detail: `An error occurred getting data for User: ${this.authService.getUsername()}` });
        });
    }

    goToPage(page: number = 1): void {
        setTimeout(() => {
            if (this.tableBonds) {
                this.tableBonds.first = (page - 1) * 10;
                this.tableBonds.firstChange.emit(this.tableBonds.first);
            }
        }, 0);
    }

    isAFilterOptionSelected(): boolean {
        this.searchFilter.isLetterOfCredit = Number(this.searchFilter.isLetterOfCredit);
        this.searchFilter.status = Number(this.searchFilter.status);
        this.searchFilter.bankId = Number(this.searchFilter.bankId);
        this.searchFilter.amountTypeId = Number(this.searchFilter.amountTypeId);
        this.searchFilter.amountSubTypeId = Number(this.searchFilter.amountSubTypeId);

        if (this.searchFilter.isLetterOfCredit === 0 && this.searchFilter.status === 0
            && this.searchFilter.marketNumber === null && this.searchFilter.communityNumber === null
            && this.searchFilter.bankId === 0 && this.searchFilter.amountTypeId === 0
            && this.searchFilter.amountSubTypeId === 0
            && (this.searchFilter.beneficiary == null || this.searchFilter.beneficiary['length'] === 0)
            && (this.searchFilter.rate == null || this.searchFilter.rate['length'] === 0)
            && this.searchFilter.fromAmount == null && this.searchFilter.toAmount == null
            && this.searchFilter.fromDateIssued == null && this.searchFilter.toDateIssued == null
            && this.searchFilter.fromDateCanceled == null && this.searchFilter.toDateCanceled == null) {
            return false;
        } else {
            return true;
        }
    }

    isExceptionAccess(): boolean {
        return this.authService.isBlcExceptionsUser();
    }

    isFullAccess(): boolean {
        return this.authService.isBlcFullAccessUser();
    }

    isReadOnlyAccess(): boolean {
        return this.authService.isBlcReadOnlyUser();
    }

    loadData(): void {
        this.isSearchComponent = true;
        this.getAllBanks();
        this.getAmountSubTypes();

        this.amountTypeService.getAmountTypes().subscribe((amountTypes: AmountType[]) => {
            this.amountTypeList = amountTypes;
            this.amountTypeList.sort((a, b) => a.name.localeCompare(b.name));
        }, () => {
            this.messageService.add({ severity: 'error', summary: 'Unexpected Error Occured', detail: 'An error occurred getting Amount Types.' });
        });

        this.companyService.getCompanies().subscribe((companies: Company[]) => {
            this.companyList = companies;
            this.companyList = this.commonFunctions.sortAndFormatCompanies(this.companyList);
        }, () => {
            this.messageService.add({ severity: 'error', summary: 'Unexpected Error Occured', detail: 'An error occurred getting Companies.' });
        });
    }

    modalEditTabDialog(data: number): void {
        this.bondId = data;
        const result = this.searchResults.find(o => o.id === this.bondId);
        this.bondData = JSON.parse(JSON.stringify(result));

        this.bondType = this.getBondType();
        this.getBondStatus();

        if (this.bondData.amountTypeId === AmountTypeEnum.FinancialGuarantee) {
            this.isAmountTypeFinancialGuarantee = true;
            this.bankService.getAvailableCommitment(this.bondData.bankId, this.isAmountTypeFinancialGuarantee).subscribe((bankInformation: BankWithFinancialGuarantee) => {
                this.bankData = bankInformation;
                this.availableCommitment = this.bankData.financialGuaranteeAvailableCommitment;
            });
        } else {
            this.isAmountTypeFinancialGuarantee = false;
            this.bankService.getAvailableCommitment(this.bondData.bankId, this.isAmountTypeFinancialGuarantee).subscribe((bankInformation: BankWithFinancialGuarantee) => {
                this.bankData = bankInformation;
                this.availableCommitment = this.bankData.availableCommitment;
            });
        }

        this.displayBankList.forEach(bank => {
            if (bank.id === this.bondData.bankId) {
                if (bank.singleBondLimit != null) {
                    this.singleBondLimit = bank.singleBondLimit.toFixed(2);
                } else {
                    this.singleBondLimit = '';
                }
            }
        });

        if (this.searchFilter.bankId != 0) {
            this.searchFilter.bankId = this.bondData.bankId;
        }

        this.documentService.getDocumentsByBlcId(this.bondData.id).subscribe((docs: Document[]) => {
            docs.forEach((result) => {
                this.commonFunctions.trimTimeFromDatePropertiesInObject(result);
            });
            this.documents = docs;
        }, () => {
            this.messageService.add({ severity: 'error', summary: 'Unexpected Error Occured', detail: `An error occurred getting documents for Bond ID ${this.bondData.id}` });
        });

        this.displayEditDialog = true;
    }

    modalOpenAddClick(): void {
        // Display the modal
        this.displayAddDialog = true;
        this.isAddClicked = true;
        this.clearSearch();
    }

    modalOpenEditClick(): void {
        // Display the modal
        this.displayEditDialog = true;
        this.isEditClicked = true;
    }

    onAdvancedSearch(isEditWindowClose: boolean = false): void {
        this.showSearchResults = true;
        if (!isEditWindowClose) {
            this.goToPage();
        }
        this.isAdvancedSearchClicked = true;
        this.isSearchEditClicked = false;
        this.searchResults = null;

        if (this.searchFilter.toAmount > this.maxAmount) {
            this.messageService.add({
                severity: 'error', summary: 'Error',
                detail: 'To Amount is out of range. Please set amount to less than ' +
                    this.currencyPipe.transform(this.maxAmount, 'USD', 'symbol', '1.2-2') + ' and retry Advanced Search.'
            });
            return;
        } else if (this.searchFilter.fromAmount > this.maxAmount) {
            this.messageService.add({
                severity: 'error', summary: 'Error',
                detail: 'From Amount is out of range. Please set amount to less than ' +
                    this.currencyPipe.transform(this.maxAmount, 'USD', 'symbol', '1.2-2') + ' and retry Advanced Search.'
            });
            return;
        }
        this.bondService.getBonds(this.searchFilter).subscribe((advanceSearchResults: Bond[]) => {
            if (advanceSearchResults.length > 0) {
                advanceSearchResults.forEach(res => {
                    res = this.commonFunctions.trimTimeFromDatePropertiesInObject(res);
                });
                this.searchResults = advanceSearchResults;
                this.formatResultsForExport(advanceSearchResults);

                if (this.searchResults.length > 0) {
                    this.isBondsAndLettersOfCreditFound = true;
                    this.showNoResultsMessage = false;
                    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Bonds/Letters of Credit have been found.' });
                } else {
                    this.isBondsAndLettersOfCreditFound = false;
                    this.showNoResultsMessage = true;
                }
            }
        }, (error: Error) => {
            this.isBondsAndLettersOfCreditFound = false;
            this.showNoResultsMessage = true;

            this.messageService.add({
                severity: 'error', summary: 'Unexpected Error Occured',
                detail: 'An error occurred getting Bonds/Letters Of Credit.'
            });
        });
    }

    onAdvancedSearchChange(eventValue: any, inputName: any): void {
        if (inputName == 'type') {
            this.searchFilter.bankId = 0;
            this.isSearchComponent = true;

            if (this.searchFilter.isLetterOfCredit == -1) {
                this.displayBankList = this.fullBankList;
            } else if (this.searchFilter.isLetterOfCredit == 0) {
                this.displayBankList = this.onlySuretiesList;
            } else if (this.searchFilter.isLetterOfCredit == 1) {
                this.displayBankList = this.onlyBanksList;
            }

            this.sortAndFilterBankList();
        } else if ((inputName == 'fromAmount' || inputName == 'toAmount') && eventValue > this.maxAmount) {
            this.messageService.add({
                severity: 'error', summary: 'Invalid Value',
                detail: (inputName == 'fromAmount' ? 'From Amount' : 'To Amount') + ' must not exceed ' + this.currencyPipe.transform(this.maxAmount, 'USD', 'symbol', '1.2-2') + '.'
            });

            if (inputName == 'fromAmount') {
                this.searchFilter.fromAmount = 0;
            } else {
                this.searchFilter.toAmount = 0;
            }
        } else if (inputName == 'rate' && (!this.commonFunctions.isFloat(eventValue) || Number.parseFloat(eventValue) > this.rateMaxAmount)) {
            this.messageService.add({
                severity: 'error', summary: 'Invalid Value',
                detail: 'Rate (bps) must not exceed ' + this.rateMaxAmount + '.'
            });
        }

        if ((!eventValue && !this.isAFilterOptionSelected())
            || (typeof eventValue === 'string' && eventValue['length'] === 0 && !this.isAFilterOptionSelected())
            || (eventValue === 0 && !this.isAFilterOptionSelected())) {
            this.isBondAndLetterOfCreditNumberAvailable = true;
        } else {
            this.bondAndLetterOfCreditNumber = null;
            this.isBondAndLetterOfCreditNumberAvailable = false;
        }
    }

    onAmountTypeChange(amountType: AmountTypeEnum): void {
        this.onAdvancedSearchChange(amountType, undefined);

        if (amountType == AmountTypeEnum.FinancialGuarantee || amountType == AmountTypeEnum.Performance) {
            this.getAmountSubTypes(amountType);
        } else {
            this.displayAmountSubTypeList = [];
            this.isAmountTypeSelected = false;
            this.searchFilter.amountSubTypeId = -1;
            this.displayAmountSubTypeList = [];
        }
    }

    onBondLCSearch(eventValue: any): void {
        !!eventValue && eventValue['length'] > 0 ? this.advancedSearchAvailable = false : this.advancedSearchAvailable = true;
    }

    onCompanyChange(eventValue: any): void {
        this.communityList = [];
        this.filteredCommunities = [];
        this.isCompanySelected = false;
        this.searchFilter.communityNumber = '-1';
        //  reset community selection first prior to community ac to rediscover
        //  its bound data
        this.clearSelectedCommunityValue();

        this.onAdvancedSearchChange(eventValue, undefined);

        if (eventValue !== 0) {
            this.communityService.getCommunities(eventValue).subscribe((communities: Community[]) => {
                if (communities?.length > 0) {
                    this.communityList = communities;
                    this.commonFunctions.sortAndFormatCommunities(this.communityList);
                    this.filteredCommunities = this.communityList;
                } else {
                    this.communityList = [];
                    this.filteredCommunities = [];
                }
                this.searchFilter.communityNumber = '-1';
                this.isCompanySelected = this.communityList?.length > 0 || this.filteredCommunities?.length > 0 ? true : false;
            }, () => {
                this.messageService.add({
                    severity: 'error', summary: 'Unexpected Error Occured',
                    detail: 'An error occurred getting Communities.'
                });

                this.searchFilter.communityNumber = '-1';
            });
        }
    }

    onCommunitySelect(event: Community): void {
        this.selectedCommunity = event ? event : null;
        this.searchFilter.communityNumber = this.selectedCommunity?.communityNumber;
    }

    onDialogClose(): void {
        this.displayAddDialog = false;
        this.isAddClicked = false;
    }

    onExportClick(): void {
        const workbook = new XLSX.Workbook();
        const worksheet = workbook.addWorksheet('BondSearchResults');

        // Add header row
        const headerRow = worksheet.addRow(this.exportHeader);

        // Set column widths to fix header row
        worksheet.columns.forEach((column) => {
            column.width = 30;
        });

        // Wrap and align the header text
        headerRow.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };

        // Set header row height
        headerRow.height = 30;

        // Fill and Border Headers
        headerRow.eachCell((cell) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'D3D3D3' },
                bgColor: { argb: '' }
            };
            cell.border = { top: { style: 'none' }, left: { style: 'none' }, bottom: { style: 'none' }, right: { style: 'none' } };
            cell.font = { bold: true };
        });

        // Add data
        this.formattedExportList.forEach((dataRow) => {
            const dataStrings: any[] = [];

            // Convert each prop into a string array
            Object.values(dataRow).forEach((val: any, index: number) => {
                switch (index) {
                    case 7: //Issue Date
                    case 8: //Cancel Date
                        if (typeof val === 'string') {
                            dataStrings.push(new Date(val));
                        }
                        else {
                            dataStrings.push(val);
                        }
                        break;
                    default: //Everything else
                        dataStrings.push(val);
                }
            });

            // Add the data row
            worksheet.addRow(dataStrings);
        });

        // Set up string array for total row
        const totalString: string[] = [];

        // Convert total row object to string array
        Object.values(this.totalExportRow).forEach((val: any) => {
            totalString.push(val);
        });

        // Add total row
        const totalRow = worksheet.addRow(totalString);

        // Format total row
        totalRow.eachCell((cell) => {
            cell.font = { bold: true };
        });

        // Export the file
        this.excelService.exportAsExcelFile(workbook, 'BondSearchResults');
    }

    onFilterCommunity(event: any): void {
        let filtered: any[] = [];
        let query = event?.query;

        for (let i = 0; i < this.communityList?.length; i++) {
            let community = this.communityList[i];
            let selCommunityFullName = `${community?.communityNumber} - ${community?.communityName}`;
            if (selCommunityFullName?.toLowerCase()?.includes(query?.toLowerCase())) {
                filtered.push(community);
            }
        }

        this.filteredCommunities = filtered?.length > 0 ? filtered : this.communityList;
    }

    onModalEditTabDialogClose(): void {
        this.displayEditDialog = false;
    }

    onSearchAndEdit(isEditWindowClose: boolean = false): void {
        this.showSearchResults = true;
        if (!isEditWindowClose) {
            this.goToPage();
        }
        this.isBondsAndLettersOfCreditFound = false;
        this.searchResults = null;
        this.showNoResultsMessage = false;
        this.isSearchEditClicked = true;
        this.isAdvancedSearchClicked = false;

        const bondsFilter: BondsFilter = new BondsFilter();
        bondsFilter.number = this.bondAndLetterOfCreditNumber;

        this.bondService.getBonds(bondsFilter).subscribe((blcList: Bond[]) => {
            if (blcList.length > 0) {
                blcList.forEach(blc => {
                    blc = this.commonFunctions.trimTimeFromDatePropertiesInObject(blc);
                });
                this.searchResults = blcList;
                this.formatResultsForExport(blcList);

                this.isBondsAndLettersOfCreditFound = true;
                this.messageService.add({
                    severity: 'success', summary: 'Success',
                    detail: 'Bond(s) or Letter(s) of Credit found.'
                });
            } else {
                this.showNoResultsMessage = true;
            }
        }, () => {
            this.showNoResultsMessage = true;
            this.messageService.add({ severity: 'error', summary: 'Unexpected Error Occured', detail: 'An error occurred getting Bond/Letter Of Credit.' });
        });
    }

    sendChildMessage(messageArray: string[]): void {
        this.messageService.add({ severity: messageArray[0], summary: messageArray[1], detail: messageArray[2] });
    }

    showSelectedValue(selected: Community): string {
        return selected ? `${selected.communityNumber} - ${selected.communityName}` : null;
    }

    sortAndFilterBankList(): void {
        // First, sort by name
        this.displayBankList?.sort((a, b) => a.name.localeCompare(b.name));

        // Then, put all inactive at the bottom of the list
        this.displayBankList?.sort((a, b) => {
            return Number(b.isActive) - Number(a.isActive);
        });

        // Now, filter out unncessary records (if applicable)
        if (this.searchFilter.isLetterOfCredit != -1) {
            const filteredBanks: Bank[] = [];
            if (this.searchFilter.isLetterOfCredit == 1) {
                this.displayBankList?.forEach(function (rec) {
                    if (!rec.isSurety) {
                        filteredBanks.push(rec);
                    }
                });
            } else {
                this.displayBankList?.forEach(function (rec) {
                    if (rec.isSurety) {
                        filteredBanks.push(rec);
                    }
                });
            }
            this.displayBankList = filteredBanks;
        }
    }
}
