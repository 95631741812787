import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { DataCacheObject } from '../../models';
import { SharedService } from './shared.service';

@Injectable()
export class ServerDataCacheService {

    constructor(public http: HttpClient, private sharedService: SharedService) { }

    getDataCache(): Observable<DataCacheObject[]> {
        const getUrl = `${environment.baseUrl}dataCaching`;
        return this.http.get<DataCacheObject[]>(getUrl);
    }

    removeAllDataCache(): Observable<boolean> {
        const opts = this.sharedService.getOptsJson();
        return this.http.delete<boolean>(environment.baseUrl + `dataCaching/DropAllCache`, opts);
    }

    removeDataCacheByKey(cacheKey: string): Observable<boolean> {
        const opts = this.sharedService.getOptsJson();
        return this.http.delete<boolean>(environment.baseUrl + `dataCaching/dropCache/${cacheKey}`, opts);
    }
}
