import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import {
    Bank,
    BanksFilter,
    BankWithAmountTypes,
    BankWithFinancialGuarantee,
    InvoiceBanksBonds,
    RateChangeSummary,
} from './../../models';
import { SharedService } from './shared.service';

@Injectable()
export class BankService {

    private cachedBanks: Observable<Bank[]> = null;

    constructor(public http: HttpClient, private sharedService: SharedService) { }

    addBankWithAmountTypes(bankParam: BankWithAmountTypes): Observable<number> {
        this.dropCache();
        bankParam.id = 0;
        bankParam.commitAmount = bankParam.commitAmount ? Number(bankParam.commitAmount.toFixed(2)) : 0.00;
        bankParam.financialGuaranteeCommitAmount = bankParam.financialGuaranteeCommitAmount ?
            Number(bankParam.financialGuaranteeCommitAmount.toFixed(2)) : 0.00;
        bankParam.financialGuaranteeRate = bankParam.financialGuaranteeRate ? Number(bankParam.financialGuaranteeRate.toFixed(2)) : 0.00;
        bankParam.minimumFee = bankParam.minimumFee ? Number(bankParam.minimumFee.toFixed(2)) : 0.00;
        bankParam.rate = bankParam.rate ? Number(bankParam.rate.toFixed(2)) : 0.00;
        bankParam.singleBondLimit = bankParam.singleBondLimit ? Number(bankParam.singleBondLimit.toFixed(2)) : 0.00;
        bankParam.isSelected = bankParam.isSelected ? true : false;
        const body = JSON.stringify(bankParam);
        const opts = this.sharedService.getOptsJsonUtf8();
        return this.http.post<number>(`${environment.baseUrl}banks/createwithamounttypes`, body, opts);
    }

    dropCache(): void {
        this.cachedBanks = null;
    }

    getAvailableCommitment(bankId: number, isAmountTypeFinancialGuarantee: boolean): Observable<BankWithFinancialGuarantee> {
        const opts = this.sharedService.getOptsApplication();
        return this.http.get<BankWithFinancialGuarantee>(environment.baseUrl + `banks/commitments/${bankId}/${isAmountTypeFinancialGuarantee}`, opts);
    }

    getBankById(bankId: number): Observable<Bank> {
        const opts = this.sharedService.getOptsJson();
        const searchUrl = environment.baseUrl + `banks/getbankbyid/${bankId}`;
        return this.http.get<Bank>(searchUrl, opts);
    }

    getBankInvoices(bankId: number): Observable<InvoiceBanksBonds[]> {
        const opts = this.sharedService.getOptsApplication();
        return this.http.get<InvoiceBanksBonds[]>(environment.baseUrl + `banks/getinvoices/${bankId}`, opts);
    }

    getBanks(includeInactive: boolean, isLetterOfCredit?: boolean): Observable<Bank[]> {
        const opts = this.sharedService.getOptsApplication();
        if (includeInactive && (isLetterOfCredit == null || isLetterOfCredit == undefined)) {
            if (!this.cachedBanks) {
                this.cachedBanks = this.http.get<Bank[]>(environment.baseUrl + `banks/${includeInactive}/${isLetterOfCredit}`, opts);
            }
            return this.cachedBanks;
        } else {
            return this.http.get<Bank[]>(environment.baseUrl + `banks/${includeInactive}/${isLetterOfCredit}`, opts);
        }
    }

    getBanksFromSearch(bankNameParam: string): Observable<BanksFilter[]> {
        const bankPass: string = (bankNameParam === '' || bankNameParam === null || bankNameParam === undefined) ? 'null' : bankNameParam;
        const opts = this.sharedService.getOptsJson();
        const body = JSON.stringify({ bankName: bankPass });
        const searchUrl = environment.baseUrl + `banks/search`;
        return this.http.post<BanksFilter[]>(searchUrl, body, opts);
    }

    getBankWithCommitment(bankId: number): Observable<BankWithAmountTypes> {
        const opts = this.sharedService.getOptsApplication();
        return this.http.get<BankWithAmountTypes>(environment.baseUrl + `banks/commitments/${bankId}/${null}`, opts);
    }


    getRateChangeSummary(dateMod: string | Date, modId: number): Observable<RateChangeSummary[]> {
        const getUrl = `${environment.baseUrl}banks/getratechangesummary`;
        const opts = this.sharedService.getOptsJson();
        const body = JSON.stringify({ dateModified: dateMod, modifierId: modId });
        return this.http.post<RateChangeSummary[]>(getUrl, body, opts);
    }

    updateBank(bankParam: Bank): Observable<void> {
        this.dropCache();
        const opts = this.sharedService.getOptsJson();
        const body = JSON.stringify(bankParam);
        return this.http.patch<void>(environment.baseUrl + `banks`, body, opts);
    }

    updateBankWithAmountTypes(bankParam: BankWithAmountTypes): Observable<number> {
        this.dropCache();
        bankParam.commitAmount = bankParam.commitAmount ? Number(bankParam.commitAmount.toFixed(2)) : 0.00;
        bankParam.financialGuaranteeCommitAmount = bankParam.financialGuaranteeCommitAmount ?
            Number(bankParam.financialGuaranteeCommitAmount.toFixed(2)) : 0.00;
        bankParam.financialGuaranteeRate = bankParam.financialGuaranteeRate ? Number(bankParam.financialGuaranteeRate.toFixed(2)) : 0.00;
        bankParam.minimumFee = bankParam.minimumFee ? Number(bankParam.minimumFee.toFixed(2)) : 0.00;
        bankParam.rate = bankParam.rate ? Number(bankParam.rate.toFixed(2)) : 0.00;
        bankParam.singleBondLimit = bankParam.singleBondLimit ? Number(bankParam.singleBondLimit.toFixed(2)) : 0.00;
        bankParam.isSelected = bankParam.isSelected ? true : false;
        const body = JSON.stringify(bankParam);
        const opts = this.sharedService.getOptsJsonUtf8();
        return this.http.patch<number>(`${environment.baseUrl}banks/updateWithAmountTypes`, body, opts);
    }
}
