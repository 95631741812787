import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { Document, DocumentParam } from '../../models';
import { SharedService } from './shared.service';

@Injectable()
export class DocumentService {

    constructor(public http: HttpClient, private sharedService: SharedService) { }

    getDocumentsByBlcId(id: number): Observable<Document[]> {
        const getUrl = `${environment.baseUrl}documents/getdocumentsbyblcid/${id}`;
        return this.http.get<Document[]>(getUrl);
    }

    getDocumentDownloadUrl(id: string): Observable<string> {
        const getUrl = `${environment.baseUrl}documents/getdocumentdownloadurl/${id}`;
        return this.http.get<string>(getUrl);
    }

    removeDocument(id: string): Observable<boolean> {
        const opts = this.sharedService.getOptsJson();
        const patchUrl = `${environment.baseUrl}documents/removedocument/${id}`;
        return this.http.delete<boolean>(patchUrl, opts);
    }

    uploadDocumentToBlobContainer(documentParam: DocumentParam): Observable<boolean> {
        const opts = this.sharedService.getOptsJson();
        const body = JSON.stringify(documentParam);
        const patchUrl = `${environment.baseUrl}documents/uploaddocumenttoblobcontainer`;
        return this.http.post<boolean>(patchUrl, body, opts);
    }
}
