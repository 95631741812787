import { AmountType, Bank, Community, Company } from './';
import { AmountSubTypeEnum, AmountTypeEnum, BLCTypeEnum, BondStatusEnum } from './enums';

export class Bond {
    id: number;
    amount: number;
    amountDescription: string;
    amountTypeId: AmountTypeEnum;
    amountSubTypeId: AmountSubTypeEnum;
    bankId: number;
    beneficiary: string;
    communityName: string;
    companyName: string;
    dateCanceled: Date | string;
    dateExpired: Date | string;
    dateIssued: Date | string;
    dateMailed: Date | string;
    dateCreated: Date;
    description: string;
    isAutoRenew: boolean;
    isLetterOfCredit: boolean;
    modifierId: number;
    number: number;
    rate: number;
    costToComplete: number;
    estimatedReleaseDate: Date | string;
    comments: string;
    ldaPhaseCode: string;
    marketNumber: string;
    communityNumber: string;

    currentDateExpired: Date | string;
    isActive: boolean;
    isExpiring: boolean;
    isExpired: boolean;
    isCanceled: boolean;
    isPending: boolean;
    currentAmount: number;

    amountType: AmountType;
    bank: Bank;
    bondType: BLCTypeEnum;
    company: Company;
    community: Community;
    status: BondStatusEnum;
}
