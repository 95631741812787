import { CurrencyPipe, DatePipe } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { switchMap } from 'rxjs/operators';
import { CommonFunctions } from 'src/functions';
import { Amendment, BankWithFinancialGuarantee, Bond, User } from 'src/models';
import { AmountTypeEnum } from 'src/models/enums';

import { AmendmentService, AuthorizationService, BankService, BondService, UserService } from '../../services';

@Component({
    selector: 'app-edit-amendment',
    templateUrl: './edit-amendment.component.html',
    styleUrls: ['./edit-amendment.component.css'],
    providers: [MessageService]
})

export class EditAmendmentComponent implements OnInit, AfterViewInit {
    @Input() amendmentData: Amendment;
    @Input() bondData: Bond;
    @Input() displayDialog: boolean = false;
    @Input() singleBondLimit: number;

    @Output() availableCommitmentChange = new EventEmitter();
    @Output() bondDataChange = new EventEmitter();
    @Output() dialogClose: EventEmitter<any> = new EventEmitter();
    @Output() getAmendments = new EventEmitter();
    @Output() sendMessageEmit = new EventEmitter();

    availableCommitment: number;
    bankData: BankWithFinancialGuarantee;
    isAmountTypeFinancialGuarantee: boolean;
    isDataChanged: boolean;
    isEffectiveDateEntered: boolean;
    isExpireDateEntryValid: boolean;
    isMailDateEntryValid: boolean;
    isEffectiveDateEntryValid: boolean;
    maxAmount: number = 999999999999.99; // 999 billion instead of 922337203685477.58 = Max value for SQL DB Type Money
    type: string = '';
    user: User = null;

    constructor(private authService: AuthorizationService,
        private amendmentService: AmendmentService,
        private bankService: BankService,
        private bondService: BondService,
        private commonFunctions: CommonFunctions,
        private currencyPipe: CurrencyPipe,
        private datePipe: DatePipe,
        private messageService: MessageService,
        private userService: UserService) { }

    ngOnInit(): void {
        this.isExpireDateEntryValid = true;
        this.isMailDateEntryValid = true;
        this.isEffectiveDateEntryValid = true;
        this.isDataChanged = false;

        this.getUser();
    }

    ngAfterViewInit(): void {
        this.isEffectiveDateEntered = this.amendmentData.dateEffective ? true : false;
    }

    closeblcModal(): void {
        this.dialogClose.emit(this.displayDialog);
    }

    getBondType(): string {
        return this.bondData.bondType == 0 ? `Surety's` : `Bank's`;
    }

    getUser(): void {
        this.userService.getUser(this.authService.getUsername()).subscribe((user: User) => {
            if (user) {
                this.user = user;
            }
            else {
                this.messageService.add({ severity: 'error', summary: 'User data not found!', detail: `Couldn\'t find \'${this.authService.getUsername()}\' in the dbo.Users table` });
            }            
        }, (err) => {
            this.messageService.add({ severity: 'error', summary: 'Unexpected Error Occured', detail: `An error occurred getting data for User: ${this.authService.getUsername()}` });                     
        });
    }

    initializeDialogHeader(): string {
        return this.bondData.isLetterOfCredit ? `Letter of Credit: #${this.bondData.number}` : `Bond: #${this.bondData.number}`;
    }

    onFieldChange(): void {
        this.isDataChanged = true;
    }

    onInput(event: any): void {
        if (event.value > this.maxAmount) {
            this.messageService.add({
                severity: 'error', summary: 'Error',
                detail: 'Amended Amount is out of range. Please set amount to less than ' + this.currencyPipe.transform(this.maxAmount, 'USD', 'symbol', '1.2-2') + '.'
            });
            this.amendmentData.bondNewAmount = 0;
            this.isDataChanged = true;
            return;
        }
        this.amendmentData.bondNewAmount = event.value;
        this.isDataChanged = true;
        this.setRequest();
    }

    onInputDateChange(evt: any, inputName: any): void {
        this.isDataChanged = true;

        if (inputName === 'expireDate') {
            if (evt == '' || evt == null || evt == undefined) {
                this.isExpireDateEntryValid = true;
                this.amendmentData.bondNewDateExpired = null;
                return;
            }

            if (this.commonFunctions.isDateValid(evt)) {
                this.isExpireDateEntryValid = true;
                this.amendmentData.request = 'Expire date set to ' + this.commonFunctions.formatDateMmddyyyy(new Date(evt).toUTCString());
                if (this.amendmentData.bondNewAmount || this.amendmentData.bondNewAmount == 0) {
                    this.amendmentData.bondNewAmount = null;
                }
            } else {
                this.isExpireDateEntryValid = false;
            }
        } else if (inputName === 'mailDate') {
            if (evt == '' || evt == null || evt == undefined) {
                this.isMailDateEntryValid = true;
                this.amendmentData.dateMailed = null;
                return;
            }

            this.isMailDateEntryValid = this.commonFunctions.isDateValid(evt);
        } else if (inputName === 'effectiveDate') {
            if (evt == '' || evt == null || evt == undefined) {
                this.isEffectiveDateEntryValid = true;
                this.amendmentData.dateEffective = null;
                return;
            }

            this.isEffectiveDateEntryValid = this.commonFunctions.isDateValid(evt);
        }

        this.isEffectiveDateEntered = this.amendmentData.dateEffective ? true : false;
    }

    onInputDateSelect(evt: any, inputName: any): void {
        this.isDataChanged = true;

        if (inputName === 'expireDate') {
            this.isExpireDateEntryValid = true;
            this.amendmentData.bondNewDateExpired = this.commonFunctions.formatDateMmddyyyy(evt.toUTCString());
            this.amendmentData.request = 'Expire date set to ' + this.amendmentData.bondNewDateExpired;
            if (this.amendmentData.bondNewAmount || this.amendmentData.bondNewAmount == 0) {
                this.amendmentData.bondNewAmount = null;
            }
        } else if (inputName === 'mailDate') {
            this.isMailDateEntryValid = true;
            this.amendmentData.dateMailed = this.commonFunctions.formatDateMmddyyyy(evt.toUTCString());
        } else if (inputName === 'effectiveDate') {
            this.isEffectiveDateEntryValid = true;
            this.amendmentData.dateEffective = this.commonFunctions.formatDateMmddyyyy(evt.toUTCString());
        }

        this.isEffectiveDateEntered = this.amendmentData.dateEffective ? true : false;
    }

    onSubmit(): void {
        this.setDatesToUtc();
        this.amendmentData.bondId = this.bondData.id;
        if (this.isDataChanged) {
            if (this.validateFields()) {
                this.amendmentService.updateAmendment(this.amendmentData).pipe(
                    switchMap(() => {
                        this.sendMessage('success', 'Success', `The amendment has been successfully updated`);
                        this.getAmendments.emit();
                        this.bondService.updateBond(this.bondData).subscribe(() => {
                            this.sendMessage('success', 'Success', `The Bond/LC has been sucessfully updated`);
                        }, () => {
                            this.sendMessage('error', 'Error', `An error occurred updating the Bond/LC`);
                        });
                        return this.bondService.getBondByNumber(this.bondData.number.toString());
                    })
                ).subscribe((bondResult: Bond) => {
                    this.bondData = bondResult;
                    this.bondData.currentDateExpired != null ?
                        this.bondData.currentDateExpired = this.datePipe.transform(this.bondData.currentDateExpired, 'MM/dd/yyyy') :
                        this.bondData.currentDateExpired = null;
                    this.bondData.bondType = this.commonFunctions.getBondType(this.bondData.isLetterOfCredit);
                    const getBondStatusResult = this.commonFunctions.getBondStatus(this.bondData);
                    this.bondData = getBondStatusResult.modifiedBond;
                    // Doing this to update the available commitment amount value since the amended amount modifies both the current amount and available commitment amount
                    if (this.bondData.amountTypeId === AmountTypeEnum.FinancialGuarantee) {
                        this.isAmountTypeFinancialGuarantee = true;
                        this.bankService.getAvailableCommitment(this.bondData.bankId, this.isAmountTypeFinancialGuarantee).pipe(
                            switchMap((bankInformation: BankWithFinancialGuarantee) => {
                                this.bankData = bankInformation;
                                this.availableCommitment = this.bankData.financialGuaranteeAvailableCommitment;
                                this.availableCommitmentChange.emit(this.availableCommitment);
                                return null;
                            })
                        );
                    } else {
                        this.isAmountTypeFinancialGuarantee = false;
                        this.bankService.getAvailableCommitment(this.bondData.bankId, this.isAmountTypeFinancialGuarantee).pipe(
                            switchMap((bankInformation: BankWithFinancialGuarantee) => {
                                this.bankData = bankInformation;
                                this.availableCommitment = this.bankData.availableCommitment;
                                this.availableCommitmentChange.emit(this.availableCommitment);
                                return null;
                            })
                        )
                    }
                }, () => {
                    this.messageService.add({ severity: 'error', summary: 'Unexpected Error Occured', detail: 'An error occurred getting Bond/Letter Of Credit.' });
                }, () => {
                    this.bondDataChange.emit(this.bondData);
                    this.closeblcModal();
                });
            }
        } else {
            this.sendMessage('error', 'Error', `Since there were no changes made, the amendment has not been saved`);
        }
    }

    sendMessage(severity: string, summary: string, detail: string): void {
        this.sendMessageEmit.emit([severity, summary, detail]);
    }

    setDatesToUtc(): void {
        if (this.amendmentData.bondNewDateExpired) {
            const utcd = this.commonFunctions.formatDateMmddyyyy(new Date(this.amendmentData.bondNewDateExpired).toISOString());
            this.amendmentData.bondNewDateExpired = utcd;
        }

        if (this.amendmentData.dateMailed) {
            const utcd = this.commonFunctions.formatDateMmddyyyy(new Date(this.amendmentData.dateMailed).toISOString());
            this.amendmentData.dateMailed = utcd;
        }

        if (this.amendmentData.dateEffective) {
            const utcd = this.commonFunctions.formatDateMmddyyyy(new Date(this.amendmentData.dateEffective).toISOString());
            this.amendmentData.dateEffective = utcd;
            this.isEffectiveDateEntered = this.amendmentData.dateEffective ? true : false;
        }
    }

    setRequest(): void {
        if (this.amendmentData.bondNewAmount == null) {
            this.amendmentData.request = '';
        } else if (this.amendmentData.bondNewAmount > this.bondData.currentAmount) {
            this.amendmentData.request = 'Increase to $' + this.amendmentData.bondNewAmount;
        } else {
            this.amendmentData.request = 'Decrease to $' + this.amendmentData.bondNewAmount;
        }
        if (this.amendmentData.bondNewDateExpired) {
            this.amendmentData.bondNewDateExpired = null;
        }
    }

    validateFields(): boolean {
        this.type = this.getBondType();
        if (!this.amendmentData.request) {
            this.sendMessage('warn', 'Warn', `Please enter the request`);
            return false;
        }

        if ((this.amendmentData.bondNewAmount > this.bondData.currentAmount) && (this.amendmentData.bondNewAmount > this.singleBondLimit)) {
            this.sendMessage('warn', 'Warn', `Amended amount exceeds the ` + this.type + ` single bond limit amount`);
        }

        if (this.amendmentData.dateEffective && ((this.amendmentData.bondNewAmount - this.bondData.currentAmount)) &&
            (this.amendmentData.bondNewAmount > this.singleBondLimit)) {
            this.messageService.add({
                severity: 'warn', summary: 'Warn',
                detail: 'Amended amount will cause the available commitment for the ' + this.type + ' to become negative'
            });
            return true;
        }

        return true;
    }
}
