import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { CommonFunctions } from 'src/functions';
import { Bond, Invoice, User } from 'src/models';

import { AuthorizationService, InvoiceService, UserService } from '../../services';

@Component({
    selector: 'app-edit-invoice',
    templateUrl: './edit-invoice.component.html',
    styleUrls: ['./edit-invoice.component.css'],
    providers: [MessageService]
})

export class EditInvoiceComponent implements OnInit {

    @Input() bondData: Bond;
    @Input() displayDialog: boolean = false;
    @Input() invoiceData: Invoice;

    @Output() dialogClose: EventEmitter<any> = new EventEmitter();
    @Output() getBondInvoices = new EventEmitter();
    @Output() sendMessageEmit = new EventEmitter();

    blcNumber: number = 0;
    isDataChanged: boolean;
    isEndDateEntryValid: boolean;
    isPayableDateEntryValid: boolean;
    isStartDateEntryValid: boolean;
    maxAmount: number = 999999999999.99; // 999 billion instead of 922337203685477.58 = Max value for SQL DB Type Money
    user: User = null;

    constructor(private authService: AuthorizationService,
        private commonFunctions: CommonFunctions,
        private currencyPipe: CurrencyPipe,
        private invoiceService: InvoiceService,
        private messageService: MessageService,
        private userService: UserService) { }

    ngOnInit() {
        this.isPayableDateEntryValid = true;
        this.isStartDateEntryValid = true;
        this.isEndDateEntryValid = true;
        this.isDataChanged = false;

        this.getUser();
    }

    closeblcModal() {
        this.dialogClose.emit(this.displayDialog);
    }

    getUser() {
        this.userService.getUser(this.authService.getUsername()).subscribe((user: User) => {
            if (user) {
                this.user = user;
            }
            else {
                this.messageService.add({ severity: 'error', summary: 'User data not found!', detail: `Couldn\'t find \'${this.authService.getUsername()}\' in the dbo.Users table` });
            }            
        }, (err) => {
            this.messageService.add({ severity: 'error', summary: 'Unexpected Error Occured', detail: `An error occurred getting data for User: ${this.authService.getUsername()}` });                     
        });
    }

    initializeDialogHeader() {
        return this.bondData.isLetterOfCredit ? `Letter of Credit: #${this.bondData.number}` : `Bond: #${this.bondData.number}`;
    }

    onInput(event) {
        if (event.value > this.maxAmount) {
            this.messageService.add({
                severity: 'error', summary: 'Error',
                detail: 'Invoice Amount is out of range. Please set amount to less than ' + this.currencyPipe.transform(this.maxAmount, 'USD', 'symbol', '1.2-2') + '.'
            });
            this.invoiceData.amount = 0;
            return;
        }
        this.invoiceData.amount = event.value;
    }

    onInputDateChange(evt: any, inputName: any) {
        this.isDataChanged = true;
        if (inputName === 'payableDate') {
            if (evt == '' || evt == null || evt == undefined) {
                this.isPayableDateEntryValid = true;
                this.invoiceData.dateAp = null;
                return;
            }

            if (this.commonFunctions.isDateValid(evt)) {
                this.isPayableDateEntryValid = true;
            } else {
                this.isPayableDateEntryValid = false;
            }
        } else if (inputName === 'issueDate') {
            if (evt == '' || evt == null || evt == undefined) {
                this.isStartDateEntryValid = true;
                this.invoiceData.datePayPeriodStart = null;
                return;
            }

            if (this.commonFunctions.isDateValid(evt)) {
                this.isStartDateEntryValid = true;
            } else {
                this.isStartDateEntryValid = false;
            }
        } else if (inputName === 'expireDate') {
            if (evt == '' || evt == null || evt == undefined) {
                this.isEndDateEntryValid = true;
                this.invoiceData.datePayPeriodEnd = null;
                return;
            }

            if (this.commonFunctions.isDateValid(evt)) {
                this.isEndDateEntryValid = true;
            } else {
                this.isEndDateEntryValid = false;
            }
        }
    }

    onInputDateSelect(evt: any, inputName: any) {
        this.isDataChanged = true;

        if (inputName === 'payableDate') {
            this.isPayableDateEntryValid = true;
            this.invoiceData.dateAp = this.commonFunctions.formatDateMmddyyyy(evt.toUTCString());
        } else if (inputName === 'issueDate') {
            this.isStartDateEntryValid = true;
            this.invoiceData.datePayPeriodStart = this.commonFunctions.formatDateMmddyyyy(evt.toUTCString());
        } else if (inputName === 'expireDate') {
            this.isEndDateEntryValid = true;
            this.invoiceData.datePayPeriodEnd = this.commonFunctions.formatDateMmddyyyy(evt.toUTCString());
        }
    }

    onSubmit() {
        this.setDatesToUtc();
        this.invoiceData.modifierId = this.user['id'];
        this.invoiceData.bondId = this.bondData.id;
        if (this.validateFields()) {
            this.invoiceService.updateInvoice(this.invoiceData).subscribe(() => {
                this.sendMessage('success', 'Success', `The invoice has been successfully updated`);
                this.closeblcModal();
                this.getBondInvoices.emit();
            }, () => {
                this.sendMessage('error', 'Error', `An error occurred updating the invoice`);
            });
        }
    }

    sendMessage(severity: string, summary: string, detail: string): void {
        this.sendMessageEmit.emit([severity, summary, detail]);
    }

    setDatesToUtc(): void {
        if (this.invoiceData.dateAp) {
            const utcd = this.commonFunctions.formatDateMmddyyyy(new Date(this.invoiceData.dateAp).toISOString());
            this.invoiceData.dateAp = utcd;
        }

        if (this.invoiceData.datePayPeriodEnd) {
            const utcd = this.commonFunctions.formatDateMmddyyyy(new Date(this.invoiceData.datePayPeriodEnd).toISOString());
            this.invoiceData.datePayPeriodEnd = utcd;
        }

        if (this.invoiceData.datePayPeriodStart) {
            const utcd = this.commonFunctions.formatDateMmddyyyy(new Date(this.invoiceData.datePayPeriodStart).toISOString());
            this.invoiceData.datePayPeriodStart = utcd;
        }
    }

    validateFields() {
        const endDate = new Date(this.invoiceData.datePayPeriodEnd);
        const startDate = new Date(this.invoiceData.datePayPeriodStart);

        if (!this.invoiceData.amount && this.invoiceData.amount != 0) {
            this.sendMessage('warn', 'Warn', `Please enter the amount`);
            return false;
        }

        if (!this.invoiceData.dateAp) {
            this.sendMessage('warn', 'Warn', `Please enter AP date`);
            return false;
        }

        if (!this.invoiceData.datePayPeriodStart) {
            this.sendMessage('warn', 'Warn', `Please enter a pay period start date`);
            return false;
        }

        if (!this.invoiceData.datePayPeriodEnd) {
            this.sendMessage('warn', 'Warn', `Please enter a pay period end date`);
            return false;
        }

        if (endDate < startDate) {
            this.sendMessage('warn', 'Warn', `Pay Period Start date must come before Pay Period End date`);
            return false;
        }

        return true;
    }
}
