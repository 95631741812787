export class InvoiceUploadParam {
    apDate: Date | string;
    marginOfError: number;
    verificationAmount: number;
    gracePeriod: number;
    file: File;
    fileJson: any[];
    fileName: string;
    fileBrokerName: string;
    fileDate: string;
    userName: string;

    constructor() {
        this.apDate = new Date();
        this.marginOfError = 0;
        this.verificationAmount = 100;
        this.gracePeriod = 0;
        this.file = null;
        this.fileJson = [];
        this.fileName = '';
        this.fileBrokerName = '';
        this.fileDate = '';
    }
}
