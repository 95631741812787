import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { AuthorizationService } from '../services';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private authorizationService: AuthorizationService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {        
        if (this.authorizationService.getAccessToken() === null) {
            this.router.navigate(['unauthorized']);
            return false;
        }

        if (this.authorizationService.authenticated() === false) {
            this.router.navigate(['unauthenticated']);
            return false;
        }

        if (this.authorizationService.isInRole() === false) {
            this.router.navigate(['unassigned']);
            return false;
        }

        return true;
    }
}
