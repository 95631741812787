import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { Broker } from '../../models';
import { SharedService } from './shared.service';

@Injectable()
export class BrokerService {

    private cachedBrokers: Observable<Broker[]> = null;

    constructor(public http: HttpClient, private sharedService: SharedService) { }

    getBrokers(): Observable<Broker[]> {
        if (!this.cachedBrokers) {
            const opts = this.sharedService.getOptsApplication();
            this.cachedBrokers = this.http.get<Broker[]>(`${environment.baseUrl}brokers/getall`, opts);
        }

        return this.cachedBrokers;
    }

    dropCache(): void {
        this.cachedBrokers = null;
    }
}
