export class Amendment {
    id: number;
    bondId: number;
    bondNewAmount: number;
    bondNewDateExpired: Date | string;
    request: string;
    dateEffective: Date | string;
    dateMailed: Date | string;
    dateCreated: Date;
    dateModified: Date;
    modifierID: number;
    oldID: number;
}
