import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthorizationService } from '../../services';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    authInterval: any;

    constructor(private authorizationService: AuthorizationService, private router: Router, private route: ActivatedRoute) { }

    ngOnInit(): void {
        this.authInterval = setInterval(() => {
            this.verifyAuthentication();
        }, 500);
    }

    verifyAuthentication(): void {
        var isAuthenticated = this.authorizationService.authenticated();
        console.log('LoginComponent:verifyAuthentication()-' + isAuthenticated);

        if (isAuthenticated) {
            this.router.navigate(['/search-bond'], { relativeTo: this.route });
            clearInterval(this.authInterval);
        }
    }
}
