import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { finalize, switchMap, tap } from 'rxjs/operators';
import { CommonFunctions } from 'src/functions';
import { Amendment, BankWithFinancialGuarantee, Bond, User } from 'src/models';
import { AmountTypeEnum } from 'src/models/enums';

import { AmendmentService, AuthorizationService, BankService, BondService, UserService } from '../../services';

@Component({
    selector: 'app-create-amendment',
    templateUrl: './create-amendment.component.html',
    styleUrls: ['./create-amendment.component.css'],
    providers: [MessageService]
})
export class CreateAmendmentComponent implements OnInit {
    @Input() bondData: Bond;
    @Input() displayDialog: boolean = false;
    @Input() singleBondLimit: number;

    @Output() availableCommitmentChange = new EventEmitter();
    @Output() bondDataChange = new EventEmitter();
    @Output() getAmendments = new EventEmitter();
    @Output() onDialogClose: EventEmitter<any> = new EventEmitter();

    amendment = new Amendment;
    availableCommitment: number;
    bankData: BankWithFinancialGuarantee;
    isAmountTypeFinancialGuarantee: boolean;
    isDataChanged: boolean;
    isEffectiveDateEntryValid: boolean;
    isExpireDateEntryValid: boolean;
    isMailDateEntryValid: boolean;
    maxAmount: number = 999999999999.99; // 999 billion instead of 922337203685477.58 = Max value for SQL DB Type Money
    type: string = '';
    user: User = null;

    constructor(private authService: AuthorizationService,
        private amendmentService: AmendmentService,
        private bankService: BankService,
        private bondService: BondService,
        private commonFunctions: CommonFunctions,
        private currencyPipe: CurrencyPipe,
        private datePipe: DatePipe,
        private messageService: MessageService,
        private userService: UserService) { }

    ngOnInit(): void {
        this.isExpireDateEntryValid = true;
        this.isMailDateEntryValid = true;
        this.isEffectiveDateEntryValid = true;
        this.isDataChanged = false;

        this.getUser();
    }

    closeblcModal(): void {
        this.onDialogClose.emit(this.displayDialog);
        this.resetForm();
    }

    getBondType(): string {
        return this.bondData.bondType == 0 ? `Surety's` : `Bank's`;
    }

    getUser() {
        this.userService.getUser(this.authService.getUsername()).subscribe((user: User) => {
            if (user) {
                this.user = user;
            }
            else {
                this.messageService.add({ severity: 'error', summary: 'User data not found!', detail: `Couldn\'t find \'${this.authService.getUsername()}\' in the dbo.Users table` });
            }
        }, (err) => {
            this.messageService.add({ severity: 'error', summary: 'Unexpected Error Occured', detail: `An error occurred getting data for User: ${this.authService.getUsername()}` });
        });
    }

    initializeDialogHeader(): string {
        return this.bondData.isLetterOfCredit ? `Letter of Credit: #${this.bondData.number}` : `Bond: #${this.bondData.number}`;
    }

    onInput(event: any): void {
        if (event.value > this.maxAmount) {
            this.messageService.add({
                severity: 'error', summary: 'Error',
                detail: 'Amended Amount is out of range. Please set amount to less than ' + this.currencyPipe.transform(this.maxAmount, 'USD', 'symbol', '1.2-2') + '.'
            });
            this.amendment.bondNewAmount = 0;
            return;
        }
        this.amendment.bondNewAmount = event.value;
        this.setRequest();
    }

    onInputDateChange(evt: any, inputName: any): void {
        this.isDataChanged = true;

        if (inputName === 'expireDate') {
            if (evt == '' || evt == null || evt == undefined) {
                this.isExpireDateEntryValid = true;
                this.amendment.bondNewDateExpired = null;
                return;
            }

            if (this.commonFunctions.isDateValid(evt)) {
                this.isExpireDateEntryValid = true;
                this.amendment.request = 'Expire date set to ' + this.commonFunctions.formatDateMmddyyyy(new Date(evt).toUTCString());
                if (this.amendment.bondNewAmount || this.amendment.bondNewAmount == 0) {
                    this.amendment.bondNewAmount = null;
                }
            } else {
                this.isExpireDateEntryValid = false;
            }
        } else if (inputName === 'mailDate') {
            if (evt == '' || evt == null || evt == undefined) {
                this.isMailDateEntryValid = true;
                this.amendment.dateMailed = null;
                return;
            }

            if (this.commonFunctions.isDateValid(evt)) {
                this.isMailDateEntryValid = true;
            } else {
                this.isMailDateEntryValid = false;
            }
        } else if (inputName === 'effectiveDate') {
            if (evt == '' || evt == null || evt == undefined) {
                this.isEffectiveDateEntryValid = true;
                this.amendment.dateEffective = null;
                return;
            }

            if (this.commonFunctions.isDateValid(evt)) {
                this.isEffectiveDateEntryValid = true;
            } else {
                this.isEffectiveDateEntryValid = false;
            }
        }
    }

    onInputDateSelect(evt: any, inputName: any): void {
        this.isDataChanged = true;
        if (inputName === 'expireDate') {
            this.isExpireDateEntryValid = true;
            this.amendment.bondNewDateExpired = this.commonFunctions.formatDateMmddyyyy(evt.toUTCString());
            this.amendment.request = 'Expire date set to ' + this.amendment.bondNewDateExpired;
            if (this.amendment.bondNewAmount || this.amendment.bondNewAmount == 0) {
                this.amendment.bondNewAmount = null;
            }
        } else if (inputName === 'mailDate') {
            this.isMailDateEntryValid = true;
            this.amendment.dateMailed = this.commonFunctions.formatDateMmddyyyy(evt.toUTCString());
        } else if (inputName === 'effectiveDate') {
            this.isEffectiveDateEntryValid = true;
            this.amendment.dateEffective = this.commonFunctions.formatDateMmddyyyy(evt.toUTCString());
        }
    }

    onSubmit(): void {
        this.setDatesToUtc();
        this.amendment.modifierID = this.user['id'];
        this.amendment.bondId = this.bondData.id;
        if (this.validateFields()) {
            this.amendmentService.addAmendment(this.amendment).pipe(
                switchMap(() => {
                    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'The amendment has been successfully added' });
                    return this.bondService.updateBond(this.bondData);
                }),
                switchMap(() => {
                    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'The Bond/LC has been sucessfully updated' });
                    return this.bondService.getBondByNumber(this.bondData.number.toString());
                }),
                switchMap((searchResult: Bond) => {
                    this.bondData = searchResult;
                    this.bondData.currentDateExpired = this.bondData.currentDateExpired ? this.datePipe.transform(this.bondData.currentDateExpired, 'MM/dd/yyyy') : null;
                    this.bondData.bondType = this.commonFunctions.getBondType(this.bondData.isLetterOfCredit);
                    this.bondData = this.commonFunctions.getBondStatus(this.bondData).modifiedBond;

                    if (this.bondData.amountTypeId === AmountTypeEnum.FinancialGuarantee) {
                        this.isAmountTypeFinancialGuarantee = true;
                    } else {
                        this.isAmountTypeFinancialGuarantee = false;
                    }

                    return this.bankService.getAvailableCommitment(this.bondData.bankId, this.isAmountTypeFinancialGuarantee);
                })
            ).subscribe((bankInformation: BankWithFinancialGuarantee) => {
                this.bankData = bankInformation;
                if (this.isAmountTypeFinancialGuarantee) {
                    this.availableCommitment = this.bankData.financialGuaranteeAvailableCommitment;
                }
                else {
                    this.availableCommitment = this.bankData.availableCommitment;
                }
                this.availableCommitmentChange.emit(this.availableCommitment);
                this.bondDataChange.emit(this.bondData);
                this.getAmendments.emit();
                this.resetForm();
                this.closeblcModal();
            }, (err) => {
                this.messageService.add({ severity: 'error', summary: 'Unexpected Error Occured', detail: 'An error occurred in adding amendment' });
                console.log(err);
            });
        }
    }

    resetForm(): void {
        this.amendment = new Amendment;
        this.isExpireDateEntryValid = true;
        this.isMailDateEntryValid = true;
        this.isEffectiveDateEntryValid = true;
    }

    setDatesToUtc(): void {
        if (this.amendment.bondNewDateExpired) {
            const utcd = this.commonFunctions.formatDateMmddyyyy(new Date(this.amendment.bondNewDateExpired).toISOString());
            this.amendment.bondNewDateExpired = utcd;
        }

        if (this.amendment.dateMailed) {
            const utcd = this.commonFunctions.formatDateMmddyyyy(new Date(this.amendment.dateMailed).toISOString());
            this.amendment.dateMailed = utcd;
        }

        if (this.amendment.dateEffective) {
            const utcd = this.commonFunctions.formatDateMmddyyyy(new Date(this.amendment.dateEffective).toISOString());
            this.amendment.dateEffective = utcd;
        }
    }

    setRequest(): void {
        if (this.amendment.bondNewAmount == null) {
            this.amendment.request = '';
        } else if (this.amendment.bondNewAmount > this.bondData.currentAmount) {
            this.amendment.request = 'Increase to $' + this.amendment.bondNewAmount;
        } else {
            this.amendment.request = 'Decrease to $' + this.amendment.bondNewAmount;
        }
        if (this.amendment.bondNewDateExpired) {
            this.amendment.bondNewDateExpired = null;
        }
    }

    validateFields(): boolean {
        this.type = this.getBondType();
        if (!this.amendment.request) {
            this.messageService.add({ severity: 'warn', summary: 'Warn', detail: 'Please enter the request' });
            return false;
        }
        if ((this.amendment.bondNewAmount > this.bondData.currentAmount) && (this.amendment.bondNewAmount > this.singleBondLimit)) {
            this.messageService.add({ severity: 'warn', summary: 'Warn', detail: 'Amended amount exceeds the ' + this.type + ' single bond limit amount' });
        }

        if (this.amendment.bondNewAmount < 0) {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please enter valid amended amount' });
            return false;
        }

        if (this.amendment.dateEffective && ((this.amendment.bondNewAmount - this.bondData.currentAmount) >
            this.availableCommitment) && (this.amendment.bondNewAmount > this.singleBondLimit)) {
            this.messageService.add({
                severity: 'warn', summary: 'Warn',
                detail: 'Amended amount will cause the available commitment for the ' + this.type + ' to become negative'
            });
            return true;
        }

        return true;
    }
}
