import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import * as XLSX from 'exceljs/dist/exceljs.min.js';
import { MessageService } from 'primeng/api';
import { switchMap } from 'rxjs/operators';

import { AmountTypeEnum, BLCTypeEnum } from '../../../models/enums';
import { CommonFunctions } from '../../../functions';
import {
    Amendment, AmountType, Bank, BankWithFinancialGuarantee, BlcAmendmentReport,
    BlcAmendmentReportParam, Bond, Company, Document, ReportColumn
} from '../../../models';
import {
    AmountTypeService, AuthorizationService, BankService, BondService,
    CompanyService, DocumentService, ExcelService, ReportService
} from '../../../services';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'app-b-lc-amendment',
    templateUrl: './b-lc-amendment.component.html',
    styleUrls: ['./b-lc-amendment.component.css'],
    providers: [MessageService]
})
export class BLCAmendmentComponent implements OnInit {
    amendmentList: Amendment[];
    amountTypeList: AmountType[] = [];
    availableCommitment: number = null;
    bankData: BankWithFinancialGuarantee;
    banksForDialog: Bank[] = [];
    bankorSuretyList: Bank[] = [];
    bankorSuretyListTarget: Bank[] = [];
    bondData: Bond;
    bondType: string = '0';
    bondOrLCType: any[] = [
        { label: `-- All --`, field: `0` },
        { label: `Bond`, field: `1` },
        { label: `Letter of Credit`, field: `2` }
    ];
    cols: ReportColumn[];
    companyList: Company[] = [];
    companyListSource: Company[] = [];
    displayEditDialog: boolean = false;
    documents: Document[];
    exportHeader: string[] = [];
    filteredCount: any[] = [];
    isAmountTypeFinancialGuarantee: boolean;
    isLetterofCredit: boolean = false;
    isStatusCanceled: boolean;
    isValidParams: boolean = false;
    reportExcelList: any[] = [];
    reportGridList: any[] = [];
    reportParameter: BlcAmendmentReportParam = new BlcAmendmentReportParam();
    reportTitle: string = null;
    showNoResultsMessage: boolean = false;
    showResults: boolean = false;
    singleBondLimit: string = null;

    constructor(private amountTypeService: AmountTypeService,
        private authorizationService: AuthorizationService,
        private bankService: BankService,
        private bondService: BondService,
        private commonFunctions: CommonFunctions,
        private companyService: CompanyService,
        private datePipe: DatePipe,
        private documentService: DocumentService,
        private excelService: ExcelService,
        private messageService: MessageService,
        private reportService: ReportService) { }

    ngOnInit(): void {
        this.authorizationService.checkBlcUserAccess();
        this.reportParameter = new BlcAmendmentReportParam();

        //  load amount types !
        this.amountTypeService.getAmountTypes()
            .subscribe((result: AmountType[]) => {
                this.amountTypeList = result;
                this.amountTypeList.sort((a, b) => a.name.localeCompare(b.name));
            }, () => {
                this.messageService.add({
                    severity: 'error', summary: 'Unexpected Error Occured',
                    detail: 'An error occurred getting Amount Types.'
                });
            });

        this.companyService.getCompanies()
            .subscribe((companies) => {
                this.companyList = companies;
                this.companyList = this.commonFunctions.sortAndFormatCompanies(this.companyList);
                this.companyListSource = JSON.parse(JSON.stringify(this.companyList));
            }, error => {
                this.messageService.add({
                    severity: 'error', summary: 'Unexpected Error Occured',
                    detail: 'An error occurred getting Companies.'
                });
            });

        this.bankService.getBanks(true)
            .subscribe((sureties) => {
                this.bankorSuretyList = sureties;
                this.bankorSuretyList.sort((a, b) => a.name.localeCompare(b.name));
            }, () => {
                this.messageService.add({
                    severity: 'error', summary: 'Unexpected Error Occured',
                    detail: 'An error occurred getting Banks.'
                });
            });

        this.isValidParams = this.validateReportParams(this.bankorSuretyListTarget, this.reportParameter).length < 1 ? true : false;
    }

    dateRangeValidator(fDate: String | Date, tDate: string | Date): any {
        const dateLast = new Date(this.datePipe.transform(tDate, 'MM/dd/yyyy'));
        const dateFirst = new Date(this.datePipe.transform(fDate, 'MM/dd/yyyy'));
        if (fDate !== null && tDate !== null && fDate > tDate) {
            return { valid: false, msg: `Please specify a From and To date range with 'From Effective Date' less than the 'To Effective Date'` };
        }
        else {
            //Determine if selected date range is between 6 years
            var diff = (dateLast.getTime() - dateFirst.getTime()) / 1000;
            diff /= (60 * 60 * 24);
            const diffFinal = Math.abs(diff / 365.25);
            if (diffFinal > 6) {
                return { valid: false, msg: `Maximum range of Effective Date is 6 years.` };
            }
        }

        return { valid: true, msg: `Date range are valid.` };
    };

    exportExcel(): void {
        // Set export file name
        const exportFileName = 'AmendmentReport';
        const workbook = new XLSX.Workbook();
        const worksheet = workbook.addWorksheet('Report Results');

        // Add title row
        const titleRow = worksheet.addRow([this.reportTitle]);
        worksheet.mergeCells('A1:J1');
        worksheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' };

        // Set font, size and style in title row
        titleRow.font = { name: 'Calibri', family: 4, size: 16, underline: 'double', bold: true };

        // Blank Row
        worksheet.addRow([]);

        // Set column widths to fix header row
        worksheet.columns.forEach((column) => {
            column.width = 17;
        });

        // Add Header Row
        this.exportHeader.push('Amendment Request');
        const headerRow = worksheet.addRow(this.exportHeader);

        // Wrap and align the header text
        headerRow.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };

        // Set header row height
        headerRow.height = 60;

        // Fill and Border Headers
        headerRow.eachCell((cell) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'D3D3D3' },
                bgColor: { argb: '' }
            };
            cell.border = { top: { style: 'none' }, left: { style: 'none' }, bottom: { style: 'none' }, right: { style: 'none' } };
            cell.font = { weight: 'bold' };
        });

        let isTotalRow = false;

        // Add Data
        this.reportExcelList.forEach(dataRow => {
            const dataStrings: any[] = [];

            // Convert each prop into a string array
            Object.values(dataRow).forEach((val: any, index: number) => {
                switch (index) {
                    case 6: //Effective Date
                        if (val?.toString()) {
                            dataStrings.push(new Date(val));
                        }
                        else {
                            dataStrings.push(val);
                        }
                        break;
                    default: //Everything else
                        dataStrings.push(val);
                }
            });

            // Add the data row
            const row = worksheet.addRow(dataStrings);
            row.eachCell({ includeEmpty: true }, (cell => {
                cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            }));

            // Check if it is a eoy total row
            if (row.getCell(1).value !== null) {
                if (row.getCell(1).value.toString().includes('Total')) {
                    isTotalRow = true;
                }
            }

            if (row.getCell(2).value !== null) {
                if (row.getCell(2).value.toString().includes('Total')) {
                    isTotalRow = true;
                }
            }

            // Format the cells
            row.eachCell((cell) => {
                // If eoy total row, set to bold
                if (isTotalRow) {
                    cell.font = { bold: true };
                }

                // If negative value, set font to red
                if (typeof cell.value === 'string' && cell.value.toString().includes('<') && cell.value.toString().includes('>')) {
                    cell.font = { color: { argb: 'FF0000' } };
                    cell.value = cell.value.replace('-', '');
                    cell.value = cell.value.replace('<', '(');
                    cell.value = cell.value.replace('>', ')');
                }
            });

            if (isTotalRow) {
                isTotalRow = false;
            }

        });

        this.excelService.exportAsExcelFile(workbook, exportFileName);
    }

    onBondModalEditDialogClose(): void {
        this.displayEditDialog = false;
    }

    onFromEffectiveDateChange(event: any): void {
        if (event.target?.value === 'mm/dd/yyyy') {
            this.reportParameter.fromEffDate == null;
        }
        this.isValidParams = this.validateReportParams(this.bankorSuretyListTarget, this.reportParameter).length < 1 ? true : false;
    }

    onGettingBondToView(bondId: number): void {
        try {
            if (bondId === null || bondId === undefined) {
                throw new Error(`Invalid BondId`);
            }

            const selectedBond = this.filteredCount.find(bond => bond.bondId == bondId);

            forkJoin(
                this.bondService.getBondById(selectedBond.bondId),
                this.bankService.getBanks(true),
                this.documentService.getDocumentsByBlcId(selectedBond.bondId),
                this.bankService.getAvailableCommitment(selectedBond.bankId, true),
                this.bankService.getAvailableCommitment(selectedBond.bankId, false)
            )
                .subscribe(([bond, banks, documents, fgAvailableCommitment, availableCommitment]) => {
                    if (bond === null || bond === undefined || bond?.id < 1) {
                        throw new Error(`Bond data for ${bondId} not found.`);
                    }

                    this.bondData = bond;
                    this.bondData.bondType = bond.isLetterOfCredit ? BLCTypeEnum.LetterOfCredit : BLCTypeEnum.Bond;
                    this.bondData.dateExpired = this.bondData.currentDateExpired;

                    const getBondStatusResult = this.commonFunctions.getBondStatus(this.bondData);
                    this.bondData = getBondStatusResult.modifiedBond;
                    this.isStatusCanceled = getBondStatusResult.isStatusCanceled;

                    if (this.bondData.amountTypeId === AmountTypeEnum.FinancialGuarantee) {
                        this.isAmountTypeFinancialGuarantee = true;
                        this.bankData = fgAvailableCommitment;
                        this.availableCommitment = this.bankData.financialGuaranteeAvailableCommitment;
                    } else {
                        this.isAmountTypeFinancialGuarantee = false;
                        this.bankData = availableCommitment;
                        this.availableCommitment = this.bankData.availableCommitment;
                    }

                    this.banksForDialog = banks;
                    this.banksForDialog.sort((a, b) => a.name.localeCompare(b.name));
                    const result2 = this.commonFunctions.setSingleBondLimit(this.banksForDialog, this.bondData);
                    this.singleBondLimit = result2.sbl;

                    this.documents = documents as Document[];
                    this.displayEditDialog = true;
                }, () => {
                    this.messageService.add({
                        severity: 'error', summary: 'Unexpected Error Occured',
                        detail: 'An error occurred getting the BLC.'
                    });
                })
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Unexpected Error Occured',
                detail: 'An error occurred during multiple process after getting the BLC record.'
            });
        }
    }

    onLinkClickEvent(event: any): void {
        const resultArray = event.data.split('_');
        const type = resultArray[0];
        const id = resultArray[1];

        if (type === 'bondId') {
            this.onGettingBondToView(id);
        } else {
            this.messageService.add({
                severity: 'error',
                summary: 'Unexpected Error Occured',
                detail: 'Invalid link type on selected record'
            });
        }
    }

    onMoveSourceToTarget(): void {
        this.showResults = false;
        this.reportGridList = [];
        this.bankorSuretyListTarget.sort((src, tgt) => src.name.localeCompare(tgt.name));
        this.isValidParams = this.validateReportParams(this.bankorSuretyListTarget, this.reportParameter).length < 1 ? true : false;
    }

    onMoveTargetToSource(): void {
        this.showResults = false;
        this.reportGridList = [];
        this.bankorSuretyList.sort((src, tgt) => src.name.localeCompare(tgt.name));
        this.isValidParams = this.validateReportParams(this.bankorSuretyListTarget, this.reportParameter).length < 1 ? true : false;
    }

    onResetSearch(): void {
        this.bankorSuretyList.push(...this.bankorSuretyListTarget);
        this.bankorSuretyList.sort((src, tgt) => src.name.localeCompare(tgt.name));
        this.bankorSuretyListTarget = [];

        this.reportGridList = [];
        this.reportExcelList = [];
        this.exportHeader = [];
        this.showResults = false;
        this.bondOrLCType = [
            { label: `-- All --`, field: `0` },
            { label: `Bond`, field: `1` },
            { label: `Letter of Credit`, field: `2` }
        ];

        this.reportParameter = new BlcAmendmentReportParam();
        this.isValidParams = this.validateReportParams(this.bankorSuretyListTarget, this.reportParameter).length < 1 ? true : false;
    }

    onSearch(): void {
        try {
            this.reportGridList = [];
            this.reportExcelList = [];
            this.exportHeader = [];
            const dValidate = this.dateRangeValidator(this.reportParameter.fromEffDate, this.reportParameter.toEffDate);
            if (dValidate.valid === false) {
                alert(dValidate.msg);
                return;
            }
            this.setDatesToUtc();

            const validatedParams = this.validateReportParams(this.bankorSuretyListTarget, this.reportParameter);
            this.isValidParams = validatedParams.length < 1 ? true : false;
            for (const validatedItem of validatedParams) {
                if (validatedItem.isValid === false) {
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Entry Validation Error Occured',
                        detail: validatedItem.message
                    });
                    return;
                }
            }
            // Set search params and report results title
            this.reportTitle = `Bond & Letter of Credit Amendment Report`;

            // Set result table columns
            this.cols = [
                { field: 'isLetterOfCredit', header: 'B/LC' },
                { field: 'bankName', header: 'Bank/Surety', widthPercentage: 10 },
                { field: 'companyName', header: 'Market' },
                { field: 'bondNumber', header: 'Instrument #', link: 'bondId' },
                { field: 'bondAmount', header: 'Initial Amount', dataType: 'currency' },
                { field: 'currentBondAmount', header: 'Current Amount', dataType: 'currency' },
                { field: 'effectiveDate', header: 'Effective Date', dataType: 'date' },
                { field: 'amendmentAmount', header: 'Amendment Amount', dataType: 'currency' },
                { field: 'difference', header: 'Difference', dataType: 'currency' }
            ];
            // Set header row values for export
            for (const col of this.cols) {
                if (col.header) {
                    this.exportHeader.push(col.header);
                }
            }

            this.reportParameter.selectedBanks = [];
            for (const oitem of this.bankorSuretyListTarget) {
                if ((oitem.id) || oitem.id != null) {
                    this.reportParameter.selectedBanks.push(oitem.id);
                }
            }

            this.reportService.getBlcAmendment(this.reportParameter)
                .subscribe((res: any[]) => {
                    // Format the date objects to remove timestamps
                    res.forEach((result) => {
                        this.commonFunctions.trimTimeFromDatePropertiesInObject(result);
                    });
                    var overallCurrentAmountTotal = 0;
                    var overallDifferenceTotal = 0;
                    const decimalFormat = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
                    const groupedBy = this.commonFunctions.groupBy(res, `bankName`);
                    const bondNumMap = {};

                    if (Object.keys(groupedBy).length > 0) {
                        for (const rpt in groupedBy) {
                            const report = groupedBy[rpt];
                            const arrbank = report.map(b => b.bank);
                            const bnk = new Set(arrbank);
                            const uniqueBanks = Array.from(bnk);

                            var filtered: BlcAmendmentReport[] = [];
                            for (const theBank of uniqueBanks) {
                                var bankCurrentAmountTotal: number = 0;
                                var bankInitialAmountTotal: number = 0;
                                var bankDifferenceTotal: number = 0;
                                var amendmentAmt: number = 0;

                                filtered = report.filter(bnk => bnk.bank === theBank);

                                for (const blcRecord of filtered) {
                                    if (bondNumMap[blcRecord.bondNumber]) {
                                        blcRecord.bondAmount = null;
                                        blcRecord.currentBondAmount = null;
                                        const formAmendmentAmount = (blcRecord.amendmentAmount) ? parseFloat(blcRecord.amendmentAmount.toFixed(2)).toLocaleString('en', decimalFormat) : `0.00`;
                                        const formDifference = (blcRecord.amendmentAmount - amendmentAmt) ? parseFloat((blcRecord.amendmentAmount - amendmentAmt).toFixed(2)).toLocaleString('en', decimalFormat) : `0.00`;

                                        this.reportGridList.push({
                                            bondId: null,
                                            isLetterOfCredit: null,
                                            bankName: null,
                                            companyName: null,
                                            bondNumber: null,
                                            bondAmount: null,
                                            currentBondAmount: null,
                                            effectiveDate: blcRecord.effectiveDate,
                                            amendmentAmount: (blcRecord.amendmentAmount) ? parseFloat(blcRecord.amendmentAmount.toFixed(2)) : 0.00,
                                            difference: (blcRecord.amendmentAmount - amendmentAmt) ? parseFloat((blcRecord.amendmentAmount - amendmentAmt).toFixed(2)) : 0.00,
                                        });

                                        this.reportExcelList.push({
                                            isLetterOfCredit: null,
                                            bankName: null,
                                            companyName: null,
                                            bondNumber: null,
                                            bondAmount: null,
                                            currentBondAmount: null,
                                            effectiveDate: blcRecord.effectiveDate,
                                            amendmentAmount: blcRecord.amendmentAmount < 0 ? `<$${formAmendmentAmount}>` : `$${formAmendmentAmount}`,
                                            difference: (blcRecord.amendmentAmount - amendmentAmt) < 0 ? `<$${formDifference}>` : `$${formDifference}`,
                                            amendmentRequest: blcRecord.amendmentRequest
                                        });

                                        bankDifferenceTotal += blcRecord.amendmentAmount - amendmentAmt;
                                        overallDifferenceTotal += blcRecord.amendmentAmount - amendmentAmt;
                                    }
                                    else {
                                        this.reportGridList.push({
                                            bondId: blcRecord.bondId,
                                            isLetterOfCredit: blcRecord.isLetterOfCredit ? `LC` : `B`,
                                            bankName: blcRecord.bankName,
                                            companyName: blcRecord.companyName,
                                            bondNumber: blcRecord.bondNumber,
                                            bondAmount: (blcRecord.bondAmount) ? parseFloat(blcRecord.bondAmount.toFixed(2)) : null,
                                            currentBondAmount: (blcRecord.currentBondAmount) ? parseFloat(blcRecord.currentBondAmount.toFixed(2)) : `0.00`,
                                            effectiveDate: blcRecord.effectiveDate,
                                            amendmentAmount: (blcRecord.amendmentAmount) ? parseFloat(blcRecord.amendmentAmount.toFixed(2)) : 0.00,
                                            difference: (blcRecord.amendmentAmount - blcRecord.bondAmount) ? parseFloat((blcRecord.amendmentAmount - blcRecord.bondAmount).toFixed(2)) : 0.00,
                                        });

                                        const formCurrentAmount = (blcRecord.currentBondAmount) ? parseFloat(blcRecord.currentBondAmount.toFixed(2)).toLocaleString('en', decimalFormat) : `0.00`;
                                        const formInitialAmount = (blcRecord.bondAmount) ? parseFloat(blcRecord.bondAmount.toFixed(2)).toLocaleString('en', decimalFormat) : `0.00`;
                                        const formDifference = (blcRecord.amendmentAmount - blcRecord.bondAmount) ? parseFloat((blcRecord.amendmentAmount - blcRecord.bondAmount).toFixed(2)).toLocaleString('en', decimalFormat) : `0.00`;
                                        const formAmendmentAmount = (blcRecord.amendmentAmount) ? parseFloat(blcRecord.amendmentAmount.toFixed(2)).toLocaleString('en', decimalFormat) : `0.00`;

                                        this.reportExcelList.push({
                                            isLetterOfCredit: blcRecord.isLetterOfCredit ? `LC` : `B`,
                                            bankName: blcRecord.bankName,
                                            companyName: blcRecord.companyName,
                                            bondNumber: blcRecord.bondNumber,
                                            bondAmount: blcRecord.bondAmount < 0 ? `<$${formInitialAmount}>` : `$${formInitialAmount}`,
                                            currentBondAmount: blcRecord.currentBondAmount < 0 ? `<$${formCurrentAmount}>` : `$${formCurrentAmount}`,
                                            effectiveDate: blcRecord.effectiveDate,
                                            amendmentAmount: blcRecord.amendmentAmount < 0 ? `<$${formAmendmentAmount}>` : `$${formAmendmentAmount}`,
                                            difference: (blcRecord.amendmentAmount - blcRecord.bondAmount) < 0 ? `<$${formDifference}>` : `$${formDifference}`,
                                            amendmentRequest: blcRecord.amendmentRequest
                                        });
                                        bankDifferenceTotal += blcRecord.amendmentAmount - blcRecord.bondAmount;
                                        overallDifferenceTotal += blcRecord.amendmentAmount - blcRecord.bondAmount;
                                    }
                                    bondNumMap[blcRecord.bondNumber] = true;
                                    amendmentAmt = blcRecord.amendmentAmount;

                                    //  resolve filteredCount here for the report result components filteredCount input
                                    this.filteredCount.push(blcRecord);
                                    bankCurrentAmountTotal += blcRecord.currentBondAmount;
                                    bankInitialAmountTotal += blcRecord.bondAmount;
                                    overallCurrentAmountTotal += blcRecord.currentBondAmount;
                                }

                                if (filtered.length != 0) {
                                    const formCurrentAmountTotal = (bankCurrentAmountTotal) ? parseFloat(bankCurrentAmountTotal.toFixed(2)).toLocaleString('en', decimalFormat) : `0.00`;
                                    const initAmtTotal = (bankInitialAmountTotal) ? parseFloat(bankInitialAmountTotal.toFixed(2)).toLocaleString('en', decimalFormat) : `0.00`;
                                    const diffTotal = (bankDifferenceTotal) ? parseFloat(bankDifferenceTotal.toFixed(2)).toLocaleString('en', decimalFormat) : `0.00`;

                                    this.reportGridList.push({
                                        isLetterOfCredit: null,
                                        bankName: `Total - ${report[0]?.bankName}`,
                                        companyName: null,
                                        bondNumber: null,
                                        bondAmount: bankInitialAmountTotal,
                                        currentBondAmount: bankCurrentAmountTotal,
                                        effectiveDate: null,
                                        amendmentAmount: null,
                                        difference: bankDifferenceTotal,
                                        groupKey: 17000
                                    });

                                    this.reportExcelList.push({
                                        isLetterOfCredit: null,
                                        bankName: `Total - ${report[0]?.bankName}`,
                                        companyName: null,
                                        bondNumber: null,
                                        bondAmount: +bankInitialAmountTotal < 0 ? `<$${initAmtTotal}>` : `$${initAmtTotal}`,
                                        currentBondAmount: +bankCurrentAmountTotal < 0 ? `<$${formCurrentAmountTotal}>` : `$${formCurrentAmountTotal}`,
                                        effectiveDate: null,
                                        amendmentAmount: null,
                                        difference: +bankDifferenceTotal < 0 ? `<$${diffTotal}>` : `$${diffTotal}`,
                                        amendmentRequest: null

                                    });
                                } else {
                                    this.reportGridList.push({
                                        isLetterOfCredit: `No matches found.`,
                                        bankName: null,
                                        companyName: null,
                                        bondNumber: null,
                                        bondAmount: null,
                                        currentBondAmount: null,
                                        effectiveDate: null,
                                        amendmentAmount: null,
                                        difference: null,
                                        groupKey: 19000
                                    });

                                    this.reportExcelList.push({
                                        isLetterOfCredit: `No matches found.`,
                                        bankName: null,
                                        companyName: null,
                                        bondNumber: null,
                                        bondAmount: null,
                                        currentBondAmount: null,
                                        effectiveDate: null,
                                        amendmentAmount: null,
                                        difference: null,
                                        amendmentRequest: null
                                    });
                                    this.showResults = true;
                                }
                            }
                        }
                        const totalCurrentAmount = (overallCurrentAmountTotal) ? parseFloat(overallCurrentAmountTotal.toFixed(2)).toLocaleString('en', decimalFormat) : `0.00`;
                        const totalDifference = (overallDifferenceTotal) ? parseFloat(overallDifferenceTotal.toFixed(2)).toLocaleString('en', decimalFormat) : `0.00`;

                        this.reportGridList.push({
                            isLetterOfCredit: null,
                            bankName: `Grand Total - All Banks`,
                            companyName: null,
                            bondNumber: null,
                            bondAmount: null,
                            currentBondAmount: overallCurrentAmountTotal,
                            effectiveDate: null,
                            amendmentAmount: null,
                            difference: overallDifferenceTotal,
                            groupKey: 18000
                        });

                        this.reportExcelList.push({
                            isLetterOfCredit: null,
                            bankName: `Grand Total - All Banks`,
                            companyName: null,
                            bondNumber: null,
                            bondAmount: null,
                            currentBondAmount: +overallCurrentAmountTotal < 0 ? `<$${totalCurrentAmount}>` : `$${totalCurrentAmount}`,
                            effectiveDate: null,
                            amendmentAmount: null,
                            difference: +overallDifferenceTotal < 0 ? `<$${totalDifference}>` : `$${totalDifference}`,
                            amendmentRequest: null
                        });

                        this.showResults = true;
                    }
                    else {
                        this.reportGridList.push({
                            isLetterOfCredit: `No matches found.`,
                            bankName: null,
                            companyName: null,
                            bondNumber: null,
                            bondAmount: null,
                            currentBondAmount: null,
                            effectiveDate: null,
                            amendmentAmount: null,
                            difference: null,
                            groupKey: 19000
                        });

                        this.reportExcelList.push({
                            isLetterOfCredit: `No matches found.`,
                            bankName: null,
                            companyName: null,
                            bondNumber: null,
                            bondAmount: null,
                            currentBondAmount: null,
                            effectiveDate: null,
                            amendmentAmount: null,
                            difference: null,
                            amendmentRequest: null
                        });
                        this.showResults = true;
                    }
                });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Report Layout Error Occured',
                detail: `There were error(s) occured during report layout process.`
            });
        }
        finally {
            this.filteredCount = [];
        }
    }

    onToEffectiveDateChange(event: any): void {
        this.showResults = false;
        this.reportGridList = [];

        if (event.target?.value === 'mm/dd/yyyy') {
            this.reportParameter.toEffDate == null;
        }

        this.isValidParams = this.validateReportParams(this.bankorSuretyListTarget, this.reportParameter).length < 1 ? true : false;
    }

    onTypeChange(event: any): void {
        this.bondType = event.target.value;
        if (this.bondType === '1') {
            this.isLetterofCredit = false;
            this.bankorSuretyListTarget = [];
            this.reportParameter = new BlcAmendmentReportParam();
            this.bankService.getBanks(false, this.isLetterofCredit)
                .subscribe((sureties: Bank[]) => {
                    this.bankorSuretyList = sureties;
                    this.bankorSuretyList.sort((a, b) => a.name.localeCompare(b.name));
                });

        } else if (this.bondType === '2') {
            this.isLetterofCredit = true;
            this.bankorSuretyListTarget = [];
            this.reportParameter = new BlcAmendmentReportParam();
            this.bankService.getBanks(false, this.isLetterofCredit)
                .subscribe((banks: Bank[]) => {
                    this.bankorSuretyList = banks;
                    this.bankorSuretyList.sort((a, b) => a.name.localeCompare(b.name));
                });
        }
        else if (this.bondType === '0') {
            this.isLetterofCredit = true;
            this.bankorSuretyListTarget = [];
            this.reportParameter = new BlcAmendmentReportParam();
            this.bankService.getBanks(false)
                .subscribe((banks: Bank[]) => {
                    this.bankorSuretyList = banks;
                    this.bankorSuretyList.sort((a, b) => a.name.localeCompare(b.name));
                });
        }
    }

    setDatesToUtc(): void {
        if (this.reportParameter.fromEffDate) {
            const utcd = this.commonFunctions.formatDateMmddyyyy(new Date(this.reportParameter.fromEffDate).toISOString());
            this.reportParameter.fromEffDate = utcd;
        }

        if (this.reportParameter.toEffDate) {
            const utcd = this.commonFunctions.formatDateMmddyyyy(new Date(this.reportParameter.toEffDate).toISOString());
            this.reportParameter.toEffDate = utcd;
        }
    }

    validateReportParams(bankList: Bank[], param: BlcAmendmentReportParam): any[] {
        var retval: any[] = [];
        if (bankList.length < 1) {
            retval.push({
                isValid: false,
                message: `No bank(s)/surety(s) found in the Selected list box. Select either a bank or a surety.`
            });
        }

        if ((!param.fromEffDate) || (param.fromEffDate === '')) {
            retval.push({
                isValid: false,
                message: `Please enter a from date.`
            });
        }

        if ((!param.toEffDate) || (param.toEffDate === '')) {
            retval.push({
                isValid: false,
                message: `Please enter a to date.`
            });
        }

        return retval;
    }
}
