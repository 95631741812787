import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { environment } from '../../environments/environment';
import { AuthorizationService } from '../../services';

@Component({
    selector: 'app-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.css']
})
export class ToolbarComponent implements OnInit {    
    accessLevel: string = '';
    environmentType: string = '';
    title: string = 'PulteGroup - Bonds & Letters for Credit';
    username: string = '';

    constructor(private authService: AuthorizationService, private titleService: Title) { }

    ngOnInit() {
        console.log('ToolbarComponent:ngOnInit()');

        this.setAccessLevel();
        this.setEnvironmentType();
        this.setTitle();
        this.setUserName();
    }

    setAccessLevel(): void {
        if (this.authService.isBlcExceptionsUser()) {
            this.accessLevel = 'Restricted Access';
        } else if (this.authService.isBlcFullAccessUser()) {
            this.accessLevel = 'Full Access';
        } else if (this.authService.isBlcReadOnlyUser()) {
            this.accessLevel = 'Read Only Access';
        } else {
            this.accessLevel = 'Unauthorized';
        }
    }

    setEnvironmentType(): void {        
        if (environment.type !== 'PROD') {
            this.environmentType = environment.type + ' - ';
        }        
    }

    setTitle(): void {
        if (environment.type !== 'PROD') {
            this.title = environment.type + ' - ' + this.title;
        }

        this.titleService.setTitle(this.title);
    }

    setUserName(): void {
        this.username = this.authService.getUsername();
    }
}

