
export class Invoice {
    id: number;
    bondNumber: string;
    amount: number;
    bondId: number;
    dateAp: Date | string;
    datePayPeriodEnd: Date | string;
    datePayPeriodStart: Date | string;
    dateCreated: Date;
    dateModified: Date;
    modifierId: number;
    oldId: number;
    description: string;
}
