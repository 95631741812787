import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { CommonFunctions } from 'src/functions';
import { Bond, User } from 'src/models';
import { Invoice } from 'src/models/Invoice';

import { AuthorizationService, InvoiceService, UserService } from '../../services';

@Component({
    selector: 'app-create-invoice',
    templateUrl: './create-invoice.component.html',
    styleUrls: ['./create-invoice.component.css'],
    providers: [MessageService]
})
export class CreateInvoiceComponent implements OnInit {
    @Input() bondData: Bond;
    @Input() displayDialog: boolean = false;

    @Output() getBondInvoices = new EventEmitter();
    @Output() onDialogClose: EventEmitter<any> = new EventEmitter();

    invoice = new Invoice;
    isDataChanged: boolean;
    isEndDateEntryValid: boolean;
    isPayableDateEntryValid: boolean;
    isStartDateEntryValid: boolean;
    isSaveReady: boolean = false;
    maxAmount: number = 999999999999.99; // 999 billion instead of 922337203685477.58 = Max value for SQL DB Type Money
    user: User = null;

    constructor(private authService: AuthorizationService,
        private commonFunctions: CommonFunctions,
        private currencyPipe: CurrencyPipe,
        private invoiceService: InvoiceService,
        private messageService: MessageService,
        private userService: UserService) { }

    ngOnInit() {
        this.isPayableDateEntryValid = true;
        this.isStartDateEntryValid = true;
        this.isEndDateEntryValid = true;
        this.isDataChanged = false;
        this.invoice.bondNumber = this.bondData.number.toString();

        this.getUser();
    }

    closeblcModal() {
        this.onDialogClose.emit(this.displayDialog);
        this.isSaveReady = false;
        this.resetForm();
    }

    getUser() {
        this.userService.getUser(this.authService.getUsername()).subscribe((user: User) => {
            if (user) {
                this.user = user;
            }
            else {
                this.messageService.add({ severity: 'error', summary: 'User data not found!', detail: `Couldn\'t find \'${this.authService.getUsername()}\' in the dbo.Users table` });
            }            
        }, (err) => {
            this.messageService.add({ severity: 'error', summary: 'Unexpected Error Occured', detail: `An error occurred getting data for User: ${this.authService.getUsername()}` });                     
        });
    }

    initializeDialogHeader() {
        return this.bondData.isLetterOfCredit ? `Letter of Credit: #${this.bondData.number}` : `Bond: #${this.bondData.number}`;
    }

    onFieldChange() {
        if ((this.invoice.amount || this.invoice.amount == 0) && this.invoice.dateAp
            && this.invoice.datePayPeriodStart && this.invoice.datePayPeriodEnd) {
            this.isSaveReady = true;
        } else {
            this.isSaveReady = false;
        }
    }

    onInput(event) {
        if (event.value > this.maxAmount) {
            this.messageService.add({
                severity: 'error', summary: 'Error',
                detail: 'Invoice Amount is out of range. Please set amount to less than ' + this.currencyPipe.transform(this.maxAmount, 'USD', 'symbol', '1.2-2') + '.'
            });
            this.invoice.amount = 0;
            return;
        }
        this.invoice.amount = event.value;
        this.onFieldChange();
    }

    onInputDateChange(evt: any, inputName: any) {
        this.isDataChanged = true;

        if (inputName === 'payableDate') {
            if (evt == '' || evt == null || evt == undefined) {
                this.isPayableDateEntryValid = true;
                this.invoice.dateAp = null;
                return;
            }

            if (this.commonFunctions.isDateValid(evt)) {
                this.isPayableDateEntryValid = true;
            } else {
                this.isPayableDateEntryValid = false;
            }
        } else if (inputName === 'issueDate') {
            if (evt == '' || evt == null || evt == undefined) {
                this.isStartDateEntryValid = true;
                this.invoice.datePayPeriodStart = null;
                return;
            }

            if (this.commonFunctions.isDateValid(evt)) {
                this.isStartDateEntryValid = true;
            } else {
                this.isStartDateEntryValid = false;
            }
        } else if (inputName === 'expireDate') {
            if (evt == '' || evt == null || evt == undefined) {
                this.isEndDateEntryValid = true;
                this.invoice.datePayPeriodEnd = null;
                return;
            }

            if (this.commonFunctions.isDateValid(evt)) {
                this.isEndDateEntryValid = true;
            } else {
                this.isEndDateEntryValid = false;
            }
        }
        this.onFieldChange();
    }

    onInputDateSelect(evt: any, inputName: any) {
        this.isDataChanged = true;

        if (inputName === 'payableDate') {
            this.isPayableDateEntryValid = true;
            this.invoice.dateAp = this.commonFunctions.formatDateMmddyyyy(evt.toUTCString());
        } else if (inputName === 'issueDate') {
            this.isStartDateEntryValid = true;
            this.invoice.datePayPeriodStart = this.commonFunctions.formatDateMmddyyyy(evt.toUTCString());
        } else if (inputName === 'expireDate') {
            this.isEndDateEntryValid = true;
            this.invoice.datePayPeriodEnd = this.commonFunctions.formatDateMmddyyyy(evt.toUTCString());
        }
        this.onFieldChange();
    }

    onSubmit() {
        this.setDatesToUtc();
        this.invoice.modifierId = this.user['id'];
        this.invoice.bondId = this.bondData.id;
        if (this.validateFields()) {
            this.invoiceService.addInvoice(this.invoice).subscribe(() => {
                this.messageService.add({ severity: 'success', summary: 'Success', detail: 'The invoice has been successfully added' });
                this.closeblcModal();
                this.getBondInvoices.emit();
                this.resetForm();
            }, () => {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'An error occurred adding the invoice' });
            });
        }
    }

    resetForm() {
        this.invoice = new Invoice;
        this.invoice.bondNumber = this.bondData.number.toString();
        this.isPayableDateEntryValid = true;
        this.isStartDateEntryValid = true;
        this.isEndDateEntryValid = true;
        this.isSaveReady = false;
    }

    setDatesToUtc(): void {
        if (this.invoice.dateAp) {
            const utcd = this.commonFunctions.formatDateMmddyyyy(new Date(this.invoice.dateAp).toISOString());
            this.invoice.dateAp = utcd;
        }

        if (this.invoice.datePayPeriodEnd) {
            const utcd = this.commonFunctions.formatDateMmddyyyy(new Date(this.invoice.datePayPeriodEnd).toISOString());
            this.invoice.datePayPeriodEnd = utcd;
        }

        if (this.invoice.datePayPeriodStart) {
            const utcd = this.commonFunctions.formatDateMmddyyyy(new Date(this.invoice.datePayPeriodStart).toISOString());
            this.invoice.datePayPeriodStart = utcd;
        }
    }

    validateFields() {
        const endDate = new Date(this.invoice.datePayPeriodEnd);
        const startDate = new Date(this.invoice.datePayPeriodStart);

        if (!this.invoice.amount && this.invoice.amount != 0) {
            return false;
        }

        if (!this.invoice.dateAp) {
            return false;
        }

        if (!this.invoice.datePayPeriodStart) {
            return false;
        }

        if (!this.invoice.datePayPeriodEnd) {
            return false;
        }

        if (endDate < startDate) {
            this.messageService.add({ severity: 'warn', summary: 'Warn', detail: 'Pay Period Start date must come before Pay Period End date' });
            return false;
        }

        return true;
    }
}
