export class UpdateBondRateParam {
    bankId: number;
    effectiveDate: string | Date;
    oldStandardRate: number;
    newStandardRate: number;
    oldFinancialGuaranteeRate: number;
    newFinancialGuaranteeRate: number;
    modifierId: number;
    dateModified: string | Date;
    constructor() {
        this.modifierId = 0;
        this.dateModified = new Date().toISOString();
    }
}