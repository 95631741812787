import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import * as XLSX from 'exceljs/dist/exceljs.min.js';
import { MessageService } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AmountTypeEnum, BLCTypeEnum } from '../../../models/enums';
import { CommonFunctions } from '../../../functions';
import {
    AmountType, Bank, BankAmountType, BankWithAmountTypes, BankWithFinancialGuarantee, Bond, Broker,
    Company, CostToCompleteByBankReport, CostToCompleteByBankReportParam, Document, ReportColumn
} from '../../../models';
import {
    AmountTypeService, AuthorizationService, BankAmountTypeService, BankService, BondService, BrokerService,
    CompanyService, DocumentService, ExcelService, ReportService
} from '../../../services';


@Component({
    selector: 'app-cost-to-complete-by-surety',
    templateUrl: './cost-to-complete-by-surety.component.html',
    styleUrls: ['./cost-to-complete-by-surety.component.css']
})
export class CostToCompleteBySuretyComponent implements OnInit {
    amountTypes: AmountType[] = [];
    amountTypeList: AmountType[] = [];
    availableCommitment: number = null;
    bankData: BankWithFinancialGuarantee;
    banksForDialog: Bank[] = [];
    bankRecord: BankWithAmountTypes = null;
    bondData: Bond;
    brokerOrSurety: string = '0';
    brokerList: Broker[] = [];
    brokerOrSuretyTypes: any[] = [
        { label: `-- Select By --`, field: `0` },
        { label: `Broker`, field: `1` },
        { label: `Surety`, field: `2` }
    ];
    cols: ReportColumn[];
    companyListSource: Company[] = [];
    companyList: Company[] = [];
    documents: Document[];
    displayBankEditDialog: boolean = false;
    displayEditDialog: boolean = false;
    exportHeader: string[] = [];
    filteredCount: any[] = [];
    isAmountTypeFinancialGuarantee: boolean;
    isStatusCanceled: boolean;
    isSuretySelected: boolean = false;
    isValidParams: boolean = true;
    maxAmount: number = 999999999999.99; // 999 billion instead of 922337203685477.58 = Max value for SQL DB Type Money
    reportExcelList: any[] = [];
    reportGridList: any[] = [];
    reportParameter: CostToCompleteByBankReportParam = new CostToCompleteByBankReportParam();
    reportTitle: string = null;
    results: CostToCompleteByBankReport[] = [];
    selectedBank: BankWithAmountTypes;
    selectedBankAmountTypes: string[] = [];
    singleBondLimit: string = null;
    showResults: boolean = false;
    suretyList: Bank[] = [];
    suretyListTarget: Bank[] = [];

    constructor(private amountTypeService: AmountTypeService,
        private authorizationService: AuthorizationService,
        private bankAmountTypeService: BankAmountTypeService,
        private bankService: BankService,
        private bondService: BondService,
        private brokerService: BrokerService,
        private commonFunctions: CommonFunctions,
        private companyService: CompanyService,
        private currencyPipe: CurrencyPipe,
        private datePipe: DatePipe,
        private documentService: DocumentService,
        private excelService: ExcelService,
        private messageService: MessageService,
        private reportService: ReportService) { }

    ngOnInit(): void {
        this.authorizationService.checkBlcUserAccess(false);
        this.bankRecord = new BankWithAmountTypes();
        this.reportParameter = new CostToCompleteByBankReportParam();

        this.amountTypeService.getAmountTypes()
            .subscribe((result: AmountType[]) => {
                this.amountTypeList = result;
                this.amountTypeList.sort((a, b) => a.name.localeCompare(b.name));
            }, () => {
                this.messageService.add({
                    severity: 'error', summary: 'Unexpected Error Occured',
                    detail: 'An error occurred getting Amount Types.'
                });
            });
        // Retrieve active and inactive sureties 
        this.bankService.getBanks(true, false)
            .subscribe((sureties) => {
                this.suretyList = sureties;
                this.suretyList.sort((a, b) => a.name.localeCompare(b.name));
            }, () => {
                this.messageService.add({
                    severity: 'error', summary: 'Unexpected Error Occured',
                    detail: 'An error occurred getting Banks.'
                });
            });

        this.brokerService.getBrokers()
            .subscribe((result: Broker[]) => {
                this.brokerList = result.sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()));
            }, () => {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Brokers Not Loaded',
                    detail: 'Please be advised, unable to load Brokers.  Nothing to bind Broker drop down list with.'
                });
                this.amountTypes = null;
            });

        //  load amount types !

        this.companyService.getCompanies()
            .subscribe((companies) => {
                this.companyList = companies;
                this.companyList = this.commonFunctions.sortAndFormatCompanies(this.companyList);
                // Cloning this because the companyList gets updated with the remaining available companies once a selection has been made
                this.companyListSource = JSON.parse(JSON.stringify(this.companyList));
            }, error => {
                this.messageService.add({
                    severity: 'error', summary: 'Unexpected Error Occured',
                    detail: 'An error occurred getting Companies.'
                });
            });
        this.isValidParams = this.validateReportParams(this.suretyListTarget, this.reportParameter).length < 1 ? true : false;
    }

    exportExcel(): void {
        // Set export file name
        const exportFileName = 'COSTTOCOMPLETEBYSURETY';
        const workbook = new XLSX.Workbook();
        const worksheet = workbook.addWorksheet('Report Results');

        // Add title row
        const titleRow = worksheet.addRow([this.reportTitle]);
        worksheet.mergeCells('A1:K1');
        worksheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' };

        // Set font, size and style in title row
        titleRow.font = { name: 'Calibri', family: 4, size: 16, underline: 'double', bold: true };

        // Blank Row
        worksheet.addRow([]);

        // Set column widths to fix header row
        worksheet.columns.forEach((column) => {
            column.width = 15;
        });

        // Add Header Row
        const headerRow = worksheet.addRow(this.exportHeader);

        // Wrap and align the header text
        headerRow.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };

        // Set header row height
        headerRow.height = 60;

        // Fill and Border Headers
        headerRow.eachCell((cell) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'D3D3D3' },
                bgColor: { argb: '' }
            };
            cell.border = { top: { style: 'none' }, left: { style: 'none' }, bottom: { style: 'none' }, right: { style: 'none' } };
            cell.font = { weight: 'bold' };
        });

        let isSubHeaderRow = false;

        // Add Data
        this.reportExcelList.forEach(dataRow => {
            const dataStrings: any[] = [];

            // Convert each prop into a string array
            Object.values(dataRow).forEach((val: any, index: number) => {
                switch (index) {
                    case 9: //Estimated Release Date
                        if (val?.toString()) {
                            dataStrings.push(new Date(val));
                        }
                        else {
                            dataStrings.push(val);
                        }
                        break;
                    default: //Everything else
                        dataStrings.push(val);
                }
            });

            // Add the data row
            const row = worksheet.addRow(dataStrings);
            row.eachCell({ includeEmpty: true }, (cell => {
                cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            }));

            // Check if it is a eoy total row
            if (row.getCell(1).value !== null) {
                if (row.getCell(1).value.toString().includes('Total') || row.getCell(1).value.toString().includes('matches')) {
                    isSubHeaderRow = true;
                }
            }
            if (row.getCell(2).value !== null) {
                if (row.getCell(2).value.toString().includes('Total') || row.getCell(1).value.toString().includes('matches')) {
                    isSubHeaderRow = true;
                }
            }

            // Format the cells
            row.eachCell((cell) => {
                // If eoy total row, set to bold
                if (isSubHeaderRow) {
                    cell.font = { bold: true };
                }

                // If negative value, set font to red
                if (typeof cell.value === 'string' && cell.value.toString().includes('<') && cell.value.toString().includes('>')) {
                    cell.font = { color: { argb: 'FF0000' } };
                    cell.value = cell.value.replace('-', '');
                }
            });

            if (isSubHeaderRow) {
                isSubHeaderRow = false;
            }

        });

        this.excelService.exportAsExcelFile(workbook, exportFileName);
    }

    onBankModalEditDialogClose(): void {
        this.displayBankEditDialog = false;
        this.selectedBankAmountTypes = [];
        this.selectedBank = null;
    }

    onBondModalEditDialogClose(): void {
        this.displayEditDialog = false;
    }

    onBrokerChange(event: any): void {
        this.bankRecord.brokerId = Number(event.target.value);
        this.showResults = false;
    }

    onGettingBankToView(bankId: number): void {
        try {
            this.bankService.getBankWithCommitment(bankId).pipe(
                switchMap((aresult: BankWithAmountTypes) => {
                    this.selectedBank = aresult;

                    //  get the bank amount types from selected bank !
                    return this.bankAmountTypeService.getBankAmountTypeByBank(this.selectedBank.id);
                })
                ).subscribe((bresult: BankAmountType[]) => {
                            this.selectedBank.amountTypes = [];
                            //  loop through the bank amoutn types result from selected bank !
                            bresult.forEach(res => {
                                this.selectedBankAmountTypes.push(res.amountTypeId.toString());
                                this.selectedBank.amountTypes.push(res.amountTypeId);
                            });
                            //  display the edit bank dialog !
                            this.displayBankEditDialog = true;
                        }, () => {
                            this.messageService.add({
                                severity: 'error',
                                summary: 'Bank Amount Types Not Loaded',
                                detail: `Please be advised, unable to get Bank Amount Types for ${this.selectedBank.name}.`
                            });
                            this.selectedBankAmountTypes = null;
                            this.displayBankEditDialog = true;
                        });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Unexpected Error Occured',
                detail: 'An error occurred during multiple process after getting the bank or surety record.'
            });
        }
    }
   
    onGettingBondToView(bondId: number): void {
        try {
            if (bondId === null || bondId === undefined) {
                throw new Error(`Invalid BondId`);
            }

            const selectedBond = this.filteredCount.find(bond => bond.bondId == bondId);

            forkJoin(
                this.bondService.getBondById(selectedBond.bondId),
                this.bankService.getBanks(true),
                this.documentService.getDocumentsByBlcId(selectedBond.bondId),
                this.bankService.getAvailableCommitment(selectedBond.bankId, true),
                this.bankService.getAvailableCommitment(selectedBond.bankId, false)
            )
                .subscribe(([bond, banks, documents, fgAvailableCommitment, availableCommitment]) => {
                    if (bond === null || bond === undefined || bond?.id < 1) {
                        throw new Error(`Bond data for ${bondId} not found.`);
                    }

                    this.bondData = bond;
                    this.bondData.bondType = bond.isLetterOfCredit ? BLCTypeEnum.LetterOfCredit : BLCTypeEnum.Bond;
                    this.bondData.dateExpired = this.bondData.currentDateExpired;

                    const getBondStatusResult = this.commonFunctions.getBondStatus(this.bondData);
                    this.bondData = getBondStatusResult.modifiedBond;
                    this.isStatusCanceled = getBondStatusResult.isStatusCanceled;

                    if (this.bondData.amountTypeId === AmountTypeEnum.FinancialGuarantee) {
                        this.isAmountTypeFinancialGuarantee = true;
                        this.bankData = fgAvailableCommitment;
                        this.availableCommitment = this.bankData.financialGuaranteeAvailableCommitment;
                    } else {
                        this.isAmountTypeFinancialGuarantee = false;
                        this.bankData = availableCommitment;
                        this.availableCommitment = this.bankData.availableCommitment;
                    }

                    this.banksForDialog = banks;
                    this.banksForDialog.sort((a, b) => a.name.localeCompare(b.name));
                    const result2 = this.commonFunctions.setSingleBondLimit(this.banksForDialog, this.bondData);
                    this.singleBondLimit = result2.sbl;

                    this.documents = documents as Document[];
                    this.displayEditDialog = true;
                }, () => {
                    this.messageService.add({
                        severity: 'error', summary: 'Unexpected Error Occured',
                        detail: 'An error occurred getting the BLC.'
                    });
                })
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Unexpected Error Occured',
                detail: 'An error occurred during multiple process after getting the BLC record.'
            });
        }
    }

    onInputChange(eventValue: any, inputName: any) {
        this.showResults = false;
        this.reportGridList = [];
        if ((inputName == 'fromAmount' || inputName == 'toAmount') && eventValue > this.maxAmount) {
            this.messageService.add({
                severity: 'error', summary: 'Invalid Value',
                detail: (inputName == 'fromAmount' ? 'From Amount' : 'To Amount') + ' must not exceed ' + this.currencyPipe.transform(this.maxAmount, 'USD', 'symbol', '1.2-2') + '.'
            });
            if (inputName == 'fromAmount') {
                this.reportParameter.fromAmount = 0;
            } else {
                this.reportParameter.toAmount = 0;
            }
        }
    }

    onLinkClickEvent(event: any): void {
        const resultArray = event.data.split('_');
        const type = resultArray[0];
        const id = resultArray[1];

        if (type === 'bankId') {
            this.onGettingBankToView(id);
        } else if (type === 'bondId') {
            this.onGettingBondToView(id);
        } else {
            this.messageService.add({
                severity: 'error',
                summary: 'Unexpected Error Occured',
                detail: 'Invalid link type on selected record'
            });
        }
    }

    onMoveSourceToTarget(): void {
        this.showResults = false;
        this.reportGridList = [];
        this.suretyListTarget.sort((src, tgt) => src.name.localeCompare(tgt.name));
        this.isValidParams = this.validateReportParams(this.suretyListTarget, this.reportParameter).length < 1 ? true : false;
    }

    onMoveTargetToSource(): void {
        this.showResults = false;
        this.reportGridList = [];
        this.suretyList.sort((src, tgt) => src.name.localeCompare(tgt.name));
        this.isValidParams = this.validateReportParams(this.suretyListTarget, this.reportParameter).length < 1 ? true : false;
    }

    onResetSearch(): void {
        this.suretyList.push(...this.suretyListTarget);
        this.suretyList.sort((src, tgt) => src.name.localeCompare(tgt.name));
        this.suretyListTarget = [];

        this.reportGridList = [];
        this.reportExcelList = [];
        this.exportHeader = [];
        this.bankRecord.brokerId = 0;
        this.showResults = false;

        this.reportParameter = new CostToCompleteByBankReportParam();
        this.isValidParams = this.validateReportParams(this.suretyListTarget, this.reportParameter).length < 1 ? true : false;
    }

    onSearch(): void {
        this.reportGridList = [];
        this.reportExcelList = [];
        this.exportHeader = [];

        if (this.isSuretySelected) {
            try {
                const validatedParams = this.validateReportParams(this.suretyListTarget, this.reportParameter);
                this.isValidParams = validatedParams.length < 1 ? true : false;

                for (const validatedItem of validatedParams) {
                    if (validatedItem.isValid === false) {
                        this.messageService.add({
                            severity: 'error',
                            summary: 'Entry Validation Error Occured',
                            detail: validatedItem.message
                        });
                        return;
                    }
                }

                // Set search params and report results title
                this.reportTitle = `Cost to Complete by Surety`;

                // Set result table columns
                this.cols = [
                    { field: 'company', header: 'Company' },
                    { field: 'community', header: 'Community' },
                    { field: 'bank', header: 'Surety', link: 'bankId' },
                    { field: 'bondAmount', header: 'Current Amount', dataType: 'currency' },
                    { field: 'beneficiary', header: 'Beneficiary' },
                    { field: 'bondType', header: 'Type' },
                    { field: 'bondNumber', header: 'Bond/LC#', link: 'bondId' },
                    { field: 'costToComplete', header: 'Cost To Complete (%)', dataType: 'decimal' },
                    { field: 'costToCompleteAmt', header: 'Cost To Complete ($)', dataType: 'currency' },
                    { field: 'estimatedReleaseDate', header: 'Estimated Release Date', dataType: `date` },
                    { field: 'comments', header: 'Comments', widthPercentage: 10 }
                ];

                // Set header row values for export
                for (const col of this.cols) {
                    if (col.header) {
                        this.exportHeader.push(col.header);
                    }
                }

                const observableList = [];
                this.reportParameter.bankIds = [];

                for (const oitem of this.suretyListTarget) {
                    if ((oitem.id) || oitem.id != null) {
                        this.reportParameter.bankIds.push(oitem.id);
                    }
                }

                observableList.push(this.reportService.getCostToCompleteByBank(this.reportParameter));
                forkJoin(observableList)
                    .subscribe((res: any[]) => {
                        var ctcCurrentAmmountTotal = 0;
                        var ctcTotalPct = 0;
                        var ctcTotalAmt = 0;
                        const decimalFormat = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
                        const groupedBy = this.commonFunctions.groupBy(res[0], `bank`);

                        if (Object.keys(groupedBy).length > 0) {
                            for (const rpt in groupedBy) {
                                //  get total ctc matches!
                                const report = groupedBy[rpt];
                                const ctcMatches = report?.length;

                                this.reportGridList.push({
                                    company: `${ctcMatches} matches`,
                                    community: `Cost to Complete Report For ${report[0]?.bank}`,
                                    bank: null,
                                    bondAmount: null,
                                    beneficiary: null,
                                    bondType: null,
                                    bondNumber: null,
                                    costToComplete: null,
                                    costToCompleteAmt: null,
                                    estimatedReleaseDate: null,
                                    comments: null,
                                    groupKey: 10000
                                });

                                this.reportExcelList.push({
                                    company: `${ctcMatches} matches`,
                                    community: `Cost to Complete Report For ${report[0]?.bank}`,
                                    bank: null,
                                    bondAmount: null,
                                    beneficiary: null,
                                    bondType: null,
                                    bondNumber: null,
                                    costToComplete: null,
                                    costToCompleteAmt: null,
                                    estimatedReleaseDate: null,
                                    comments: null,
                                });

                                const arrbnk = report.map(b => b.bank);
                                const bnk = new Set(arrbnk);
                                const uniqueBanks = Array.from(bnk);

                                var filtered: CostToCompleteByBankReport[] = [];
                                for (const theBank of uniqueBanks) {
                                    var currentAmountTotal: number = 0;
                                    var ctcPct: number = 0;
                                    var ctcAmt: number = 0;

                                    filtered = report.filter(bnk => bnk.bank === theBank);
                                    for (const ctcRecord of filtered) {
                                        this.commonFunctions.trimTimeFromDatePropertiesInObject(ctcRecord);

                                        //  resolve filteredCount here for the report result components filteredCount input
                                        this.filteredCount.push(ctcRecord);

                                        currentAmountTotal += ctcRecord.bondAmount;
                                        ctcPct += ctcRecord.costToComplete;
                                        ctcAmt += ctcRecord.costToCompleteAmt;
                                        ctcCurrentAmmountTotal += ctcRecord.bondAmount;
                                        ctcTotalPct += ctcRecord.costToComplete;
                                        ctcTotalAmt += ctcRecord.costToCompleteAmt;

                                        this.reportGridList.push({
                                            bankId: ctcRecord.bankId,
                                            bondId: ctcRecord.bondId,
                                            company: ctcRecord.company,
                                            community: ctcRecord.community,
                                            bank: ctcRecord.bank,
                                            bondAmount: (ctcRecord.bondAmount) ? parseFloat(ctcRecord.bondAmount.toFixed(2)) : 0.00,
                                            beneficiary: ctcRecord.beneficiary,
                                            bondType: ctcRecord.bondType,
                                            bondNumber: ctcRecord.bondNumber,
                                            costToComplete: (ctcRecord.costToComplete) ? parseFloat(ctcRecord.costToComplete.toFixed(2)) : 0.00,
                                            costToCompleteAmt: (ctcRecord.costToCompleteAmt) ? parseFloat(ctcRecord.costToCompleteAmt.toFixed(2)) : 0.00,
                                            estimatedReleaseDate: ctcRecord.estimatedReleaseDate,
                                            comments: ctcRecord.comments,
                                        });

                                        const formAmount = (ctcRecord.costToCompleteAmt) ? parseFloat(ctcRecord.costToCompleteAmt.toFixed(2)).toLocaleString('en', decimalFormat) : `0.00`;
                                        const formBondAmount = (ctcRecord.bondAmount) ? parseFloat(ctcRecord.bondAmount.toFixed(2)).toLocaleString('en', decimalFormat) : `0.00`;
                                        const formCTC = (ctcRecord.costToComplete) ? parseFloat(ctcRecord.costToComplete.toFixed(2)).toLocaleString('en', decimalFormat) : `0.00`;

                                        this.reportExcelList.push({
                                            company: ctcRecord.company,
                                            community: ctcRecord.community,
                                            bank: ctcRecord.bank,
                                            bondAmount: ctcRecord.bondAmount < 0 ? `<$${formBondAmount}>` : `$${formBondAmount}`,
                                            beneficiary: ctcRecord.beneficiary,
                                            bondType: ctcRecord.bondType,
                                            bondNumber: ctcRecord.bondNumber,
                                            costToComplete: formCTC,
                                            costToCompleteAmt: +ctcRecord.costToCompleteAmt < 0 ? `<$${formAmount}>` : `$${formAmount}`,
                                            estimatedReleaseDate: ctcRecord.estimatedReleaseDate,
                                            comments: ctcRecord.comments,
                                        });
                                    }

                                    if (filtered.length != 0) {
                                        const formCurrentAmountTotal = (currentAmountTotal) ? parseFloat(currentAmountTotal.toFixed(2)).toLocaleString('en', decimalFormat) : `0.00`;
                                        const ctcAmtTotal = (ctcAmt) ? parseFloat(ctcAmt.toFixed(2)).toLocaleString('en', decimalFormat) : `0.00`;
                                        const ctcPercent = (Number(ctcAmt) / Number(currentAmountTotal)).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 });

                                        this.reportGridList.push({
                                            company: null,
                                            community: `Cost To Complete Total For ${theBank}`,
                                            bank: null,
                                            bondAmount: currentAmountTotal,
                                            beneficiary: null,
                                            bondType: null,
                                            bondNumber: null,
                                            costToComplete: ctcPercent,
                                            costToCompleteAmt: ctcAmt,
                                            estimatedReleaseDate: null,
                                            comments: null,
                                            groupKey: 11000
                                        });

                                        this.reportExcelList.push({
                                            company: null,
                                            community: `Cost To Complete Total For ${theBank}`,
                                            bank: null,
                                            bondAmount: +currentAmountTotal < 0 ? `<$${formCurrentAmountTotal}>` : `$${formCurrentAmountTotal}`,
                                            beneficiary: null,
                                            bondType: null,
                                            bondNumber: null,
                                            costToComplete: +ctcPct < 0 ? `<${ctcPercent}>` : `${ctcPercent}`,
                                            costToCompleteAmt: +ctcAmt < 0 ? `<$${ctcAmtTotal}>` : `$${ctcAmtTotal}`,
                                            estimatedReleaseDate: null,
                                            comments: null,
                                        });
                                    } else {
                                        this.reportGridList.push({
                                            company: `No matches found.`,
                                            community: null,
                                            bank: null,
                                            bondAmount: null,
                                            beneficiary: null,
                                            bondType: null,
                                            bondNumber: null,
                                            costToComplete: null,
                                            costToCompleteAmt: null,
                                            estimatedReleaseDate: null,
                                            comments: null,
                                            groupKey: 12000
                                        });

                                        this.reportExcelList.push({
                                            company: `No matches found.`,
                                            community: null,
                                            bank: null,
                                            bondAmount: null,
                                            beneficiary: null,
                                            bondType: null,
                                            bondNumber: null,
                                            costToComplete: null,
                                            costToCompleteAmt: null,
                                            estimatedReleaseDate: null,
                                            comments: null,
                                        });
                                    }
                                }
                            }
                        }
                        else {
                            for (const com of this.suretyListTarget) {
                                this.reportGridList.push({
                                    company: `0 matches`,
                                    community: `Cost to Complete Report For ${com?.name}`,
                                    bank: null,
                                    bondAmount: null,
                                    beneficiary: null,
                                    bondType: null,
                                    bondNumber: null,
                                    costToComplete: null,
                                    costToCompleteAmt: null,
                                    estimatedReleaseDate: null,
                                    comments: null,
                                    groupKey: 10000
                                });

                                this.reportExcelList.push({
                                    company: `0 matches`,
                                    community: `Cost to Complete Report For ${com?.name}`,
                                    bank: null,
                                    bondAmount: null,
                                    beneficiary: null,
                                    bondType: null,
                                    bondNumber: null,
                                    costToComplete: null,
                                    costToCompleteAmt: null,
                                    estimatedReleaseDate: null,
                                    comments: null,
                                });

                                this.reportGridList.push({
                                    company: `No matches found.`,
                                    community: null,
                                    bank: null,
                                    bondAmount: null,
                                    beneficiary: null,
                                    bondType: null,
                                    bondNumber: null,
                                    costToComplete: null,
                                    costToCompleteAmt: null,
                                    estimatedReleaseDate: null,
                                    comments: null,
                                    groupKey: 12000
                                });

                                this.reportExcelList.push({
                                    company: `No matches found.`,
                                    community: null,
                                    bank: null,
                                    bondAmount: null,
                                    beneficiary: null,
                                    bondType: null,
                                    bondNumber: null,
                                    costToComplete: null,
                                    costToCompleteAmt: null,
                                    estimatedReleaseDate: null,
                                    comments: null,
                                });
                            }
                            this.showResults = true;
                        }

                        const formCtcCurrentTotal = (ctcCurrentAmmountTotal) ? parseFloat(ctcCurrentAmmountTotal.toFixed(2)).toLocaleString('en', decimalFormat) : `0.00`;
                        const formCtcAmtTotal = (ctcTotalAmt) ? parseFloat(ctcTotalAmt.toFixed(2)).toLocaleString('en', decimalFormat) : `0.00`;
                        const ctcTotalPercent = (Number(ctcTotalAmt) / Number(ctcCurrentAmmountTotal)).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 });

                        this.reportGridList.push({
                            company: `Cost To Complete Total`,
                            community: null,
                            bank: null,
                            bondAmount: ctcCurrentAmmountTotal,
                            beneficiary: null,
                            bondType: null,
                            bondNumber: null,
                            costToComplete: ctcTotalPercent,
                            costToCompleteAmt: ctcTotalAmt,
                            estimatedReleaseDate: null,
                            comments: null,
                            groupKey: 13000
                        });

                        this.reportExcelList.push({
                            company: `Cost To Complete Total`,
                            community: null,
                            bank: null,
                            bondAmount: +ctcCurrentAmmountTotal < 0 ? `<$${formCtcCurrentTotal}>` : `$${formCtcCurrentTotal}`,
                            beneficiary: null,
                            bondType: null,
                            bondNumber: null,
                            costToComplete: +ctcTotalPct < 0 ? `<${ctcTotalPercent}>` : `${ctcTotalPercent}`,
                            costToCompleteAmt: +ctcTotalAmt < 0 ? `<$${formCtcAmtTotal}>` : `$${formCtcAmtTotal}`,
                            estimatedReleaseDate: null,
                            comments: null,
                        });

                        // end of data load for report and excel !
                        this.showResults = true;
                    });
            } catch (error) {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Report Layout Error Occured',
                    detail: `There were error(s) occured during report layout process.`
                });
            }
            finally {
                this.filteredCount = [];
            }
        }
        else {
            if (this.bankRecord.brokerId != 0) {
                this.bankService.getBanks(true)
                    .subscribe((banks: Bank[]) => {
                        banks.forEach((bank) => {
                            this.commonFunctions.trimTimeFromDatePropertiesInObject(bank);
                        });
                        this.banksForDialog = banks;
                        this.banksForDialog.sort((a, b) => a.name.localeCompare(b.name));
                        const result = this.banksForDialog.filter(o => o.brokerId === this.bankRecord.brokerId);
                        try {
                            this.reportGridList = [];
                            // Set search params and report results title
                            this.reportTitle = `Cost to Complete by Surety`;

                            // Set result table columns
                            this.cols = [
                                { field: 'company', header: 'Company' },
                                { field: 'community', header: 'Community' },
                                { field: 'bank', header: 'Surety', link: 'bankId' },
                                { field: 'bondAmount', header: 'Current Amount', dataType: 'currency' },
                                { field: 'beneficiary', header: 'Beneficiary' },
                                { field: 'bondType', header: 'Type' },
                                { field: 'bondNumber', header: 'Bond/LC#', link: 'bondId' },
                                { field: 'costToComplete', header: 'Cost To Complete (%)', dataType: 'decimal' },
                                { field: 'costToCompleteAmt', header: 'Cost To Complete ($)', dataType: 'currency' },
                                { field: 'estimatedReleaseDate', header: 'Estimated Release Date', dataType: `date` },
                                { field: 'comments', header: 'Comments' }
                            ];
                            // Set header row values for export
                            for (const col of this.cols) {
                                if (col.header) {
                                    this.exportHeader.push(col.header);
                                }
                            }

                            const observableList = [];
                            this.reportParameter.bankIds = [];
                            for (const oitem of result) {
                                if ((oitem.id) || oitem.id != null) {
                                    this.reportParameter.bankIds.push(oitem.id);
                                }
                            }

                            observableList.push(this.reportService.getCostToCompleteByBank(this.reportParameter));
                            forkJoin(observableList)
                                .subscribe((res: any[]) => {
                                    // Format the date objects to remove timestamps
                                    res.forEach((result) => {
                                        this.commonFunctions.trimTimeFromDatePropertiesInObject(result);
                                    });
                                    var ctcCurrentAmmountTotal = 0;
                                    var ctcTotalPct = 0;
                                    var ctcTotalAmt = 0;
                                    const decimalFormat = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
                                    const groupedBy = this.commonFunctions.groupBy(res[0], `bank`);
                                    if (Object.keys(groupedBy).length > 0) {
                                        for (const rpt in groupedBy) {
                                            //  get total ctc matches!
                                            const report = groupedBy[rpt];
                                            const ctcMatches = report?.length;
                                            this.reportGridList.push({
                                                company: `${ctcMatches} matches`,
                                                community: `Cost to Complete Report For ${report[0]?.bank}`,
                                                bank: null,
                                                bondAmount: null,
                                                beneficiary: null,
                                                bondType: null,
                                                bondNumber: null,
                                                costToComplete: null,
                                                costToCompleteAmt: null,
                                                estimatedReleaseDate: null,
                                                comments: null,
                                                groupKey: 10000
                                            });

                                            this.reportExcelList.push({
                                                company: `${ctcMatches} matches`,
                                                community: `Cost to Complete Report For ${report[0]?.bank}`,
                                                bank: null,
                                                bondAmount: null,
                                                beneficiary: null,
                                                bondType: null,
                                                bondNumber: null,
                                                costToComplete: null,
                                                costToCompleteAmt: null,
                                                estimatedReleaseDate: null,
                                                comments: null,
                                            });

                                            const arrbnk = report.map(b => b.bank);
                                            const bnk = new Set(arrbnk);
                                            const uniqueBanks = Array.from(bnk);

                                            var filtered: CostToCompleteByBankReport[] = [];
                                            for (const theBank of uniqueBanks) {
                                                var currentAmountTotal: number = 0;
                                                var ctcPct: number = 0;
                                                var ctcAmt: number = 0;
                                                filtered = report.filter(bnk => bnk.bank === theBank);
                                                for (const ctcRecord of filtered) {
                                                    //  resolve filteredCount here for the report result components filteredCount input
                                                    this.filteredCount.push(ctcRecord);
                                                    currentAmountTotal += ctcRecord.bondAmount;
                                                    ctcPct += ctcRecord.costToComplete;
                                                    ctcAmt += ctcRecord.costToCompleteAmt;
                                                    ctcCurrentAmmountTotal += ctcRecord.bondAmount;
                                                    ctcTotalPct += ctcRecord.costToComplete;
                                                    ctcTotalAmt += ctcRecord.costToCompleteAmt;
                                                    this.reportGridList.push({
                                                        bankId: ctcRecord.bankId,
                                                        bondId: ctcRecord.bondId,
                                                        company: ctcRecord.company,
                                                        community: ctcRecord.community,
                                                        bank: ctcRecord.bank,
                                                        bondAmount: (ctcRecord.bondAmount) ? parseFloat(ctcRecord.bondAmount.toFixed(2)) : 0.00,
                                                        beneficiary: ctcRecord.beneficiary,
                                                        bondType: ctcRecord.bondType,
                                                        bondNumber: ctcRecord.bondNumber,
                                                        costToComplete: (ctcRecord.costToComplete) ? parseFloat(ctcRecord.costToComplete.toFixed(2)) : 0.00,
                                                        costToCompleteAmt: (ctcRecord.costToCompleteAmt) ? parseFloat(ctcRecord.costToCompleteAmt.toFixed(2)) : 0.00,
                                                        estimatedReleaseDate: ctcRecord.estimatedReleaseDate,
                                                        comments: ctcRecord.comments,
                                                    });
                                                    const formAmount = (ctcRecord.costToCompleteAmt) ? parseFloat(ctcRecord.costToCompleteAmt.toFixed(2)).toLocaleString('en', decimalFormat) : `0.00`;
                                                    const formBondAmount = (ctcRecord.bondAmount) ? parseFloat(ctcRecord.bondAmount.toFixed(2)).toLocaleString('en', decimalFormat) : `0.00`;
                                                    const formCTC = (ctcRecord.costToComplete) ? parseFloat(ctcRecord.costToComplete.toFixed(2)).toLocaleString('en', decimalFormat) : `0.00`;
                                                    this.reportExcelList.push({
                                                        company: ctcRecord.company,
                                                        community: ctcRecord.community,
                                                        bank: ctcRecord.bank,
                                                        bondAmount: ctcRecord.bondAmount < 0 ? `<$${formBondAmount}>` : `$${formBondAmount}`,
                                                        beneficiary: ctcRecord.beneficiary,
                                                        bondType: ctcRecord.bondType,
                                                        bondNumber: ctcRecord.bondNumber,
                                                        costToComplete: formCTC,
                                                        costToCompleteAmt: +ctcRecord.costToCompleteAmt < 0 ? `<$${formAmount}>` : `$${formAmount}`,
                                                        estimatedReleaseDate: (ctcRecord.estimatedReleaseDate) ? this.datePipe.transform(ctcRecord.estimatedReleaseDate, `MM/dd/yyyy`) : null,
                                                        comments: ctcRecord.comments,
                                                    });
                                                }
                                                if (filtered.length != 0) {
                                                    const formCurrentAmountTotal = (currentAmountTotal) ? parseFloat(currentAmountTotal.toFixed(2)).toLocaleString('en', decimalFormat) : `0.00`;
                                                    const ctcAmtTotal = (ctcAmt) ? parseFloat(ctcAmt.toFixed(2)).toLocaleString('en', decimalFormat) : `0.00`;
                                                    const ctcPercent = (Number(ctcAmt) / Number(currentAmountTotal)).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 });
                                                    this.reportGridList.push({
                                                        company: null,
                                                        community: `Cost To Complete Total For ${theBank}`,
                                                        bank: null,
                                                        bondAmount: currentAmountTotal,
                                                        beneficiary: null,
                                                        bondType: null,
                                                        bondNumber: null,
                                                        costToComplete: ctcPercent,
                                                        costToCompleteAmt: ctcAmt,
                                                        estimatedReleaseDate: null,
                                                        comments: null,
                                                        groupKey: 11000
                                                    });

                                                    this.reportExcelList.push({
                                                        company: null,
                                                        community: `Cost To Complete Total For ${theBank}`,
                                                        bank: null,
                                                        bondAmount: +currentAmountTotal < 0 ? `<$${formCurrentAmountTotal}>` : `$${formCurrentAmountTotal}`,
                                                        beneficiary: null,
                                                        bondType: null,
                                                        bondNumber: null,
                                                        costToComplete: +ctcPct < 0 ? `<${ctcPercent}>` : `${ctcPercent}`,
                                                        costToCompleteAmt: +ctcAmt < 0 ? `<$${ctcAmtTotal}>` : `$${ctcAmtTotal}`,
                                                        estimatedReleaseDate: null,
                                                        comments: null,
                                                    });

                                                } else {
                                                    this.reportGridList.push({
                                                        company: `No matches found.`,
                                                        community: null,
                                                        bank: null,
                                                        bondAmount: null,
                                                        beneficiary: null,
                                                        bondType: null,
                                                        bondNumber: null,
                                                        costToComplete: null,
                                                        costToCompleteAmt: null,
                                                        estimatedReleaseDate: null,
                                                        comments: null,
                                                        groupKey: 12000
                                                    });
                                                    this.reportExcelList.push({
                                                        company: `No matches found.`,
                                                        community: null,
                                                        bank: null,
                                                        bondAmount: null,
                                                        beneficiary: null,
                                                        bondType: null,
                                                        bondNumber: null,
                                                        costToComplete: null,
                                                        costToCompleteAmt: null,
                                                        estimatedReleaseDate: null,
                                                        comments: null,
                                                    });
                                                }
                                            }
                                        }
                                        const formCtcCurrentTotal = (ctcCurrentAmmountTotal) ? parseFloat(ctcCurrentAmmountTotal.toFixed(2)).toLocaleString('en', decimalFormat) : `0.00`;
                                        const formCtcAmtTotal = (ctcTotalAmt) ? parseFloat(ctcTotalAmt.toFixed(2)).toLocaleString('en', decimalFormat) : `0.00`;
                                        const ctcTotalPercent = (Number(ctcTotalAmt) / Number(ctcCurrentAmmountTotal)).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 });
                                        this.reportGridList.push({
                                            company: `Cost To Complete Total`,
                                            community: null,
                                            bank: null,
                                            bondAmount: ctcCurrentAmmountTotal,
                                            beneficiary: null,
                                            bondType: null,
                                            bondNumber: null,
                                            costToComplete: ctcTotalPercent,
                                            costToCompleteAmt: ctcTotalAmt,
                                            estimatedReleaseDate: null,
                                            comments: null,
                                            groupKey: 13000
                                        });
                                        this.reportExcelList.push({
                                            company: `Cost To Complete Total`,
                                            community: null,
                                            bank: null,
                                            bondAmount: +ctcCurrentAmmountTotal < 0 ? `<$${formCtcCurrentTotal}>` : `$${formCtcCurrentTotal}`,
                                            beneficiary: null,
                                            bondType: null,
                                            bondNumber: null,
                                            costToComplete: +ctcTotalPct < 0 ? `<${ctcTotalPercent}>` : `${ctcTotalPercent}`,
                                            costToCompleteAmt: +ctcTotalAmt < 0 ? `<$${formCtcAmtTotal}>` : `$${formCtcAmtTotal}`,
                                            estimatedReleaseDate: null,
                                            comments: null,
                                        });
                                        // end of data load for report and excel !
                                        this.showResults = true;
                                    }

                                    else {
                                        for (const ba of result) {
                                            this.reportGridList.push({
                                                company: `0 matches`,
                                                community: `Cost to Complete Report For ${ba?.name}`,
                                                bank: null,
                                                bondAmount: null,
                                                beneficiary: null,
                                                bondType: null,
                                                bondNumber: null,
                                                costToComplete: null,
                                                costToCompleteAmt: null,
                                                estimatedReleaseDate: null,
                                                comments: null,
                                                groupKey: 10000
                                            });

                                            this.reportExcelList.push({
                                                company: `0 matches`,
                                                community: `Cost to Complete Report For ${ba?.name}`,
                                                bank: null,
                                                bondAmount: null,
                                                beneficiary: null,
                                                bondType: null,
                                                bondNumber: null,
                                                costToComplete: null,
                                                costToCompleteAmt: null,
                                                estimatedReleaseDate: null,
                                                comments: null,
                                            });

                                            this.reportGridList.push({
                                                company: `No matches found.`,
                                                community: null,
                                                bank: null,
                                                bondAmount: null,
                                                beneficiary: null,
                                                bondType: null,
                                                bondNumber: null,
                                                costToComplete: null,
                                                costToCompleteAmt: null,
                                                estimatedReleaseDate: null,
                                                comments: null,
                                                groupKey: 12000
                                            });
                                            this.reportExcelList.push({
                                                company: `No matches found.`,
                                                community: null,
                                                bank: null,
                                                bondAmount: null,
                                                beneficiary: null,
                                                bondType: null,
                                                bondNumber: null,
                                                costToComplete: null,
                                                costToCompleteAmt: null,
                                                estimatedReleaseDate: null,
                                                comments: null,
                                            });
                                        }
                                        this.showResults = true;
                                    }
                                });
                        } catch (error) {
                            this.messageService.add({
                                severity: 'error',
                                summary: 'Report Layout Error Occured',
                                detail: `There were error(s) occured during report layout process.`
                            });
                        }
                        finally {
                            this.filteredCount = [];
                        }
                    });

            }
            else {
                this.reportParameter.bankIds = [];
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: `Please select a broker.`
                });
            }

        }
    }

    onTypeChange(event: any): void {
        this.brokerOrSurety = event.target.value;
        if (this.brokerOrSurety === '1') {
            this.isSuretySelected = false;
        } else if (this.brokerOrSurety === '2') {
            this.isSuretySelected = true;
        }
    }

    validateReportParams(baList: Bank[], param: CostToCompleteByBankReportParam): any[] {
        var retval: any[] = [];
        if (baList.length < 1) {
            retval.push({
                isValid: false,
                message: `No surety found in the Selected surety list box. Select a surety.`
            });
        }

        return retval;
    }
}
