import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import * as XLSX from 'exceljs/dist/exceljs.min.js';
import { MessageService } from 'primeng/api';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';

import { AmountTypeEnum, BLCTypeEnum } from '../../../models/enums';
import { CommonFunctions } from '../../../functions';
import { AmountType, Bank, BankWithAmountTypes, BankWithFinancialGuarantee, Bond, Company, Document,
         InvoiceByCompanyExcel, InvoiceByCompanyReport, InvoiceByCompanyReportParam } from '../../../models';
import { AmountTypeService, AuthorizationService, BankAmountTypeService, BankService, BondService,
         CompanyService, DocumentService, ExcelService, ReportService } from '../../../services';

@Component({
    selector: 'app-invoice-by-company',
    templateUrl: './invoice-by-company.component.html',
    styleUrls: ['./invoice-by-company.component.css'],
    providers: [MessageService]
})
export class InvoiceByCompanyComponent implements OnInit {
    amountTypeList: AmountType[] = [];
    availableCommitment: number = null;
    bankData: BankWithFinancialGuarantee;
    banksForDialog: Bank[] = [];
    bondData: Bond;
    cols: any[];
    companyList: Company[] = [];
    companyListSource: Company[] = [];
    companyListTarget: Company[] = [];
    displayBankEditDialog: boolean = false;
    displayEditDialog: boolean = false;
    documents: Document[];
    exportHeader: string[] = [];
    filteredCount: InvoiceByCompanyReport[] = [];
    isAmountTypeFinancialGuarantee: boolean;
    isLoading: boolean = false;
    isStatusCanceled: boolean;
    isValidParams: boolean = true;
    reportGridList: InvoiceByCompanyReport[] = [];
    reportExcelList: any[] = [];
    reportParameter: InvoiceByCompanyReportParam = new InvoiceByCompanyReportParam();
    reportSubTitle: string = null;
    reportTitle: string = null;
    selectedBank: BankWithAmountTypes = null;
    selectedBankAmountTypes: string[] = [];
    showResults: boolean = false;
    singleBondLimit: string = null;
    sub: any = null;

    constructor(private amountTypeService: AmountTypeService,
        private authorizationService: AuthorizationService,
        private bankAmountTypeService: BankAmountTypeService,
        private bankService: BankService,
        private bondService: BondService,
        private commonFunctions: CommonFunctions,
        private companyService: CompanyService,
        private datePipe: DatePipe,
        private documentService: DocumentService,
        private excelService: ExcelService,
        private messageService: MessageService,
        private reportService: ReportService) { }

    ngOnInit(): void {
        this.authorizationService.checkBlcUserAccess();
        this.reportParameter = new InvoiceByCompanyReportParam();

        this.amountTypeService.getAmountTypes()
            .subscribe((result: AmountType[]) => {
                this.amountTypeList = result;
                this.amountTypeList.sort((a, b) => a.name.localeCompare(b.name));
            }, () => {
                this.messageService.add({
                    severity: 'error', summary: 'Unexpected Error Occured',
                    detail: 'An error occurred getting Amount Types.'
                });
            });

        this.companyService.getCompanies()
            .subscribe((companies: Company[]) => {
                this.companyList = companies;
                this.companyList = this.commonFunctions.sortAndFormatCompanies(this.companyList);
                // Cloning this because the companyList gets updated with the remaining available companies once a selection has been made
                this.companyListSource = JSON.parse(JSON.stringify(this.companyList));
            });
        this.isValidParams = this.validateReportParams(this.companyListTarget, this.reportParameter).length < 1 ? true : false;
    }

    dateRangeValidator(fDate: String | Date, tDate: string | Date): any {
        if (fDate !== null && tDate !== null && fDate > tDate) {
            return { valid: false, msg: `Please specify a From and To date range with 'From Accounts Payable Date' less than the 'To Accounts Payable Date'` };
        }
        return { valid: true, msg: `Date range are valid.` };
    };

    exportExcel(): void {
        // Set export file name
        const exportFileName = 'INVOICEBYCOMPANY';
        const workbook = new XLSX.Workbook();
        const worksheet = workbook.addWorksheet('Report Results');

        // Add title row
        const titleRow = worksheet.addRow([this.reportTitle]);
        const subTitleRow = worksheet.addRow([this.reportSubTitle]);
        worksheet.mergeCells('A1:K1');
        worksheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.mergeCells('A2:K2');
        worksheet.getCell('A2').alignment = { vertical: 'middle', horizontal: 'center' };

        // Set font, size and style in title row
        titleRow.font = { name: 'Calibri', family: 4, size: 16, underline: 'double', bold: true };
        subTitleRow.font = { name: 'Calibri', family: 4, size: 14 };

        // Set column widths to fix header row
        worksheet.columns.forEach((column) => {
            column.width = 15;
        });

        // Add Header Row
        const headerRow = worksheet.addRow(this.exportHeader);

        // Wrap and align the header text
        headerRow.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };

        // Set header row height
        headerRow.height = 60;

        // Fill and Border Headers
        headerRow.eachCell((cell) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'D3D3D3' },
                bgColor: { argb: '' }
            };
            cell.border = { top: { style: 'none' }, left: { style: 'none' }, bottom: { style: 'none' }, right: { style: 'none' } };
            cell.font = { weight: 'bold' };
        });

        let isTotalRow = false;

        // Add Data
        this.reportExcelList.forEach(dataRow => {
            const dataStrings: any[] = [];

            // Convert each prop into a string array
            Object.values(dataRow).forEach((val: any, index: number) => {
                switch (index) {
                    case 6: //AP Date
                    case 7: //Invoice Start Date
                    case 8: //Invoice End Date
                        if (val?.toString()) {
                            dataStrings.push(new Date(val));
                        }
                        else {
                            dataStrings.push(val);
                        }
                        break;
                    default: //Everything else
                        dataStrings.push(val);
                }                
            });

            // Add the data row
            const row = worksheet.addRow(dataStrings);
            row.eachCell({ includeEmpty: true }, (cell => {
                cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            }));

            // Check if it is a eoy total row
            if (row.getCell(1).value !== null) {
                if (row.getCell(1).value.toString().includes('Total') || row.getCell(1).value.toString().includes('Summary')) {
                    isTotalRow = true;
                }
            }
            if (row.getCell(2).value !== null) {
                if (row.getCell(2).value.toString().includes('Total') || row.getCell(2).value.toString().includes('Summary')) {
                    isTotalRow = true;
                }
            }

            // Format the cells
            row.eachCell((cell) => {
                // If eoy total row, set to bold
                if (isTotalRow) {
                    cell.font = { bold: true };
                }                

                // If negative value, set font to red
                if (typeof cell.value === 'string' && cell.value.toString().includes('(') && cell.value.toString().includes(')')) {
                    cell.font = { color: { argb: 'FF0000' } };
                    cell.value = cell.value.toString().replace('-', '');
                }
            });

            if (isTotalRow) {
                isTotalRow = false;
            }

        });

        this.excelService.exportAsExcelFile(workbook, exportFileName);
    }

    onBankModalEditDialogClose(): void {
        this.displayBankEditDialog = false;
        this.selectedBankAmountTypes = [];
        this.selectedBank = null;
    }

    onBondModalEditDialogClose(): void {
        this.displayEditDialog = false;
    }

    onFromDateApChange(event: any): void {
        if (event.target?.value === 'mm/dd/yyyy') {
            this.reportParameter.fromDateAp == null;
        }
        this.isValidParams = this.validateReportParams(this.companyListTarget, this.reportParameter).length < 1 ? true : false;
    }

    onGettingBankToView(bankId: number): void {
        try {
            const dataFetchList = [];
            const paramBankId = bankId;
            dataFetchList.push(this.bankService.getBankWithCommitment(paramBankId));
            dataFetchList.push(this.bankAmountTypeService.getBankAmountTypeByBank(paramBankId));

            forkJoin(dataFetchList)
                .subscribe((fetchedData: any[]) => {
                    this.selectedBank = fetchedData[0];
                    this.selectedBank.amountTypes = [];

                    const batResults = fetchedData[1];
                    for (const bat of batResults) {
                        this.selectedBankAmountTypes.push(`${bat.amountTypeId}`);
                        this.selectedBank.amountTypes.push(bat.amountTypeId);
                    }

                    this.displayBankEditDialog = true;
                }, () => {
                    if (this.selectedBankAmountTypes == null) {
                        this.messageService.add({
                            severity: 'error',
                            summary: 'Bank Amount Types Not Loaded',
                            detail: `Please be advised, unable to get Bank Amount Types for ${this.selectedBank.name}.`
                        });
                        this.selectedBankAmountTypes = null;
                        this.displayBankEditDialog = true;
                    }
                });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Unexpected Error Occured',
                detail: 'An error occurred during multiple process after getting the bank or surety record.'
            });
        }
    }

    onGettingBondToView(bondId: number): void {
        try {
            if (bondId === null || bondId === undefined) {
                throw new Error(`Invalid BondId`);
            }

            const selectedBond = this.filteredCount.find(bond => bond.bondId == bondId);

            forkJoin(
                this.bondService.getBondById(selectedBond.bondId),
                this.bankService.getBanks(true),
                this.documentService.getDocumentsByBlcId(selectedBond.bondId),
                this.bankService.getAvailableCommitment(selectedBond.bankId, true),
                this.bankService.getAvailableCommitment(selectedBond.bankId, false)
            )
                .subscribe(([bond, banks, documents, fgAvailableCommitment, availableCommitment]) => {
                    if (bond === null || bond === undefined || bond?.id < 1) {
                        throw new Error(`Bond data for ${bondId} not found.`);
                    }

                    this.bondData = bond;
                    this.bondData.bondType = bond.isLetterOfCredit ? BLCTypeEnum.LetterOfCredit : BLCTypeEnum.Bond;
                    this.bondData.dateExpired = this.bondData.currentDateExpired;

                    const getBondStatusResult = this.commonFunctions.getBondStatus(this.bondData);
                    this.bondData = getBondStatusResult.modifiedBond;
                    this.isStatusCanceled = getBondStatusResult.isStatusCanceled;

                    if (this.bondData.amountTypeId === AmountTypeEnum.FinancialGuarantee) {
                        this.isAmountTypeFinancialGuarantee = true;
                        this.bankData = fgAvailableCommitment;
                        this.availableCommitment = this.bankData.financialGuaranteeAvailableCommitment;
                    } else {
                        this.isAmountTypeFinancialGuarantee = false;
                        this.bankData = availableCommitment;
                        this.availableCommitment = this.bankData.availableCommitment;
                    }

                    this.banksForDialog = banks;
                    this.banksForDialog.sort((a, b) => a.name.localeCompare(b.name));
                    const result2 = this.commonFunctions.setSingleBondLimit(this.banksForDialog, this.bondData);
                    this.singleBondLimit = result2.sbl;

                    this.documents = documents as Document[];
                    this.displayEditDialog = true;
                }, () => {
                    this.messageService.add({
                        severity: 'error', summary: 'Unexpected Error Occured',
                        detail: 'An error occurred getting the BLC.'
                    });
                })
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Unexpected Error Occured',
                detail: 'An error occurred during multiple process after getting the BLC record.'
            });
        }
    }

    onLinkClickEvent(event: any): void {
        const resultArray = event.data.split('_');
        const type = resultArray[0];
        const id = resultArray[1];

        if (type === 'bankId') {
            this.onGettingBankToView(id);
        } else if (type === 'bondId') {
            this.onGettingBondToView(id);
        } else {
            this.messageService.add({
                severity: 'error',
                summary: 'Unexpected Error Occured',
                detail: 'Invalid link type on selected record'
            });
        }
    }

    onMoveSourceToTarget(): void {
        this.showResults = false;
        this.reportGridList = [];
        this.companyListTarget.sort((src: Company, tgt: Company) => src.marketNumber.localeCompare(tgt.marketNumber));
        this.isValidParams = this.validateReportParams(this.companyListTarget, this.reportParameter).length < 1 ? true : false;
    }

    onMoveTargetToSource(): void {
        this.showResults = false;
        this.reportGridList = [];
        this.companyList.sort((src: Company, tgt: Company) => src.marketNumber.localeCompare(tgt.marketNumber));
        this.isValidParams = this.validateReportParams(this.companyListTarget, this.reportParameter).length < 1 ? true : false;
    }

    onResetSearch(): void {
        this.companyList.push(...this.companyListTarget);
        this.companyList.sort((src: Company, tgt: Company) => src.marketNumber.localeCompare(tgt.marketNumber));
        this.companyListTarget = [];

        this.reportGridList = [];
        this.reportExcelList = [];
        this.exportHeader = [];
        this.showResults = false;

        this.reportParameter = new InvoiceByCompanyReportParam();
        this.isValidParams = this.validateReportParams(this.companyListTarget, this.reportParameter).length < 1 ? true : false;
    }

    onSearch(): void {
        try {
            //  validate date range if entered !
            this.setDatesToUtc();
            const dValidate = this.dateRangeValidator(this.reportParameter.fromDateAp, this.reportParameter.toDateAp);
            if (dValidate.valid === false) {
                alert(dValidate.msg);
                return;
            }
            this.reportGridList = [];
            this.reportExcelList = [];
            this.exportHeader = [];

            const validatedParams = this.validateReportParams(this.companyListTarget, this.reportParameter);
            this.isValidParams = validatedParams.length < 1 ? true : false;

            for (const validatedItem of validatedParams) {
                if (validatedItem.isValid === false) {
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Entry Validation Error Occured',
                        detail: validatedItem.message
                    });
                    return;
                }
            }

            // Set search params and report results title
            this.reportTitle = `Invoice by Company`;
            this.reportSubTitle = `Accounts Payable Dates: ${this.datePipe.transform(this.reportParameter.fromDateAp, 'MM/dd/yyyy')} - ${this.datePipe.transform(this.reportParameter.toDateAp, 'MM/dd/yyyy')}`

            // Set result table columns
            this.cols = [
                { field: 'company', header: 'Company' },
                { field: 'community', header: 'Community' },
                { field: 'bank', header: 'Bank/Surety', link: 'bankId' },
                { field: 'bondAmount', header: 'Current Amount', dataType: 'currency' },
                { field: 'rate', header: 'Rate(bps)' },
                { field: 'bondNumber', header: 'Bond/LC#', link: 'bondId' },
                { field: 'dateAP', header: 'AP Date', dataType: 'date' },
                { field: 'startDate', header: 'Invoice Start Date', dataType: 'date' },
                { field: 'endDate', header: 'Invoice End Date', dataType: 'date' },
                { field: 'description', header: 'Notes' },
                { field: 'amount', header: 'Fee', dataType: 'currency' }
            ];

            // Set header row values for export
            for (const col of this.cols) {
                if (col.header) {
                    this.exportHeader.push(col.header);
                }
            }

            this.reportParameter.companyNumbers = [];
            for (const oitem of this.companyListTarget) {
                if ((oitem.marketNumber) || oitem.marketNumber != null) {
                    this.reportParameter.companyNumbers.push(oitem.marketNumber);
                }
            }

            this.reportService.getInvoiceByCompany(this.reportParameter)
                .subscribe((res: any[]) => {
                    // Format the date objects to remove timestamps
                    res.forEach((result) => {
                        this.commonFunctions.trimTimeFromDatePropertiesInObject(result);
                    });
                    var invCurrentAmmountTotal = 0;
                    var invFeeTotal = 0;
                    const groupedBy = this.commonFunctions.groupBy(res, `company`);
                    const decimalFormat = { minimumFractionDigits: 2, maximumFractionDigits: 2 };

                    if (Object.keys(groupedBy).length > 0) {
                        for (const rpt in groupedBy) {
                            const report = groupedBy[rpt];
                            const invMatches = report?.length;

                            var invoiceSummaryReportForRow: InvoiceByCompanyReport = {
                                company: `${invMatches} matches`,
                                community: `Invoice Summary Report For ${report[0]?.company}`,
                                bank: null,
                                bondAmount: null,
                                rate: null,
                                bondNumber: null,
                                dateAP: null,
                                startDate: null,
                                endDate: null,
                                description: null,
                                amount: null,
                                bankId: null,
                                bondId: null,
                                groupKey: 1000
                            };
                            this.reportGridList.push(invoiceSummaryReportForRow);

                            var excelSummaryReportForRow: InvoiceByCompanyExcel = {
                                company: `${invMatches} matches`,
                                community: `Invoice Summary Report For ${report[0]?.company}`,
                                bank: null,
                                bondAmount: null,
                                rate: null,
                                bondNumber: null,
                                dateAP: null,
                                startDate: null,
                                endDate: null,
                                description: null,
                                amount: null
                            };
                            this.reportExcelList.push(excelSummaryReportForRow);

                            const arrB = report.map(b => b.bank);
                            const sRB = new Set(arrB);
                            const uniqueBanks = Array.from(sRB);

                            var overallTotalAmount = 0;
                            var overallFeeAmount = 0;
                            var filtered: InvoiceByCompanyReport[] = [];

                            for (const theBank of uniqueBanks) {
                                var currentAmountTotal: number = 0;
                                var currentFeeTotal: number = 0;
                                filtered = report.filter(bnk => bnk.bank === theBank);

                                for (const invoiceRecord of filtered) {
                                    this.filteredCount.push(invoiceRecord);

                                    currentAmountTotal += invoiceRecord.bondAmount;
                                    currentFeeTotal += invoiceRecord.amount;
                                    invCurrentAmmountTotal += invoiceRecord.bondAmount;
                                    invFeeTotal += invoiceRecord.amount;

                                    var recordRow: InvoiceByCompanyReport = {
                                        company: invoiceRecord.company,
                                        community: invoiceRecord.community,
                                        bank: invoiceRecord.bank,
                                        bondAmount: (invoiceRecord.bondAmount) ? parseFloat(invoiceRecord.bondAmount.toFixed(2)) : 0.00,
                                        rate: (invoiceRecord.rate) ? parseFloat(invoiceRecord.rate.toFixed(2)) : 0.00,
                                        bondNumber: invoiceRecord.bondNumber,
                                        dateAP: invoiceRecord.dateAP,
                                        startDate: invoiceRecord.startDate,
                                        endDate: invoiceRecord.endDate,
                                        description: invoiceRecord.description,
                                        amount: (invoiceRecord.amount) ? parseFloat(invoiceRecord.amount.toFixed(2)) : 0.00,
                                        bankId: invoiceRecord.bankId,
                                        bondId: invoiceRecord.bondId,
                                        groupKey: 0
                                    };
                                    this.reportGridList.push(recordRow);

                                    const excelRow = {
                                        company: invoiceRecord.company,
                                        community: invoiceRecord.community,
                                        bank: invoiceRecord.bank,
                                        bondAmount: +invoiceRecord.bondAmount < 0 ? `($${invoiceRecord.bondAmount.toLocaleString('en', decimalFormat)})` : `$${invoiceRecord.bondAmount.toLocaleString('en', decimalFormat)}`,
                                        rate: (invoiceRecord.rate) ? parseFloat(invoiceRecord.rate.toFixed(2)) : 0.00,
                                        bondNumber: invoiceRecord.bondNumber,
                                        dateAP: invoiceRecord.dateAP,
                                        startDate: invoiceRecord.startDate,
                                        endDate: invoiceRecord.endDate,
                                        description: invoiceRecord.description,
                                        amount: +invoiceRecord.amount < 0 ? `($${invoiceRecord.amount.toLocaleString('en', decimalFormat)})` : `$${invoiceRecord.amount.toLocaleString('en', decimalFormat)}`
                                    };
                                    this.reportExcelList.push(excelRow);
                                }

                                if (filtered.length != 0) {
                                    var invoiceTotalForRow: InvoiceByCompanyReport = {
                                        company: null,
                                        community: `Invoice Total For ${theBank}`,
                                        bank: null,
                                        bondAmount: (currentAmountTotal) ? parseFloat(currentAmountTotal.toFixed(2)) : 0.00,
                                        rate: null,
                                        bondNumber: null,
                                        dateAP: null,
                                        startDate: null,
                                        endDate: null,
                                        description: null,
                                        amount: (currentFeeTotal) ? parseFloat(currentFeeTotal.toFixed(2)) : 0.00,
                                        bankId: null,
                                        bondId: null,
                                        groupKey: 3000
                                    };
                                    this.reportGridList.push(invoiceTotalForRow);

                                    var excelTotalForRow: InvoiceByCompanyExcel = {
                                        company: null,
                                        community: `Invoice Total For ${theBank}`,
                                        bank: null,
                                        bondAmount: +currentAmountTotal < 0 ? `($${currentAmountTotal.toLocaleString('en', decimalFormat)})` : `$${currentAmountTotal.toLocaleString('en', decimalFormat)}`,
                                        rate: null,
                                        bondNumber: null,
                                        dateAP: null,
                                        startDate: null,
                                        endDate: null,
                                        description: null,
                                        amount: +currentFeeTotal < 0 ? `($${currentFeeTotal.toLocaleString('en', decimalFormat)})` : `$${currentFeeTotal.toLocaleString('en', decimalFormat)}`
                                    };
                                    this.reportExcelList.push(excelTotalForRow);

                                    overallTotalAmount += (currentAmountTotal) ? parseFloat(currentAmountTotal.toFixed(2)) : 0.00;
                                    overallFeeAmount += (currentFeeTotal) ? parseFloat(currentFeeTotal.toFixed(2)) : 0.00;
                                }
                            }

                            if (filtered.length != 0) {
                                var invoiceTotalForRow: InvoiceByCompanyReport = {
                                    company: null,
                                    community: `Invoice Total For ${report[0]?.company}`,
                                    bank: null,
                                    bondAmount: overallTotalAmount,
                                    rate: null,
                                    bondNumber: null,
                                    dateAP: null,
                                    startDate: null,
                                    endDate: null,
                                    description: null,
                                    amount: overallFeeAmount,
                                    bankId: null,
                                    bondId: null,
                                    groupKey: 16000
                                };
                                this.reportGridList.push(invoiceTotalForRow);

                                var excelTotalForRow: InvoiceByCompanyExcel = {
                                    company: null,
                                    community: `Invoice Total For ${report[0]?.company}`,
                                    bank: null,
                                    bondAmount: +overallTotalAmount < 0 ? `($${overallTotalAmount.toLocaleString('en', decimalFormat)})` : `$${overallTotalAmount.toLocaleString('en', decimalFormat)}`,
                                    rate: null,
                                    bondNumber: null,
                                    dateAP: null,
                                    startDate: null,
                                    endDate: null,
                                    description: null,
                                    amount: +overallFeeAmount < 0 ? `($${overallFeeAmount.toLocaleString('en', decimalFormat)})` : `$${overallFeeAmount.toLocaleString('en', decimalFormat)}`
                                };
                                this.reportExcelList.push(excelTotalForRow);
                            } else {
                                var noMatchesRow: InvoiceByCompanyReport = {
                                    company: 'No matches found',
                                    community: null,
                                    bank: null,
                                    bondAmount: null,
                                    rate: null,
                                    bondNumber: null,
                                    dateAP: null,
                                    startDate: null,
                                    endDate: null,
                                    description: null,
                                    amount: null,
                                    bankId: null,
                                    bondId: null,
                                    groupKey: 4000
                                };
                                this.reportGridList.push(noMatchesRow);

                                var noMatchesExcelRow: InvoiceByCompanyExcel = {
                                    company: 'No matches found',
                                    community: null,
                                    bank: null,
                                    bondAmount: null,
                                    rate: null,
                                    bondNumber: null,
                                    dateAP: null,
                                    startDate: null,
                                    endDate: null,
                                    description: null,
                                    amount: null
                                };
                                this.reportExcelList.push(noMatchesExcelRow);
                            }
                        }

                        if (this.reportParameter.companyNumbers.length > 1) {
                            var invoicesTotalRow: InvoiceByCompanyReport = {
                                company: `Invoices Total`,
                                community: null,
                                bank: null,
                                bondAmount: (invCurrentAmmountTotal) ? parseFloat(invCurrentAmmountTotal.toFixed(2)) : 0.00,
                                rate: null,
                                bondNumber: null,
                                dateAP: null,
                                startDate: null,
                                endDate: null,
                                description: null,
                                amount: (invFeeTotal) ? parseFloat(invFeeTotal.toFixed(2)) : 0.00,
                                bankId: null,
                                bondId: null,
                                groupKey: 5000
                            };
                            this.reportGridList.push(invoicesTotalRow);

                            var excelTotalRow: InvoiceByCompanyExcel = {
                                company: `Invoices Total`,
                                community: null,
                                bank: null,
                                bondAmount: +invCurrentAmmountTotal < 0 ? `($${invCurrentAmmountTotal.toLocaleString('en', decimalFormat)})` : `$${invCurrentAmmountTotal.toLocaleString('en', decimalFormat)}`,
                                rate: null,
                                bondNumber: null,
                                dateAP: null,
                                startDate: null,
                                endDate: null,
                                description: null,
                                amount: +invFeeTotal < 0 ? `($${invFeeTotal.toLocaleString('en', decimalFormat)})` : `$${invFeeTotal.toLocaleString('en', decimalFormat)}`
                            };
                            this.reportExcelList.push(excelTotalRow);
                        }

                        this.showResults = true;
                    } else {
                        for (const noco of this.companyListTarget) {
                            var invoiceSummaryReportForRow: InvoiceByCompanyReport = {
                                company: `0 matches`,
                                community: `Invoice Summary Report For ${noco?.companyName}`,
                                bank: null,
                                bondAmount: null,
                                rate: null,
                                bondNumber: null,
                                dateAP: null,
                                startDate: null,
                                endDate: null,
                                description: null,
                                amount: null,
                                bankId: null,
                                bondId: null,
                                groupKey: 1000
                            };
                            this.reportGridList.push(invoiceSummaryReportForRow);

                            var excelSummaryReportForRow: InvoiceByCompanyExcel = {
                                company: `0 matches`,
                                community: `Invoice Summary Report For ${noco?.companyName}`,
                                bank: null,
                                bondAmount: null,
                                rate: null,
                                bondNumber: null,
                                dateAP: null,
                                startDate: null,
                                endDate: null,
                                description: null,
                                amount: null
                            };
                            this.reportExcelList.push(excelSummaryReportForRow);

                            var noMatchesRow: InvoiceByCompanyReport = {
                                company: 'No matches found',
                                community: null,
                                bank: null,
                                bondAmount: null,
                                rate: null,
                                bondNumber: null,
                                dateAP: null,
                                startDate: null,
                                endDate: null,
                                description: null,
                                amount: null,
                                bankId: null,
                                bondId: null,
                                groupKey: 4000
                            };
                            this.reportGridList.push(noMatchesRow);

                            var noMatchesExcelRow: InvoiceByCompanyExcel = {
                                company: 'No matches found',
                                community: null,
                                bank: null,
                                bondAmount: null,
                                rate: null,
                                bondNumber: null,
                                dateAP: null,
                                startDate: null,
                                endDate: null,
                                description: null,
                                amount: null
                            };
                            this.reportExcelList.push(noMatchesExcelRow);

                            this.showResults = true;
                        }
                    }
                });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Report Layout Error Occured',
                detail: `There were error(s) occured during report layout process.`
            });
        } finally {
            this.filteredCount = [];
        }
    }

    onToDateApChange(event: any): void {
        this.showResults = false;
        this.reportGridList = [];

        if (event.target?.value === 'mm/dd/yyyy') {
            this.reportParameter.toDateAp == null;
        }

        this.isValidParams = this.validateReportParams(this.companyListTarget, this.reportParameter).length < 1 ? true : false;
    }

    sendChildMessage(messageArray: string[]) {
        this.messageService.add({ severity: messageArray[0], summary: messageArray[1], detail: messageArray[2] });
    }

    setDatesToUtc(): void {
        if (this.reportParameter.fromDateAp) {
            const utcd = this.commonFunctions.formatDateMmddyyyy(new Date(this.reportParameter.fromDateAp).toISOString());
            this.reportParameter.fromDateAp = utcd;
        }

        if (this.reportParameter.toDateAp) {
            const utcd = this.commonFunctions.formatDateMmddyyyy(new Date(this.reportParameter.toDateAp).toISOString());
            this.reportParameter.toDateAp = utcd;
        }
    }

    validateReportParams(cotList: Company[], param: InvoiceByCompanyReportParam): any[] {
        var retval: any[] = [];
        if (cotList.length < 1) {
            retval.push({
                isValid: false,
                message: `No company found in the Selected Company list box. Select a company.`
            });
        }

        if ((!param.fromDateAp) || (param.fromDateAp === '')) {
            retval.push({
                isValid: false,
                message: `Please enter a from date.`
            });
        }

        if ((!param.toDateAp) || (param.toDateAp === '')) {
            retval.push({
                isValid: false,
                message: `Please enter a to date.`
            });
        }

        return retval;
    }
}
