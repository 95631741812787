import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import * as XLSX from 'exceljs/dist/exceljs.min.js';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ReportResultsComponent } from '../report-results/report-results.component';

import { AmountTypeEnum, BLCTypeEnum, BondStatusEnum } from '../../../models/enums';
import { CommonFunctions } from '../../../functions';
import {
    AmountType, Bank, BankAmountType, BanksFilter, BankWithAmountTypes, BankWithFinancialGuarantee, Bond,
    Company, Document, InvoiceBankSurety, InvoiceBankSuretyExcel, InvoiceBankSuretyParam, InvoiceBankSuretyReport, ReportColumn
} from '../../../models';
import {
    AmountTypeService, AuthorizationService, BankAmountTypeService, BankService, BondService,
    CompanyService, DocumentService, ExcelService, ReportService
} from '../../../services';

@Component({
    selector: 'app-invoice-by-bank-surety',
    templateUrl: './invoice-by-bank-surety.component.html',
    styleUrls: ['./invoice-by-bank-surety.component.css'],
    providers: [MessageService]
})
export class InvoiceByBankSuretyComponent implements OnInit {
    amountTypeList: AmountType[] = [];
    availableCommitment: number = null;
    bankNameOptions: any[] = [];
    bankData: BankWithFinancialGuarantee;
    bankList: BanksFilter[] = [];
    banksForDialog: Bank[] = [];
    blcOptions: any[] = [];
    bondData: Bond;
    bondStatus = BondStatusEnum;
    cols: ReportColumn[];
    companyList: Company[] = [];
    displayEditDialog: boolean = false;
    displayBankEditDialog: boolean = false;
    documents: Document[];
    excelResultSet: InvoiceBankSuretyExcel[] = [];
    exportFileName: string = '';
    exportHeader: string[] = [];
    filteredList: InvoiceBankSuretyReport[] = [];
    invoiceBankSuretyParam: InvoiceBankSuretyParam = new InvoiceBankSuretyParam();
    isAmountTypeFinancialGuarantee: boolean;
    isLoading: boolean = false;
    isStatusCanceled: boolean;
    reportPresentationList: any[] = [];
    reportSubTitle: string = '';
    reportTitle: string = '';
    reportType: string = '';
    results: InvoiceBankSuretyReport[] = [];
    resultSetFooter: any;
    selectedBank: BankWithAmountTypes;
    selectedBankAmountTypes: string[] = [];
    showNoResultsMessage: boolean = false;
    showResults: boolean = false;
    singleBondLimit: string = null;
    sub: any = null;

    @ViewChild(ReportResultsComponent) resultsComp: ReportResultsComponent;

    constructor(private amountTypeService: AmountTypeService,
        private authorizationService: AuthorizationService,
        private bankAmountTypeService: BankAmountTypeService,
        private bankService: BankService,
        private bondService: BondService,
        private commonFunctions: CommonFunctions,
        private companyService: CompanyService,
        private datePipe: DatePipe,
        private documentService: DocumentService,
        private excelService: ExcelService,
        private messageService: MessageService,
        private primengConfig: PrimeNGConfig,
        private reportService: ReportService) { }

    ngOnInit(): void {
        this.authorizationService.checkBlcUserAccess();

        //  load amount types !
        this.amountTypeService.getAmountTypes()
            .subscribe((result: AmountType[]) => {
                this.amountTypeList = result;
                this.amountTypeList.sort((a, b) => a.name.localeCompare(b.name));
            }, () => {
                this.messageService.add({
                    severity: 'error', summary: 'Unexpected Error Occured',
                    detail: 'An error occurred getting Amount Types.'
                });
            });
        //  load drop down data for bank names !
        this.bankService.getBanksFromSearch(null)
            .subscribe((result: BanksFilter[]) => {
                this.bankList = result;
                this.bankNameOptions.push({ value: -1, data: -1, label: `-- All Banks/Sureties --`, selected: 'selected' });
                for (const bl of result.sort((a, b) => a.name.localeCompare(b.name))) {
                    this.bankNameOptions.push({ value: bl.id, data: bl.id, label: bl.name });
                }
                this.invoiceBankSuretyParam = this.resetParam();
            }, () => {
                this.messageService.add({
                    severity: 'error', summary: 'Unexpected Error Occured',
                    detail: 'An error occurred getting a bank list.'
                });
            });
        //  load company array !
        this.companyService.getCompanies()
            .subscribe((companies) => {
                this.companyList = companies;
                this.companyList = this.commonFunctions.sortAndFormatCompanies(this.companyList);
            }, () => {
                this.messageService.add({
                    severity: 'error', summary: 'Unexpected Error Occured',
                    detail: 'An error occurred getting Companies.'
                });
            });

        this.primengConfig.ripple = true;
    }

    exportExcel(): void {
        const workbook = new XLSX.Workbook();
        const worksheet = workbook.addWorksheet('Report Results');

        // Add title row
        const titleRow = worksheet.addRow([this.reportTitle]);
        worksheet.mergeCells('A1:K1');
        worksheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' };

        // Set font, size and style in title row
        titleRow.font = { name: 'Calibri', family: 4, size: 16, underline: 'double', bold: true };

        // Blank Row
        worksheet.addRow([]);

        // Set column widths to fix header row
        worksheet.columns.forEach((column) => {
            column.width = 15;
        });

        // Add Header Row
        const headerRow = worksheet.addRow(this.exportHeader);

        // Wrap and align the header text
        headerRow.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };

        // Set header row height
        headerRow.height = 60;

        // Fill and Border Headers
        headerRow.eachCell((cell) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'D3D3D3' },
                bgColor: { argb: '' }
            };
            cell.border = { top: { style: 'none' }, left: { style: 'none' }, bottom: { style: 'none' }, right: { style: 'none' } };
            cell.font = { weight: 'bold' };
        });

        let isTotalRow = false;
        // Add Data
        this.excelResultSet.forEach(dataRow => {
            const dataStrings: any[] = [];

            // Convert each prop into a string array
            Object.values(dataRow).forEach((val: any, index: number) => {
                switch (index) {
                    case 7: //AP Date
                    case 8: //Invoice Start Date
                    case 9: //Invoice End Date
                        if (val?.toString()) {
                            dataStrings.push(new Date(val));
                        }
                        else {
                            dataStrings.push(val);
                        }
                        break;
                    default: //Everything else
                        dataStrings.push(val);
                }
            });

            // Add the data row
            const row = worksheet.addRow(dataStrings);
            row.eachCell({ includeEmpty: true }, (cell => {
                cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            }));

            // Check if it is a eoy total row
            if (row.getCell(1).value !== null) {
                if (row.getCell(1).value.toString().includes('Total ')) {
                    isTotalRow = true;
                }
            }

            // Format the cells
            row.eachCell((cell) => {
                // If eoy total row, set to bold
                if (isTotalRow) {
                    cell.font = { bold: true };
                } else if (isTotalRow && cell.value.toString().includes('(') && cell.value.toString().includes('$') && cell.value.toString().includes(')')) {
                    cell.font = { color: { argb: 'FF0000' }, bold: true };
                    cell.value = cell.value.replace('-', '');
                }

                // If negative value, set font to red
                if (typeof cell.value === 'string' && cell.value.toString().includes('(') && cell.value.toString().includes('$') && cell.value.toString().includes(')')) {
                    cell.font = { color: { argb: 'FF0000' } };
                    cell.value = cell.value.replace('-', '');
                }
            });

            if (isTotalRow) {
                worksheet.addRow();
                isTotalRow = false;
            }

        });

        this.excelService.exportAsExcelFile(workbook, this.exportFileName);
    }

    onBankModalEditDialogClose(): void {
        this.displayBankEditDialog = false;
        this.selectedBankAmountTypes = [];
        this.selectedBank = null;
    }

    onBondModalEditDialogClose(): void {
        this.displayEditDialog = false;
    }

    onGettingBankToView(bankId: number): void {
        try {
            this.bankService.getBankWithCommitment(bankId).pipe(
                switchMap((bankWithAmountTypes: BankWithAmountTypes) => {
                    this.selectedBank = bankWithAmountTypes;

                    //  get the bank amount types from selected bank !
                    return this.bankAmountTypeService.getBankAmountTypeByBank(this.selectedBank.id);
                })
            ).subscribe((bankAmountType: BankAmountType[]) => {
                this.selectedBank.amountTypes = [];
                //  loop through the bank amoutn types result from selected bank !
                bankAmountType.forEach(res => {
                    this.selectedBankAmountTypes.push(res.amountTypeId.toString());
                    this.selectedBank.amountTypes.push(res.amountTypeId);
                });
                //  display the edit bank dialog !
                this.displayBankEditDialog = true;
            }, () => {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Bank Amount Types Not Loaded',
                    detail: `Please be advised, unable to get Bank Amount Types for ${this.selectedBank.name}.`
                });
                this.selectedBankAmountTypes = null;
                this.displayBankEditDialog = true;
            });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Unexpected Error Occured',
                detail: 'An error occurred during multiple process after getting the bank or surety record.'
            });
        }
    }

    onGettingBondToView(bondId: number): void {
        try {
            if (bondId === null || bondId === undefined) {
                throw new Error(`Invalid BondId`);
            }

            const selectedBond = this.filteredList.find(bond => bond.bondId == bondId);

            forkJoin(
                this.bondService.getBondById(selectedBond.bondId),
                this.bankService.getBanks(true),
                this.documentService.getDocumentsByBlcId(selectedBond.bondId),
                this.bankService.getAvailableCommitment(selectedBond.bankId, true),
                this.bankService.getAvailableCommitment(selectedBond.bankId, false)
            )
                .subscribe(([bond, banks, documents, fgAvailableCommitment, availableCommitment]) => {
                    if (bond === null || bond === undefined || bond?.id < 1) {
                        throw new Error(`Bond data for ${bondId} not found.`);
                    }

                    this.bondData = bond;
                    this.bondData.bondType = bond.isLetterOfCredit ? BLCTypeEnum.LetterOfCredit : BLCTypeEnum.Bond;
                    this.bondData.dateExpired = this.bondData.currentDateExpired;

                    const getBondStatusResult = this.commonFunctions.getBondStatus(this.bondData);
                    this.bondData = getBondStatusResult.modifiedBond;
                    this.isStatusCanceled = getBondStatusResult.isStatusCanceled;

                    if (this.bondData.amountTypeId === AmountTypeEnum.FinancialGuarantee) {
                        this.isAmountTypeFinancialGuarantee = true;
                        this.bankData = fgAvailableCommitment;
                        this.availableCommitment = this.bankData.financialGuaranteeAvailableCommitment;
                    } else {
                        this.isAmountTypeFinancialGuarantee = false;
                        this.bankData = availableCommitment;
                        this.availableCommitment = this.bankData.availableCommitment;
                    }

                    this.banksForDialog = banks;
                    this.banksForDialog.sort((a, b) => a.name.localeCompare(b.name));
                    const result2 = this.commonFunctions.setSingleBondLimit(this.banksForDialog, this.bondData);
                    this.singleBondLimit = result2.sbl;

                    this.documents = documents as Document[];
                    this.displayEditDialog = true;
                }, () => {
                    this.messageService.add({
                        severity: 'error', summary: 'Unexpected Error Occured',
                        detail: 'An error occurred getting the BLC.'
                    });
                })
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Unexpected Error Occured',
                detail: 'An error occurred during multiple process after getting the BLC record.'
            });
        }
    }

    onLinkClickEvent(event: any): void {
        const resultArray = event.data.split('_');
        const type = resultArray[0];
        const id = resultArray[1];

        if (type === 'bankId') {
            this.onGettingBankToView(id);
        } else if (type === 'bondId') {
            this.onGettingBondToView(id);
        } else {
            this.messageService.add({
                severity: 'error',
                summary: 'Unexpected Error Occured',
                detail: 'Invalid link type on selected record'
            });
        }
    }

    onResetClick(): void {
        this.invoiceBankSuretyParam = this.resetParam();
    }

    onSearchClick(): void {
        //  validation block !
        const validationResult = this.validateDateRange(this.invoiceBankSuretyParam);
        if (validationResult.valid === false) {
            alert(validationResult.message);
            return;
        }

        this.showResults = false;
        this.isLoading = true;

        // Set search params and report results title
        this.reportTitle = `Invoice by Bank/Surety`;
        this.reportSubTitle = ((this.invoiceBankSuretyParam.fromDateAp) && (this.invoiceBankSuretyParam.toDateAp))
            ? ` Accounts Payable Dates: ${this.datePipe.transform(this.invoiceBankSuretyParam.fromDateAp, 'MM/dd/yyyy')} - ${this.datePipe.transform(this.invoiceBankSuretyParam.toDateAp, 'MM/dd/yyyy')}`
            : ``;

        // Set result table columns
        this.cols = [
            { field: 'name', header: 'Bank/Surety', link: 'bankId' },
            { field: 'company', header: 'Company' },
            { field: 'community', header: 'Community' },
            { field: 'bondNumber', header: 'Bond/LC#', link: 'bondId' },
            { field: 'currentAmount', header: 'Current Amount', dataType: 'currency' },
            { field: 'rate', header: 'Rate(bps)' },
            { field: 'fee', header: 'Fee', dataType: 'currency' },
            { field: 'apDate', header: 'AP Date', dataType: `date` },
            { field: 'invoiceStartDate', header: 'Invoice Start Date', dataType: `date` },
            { field: 'invoiceEndDate', header: 'Invoice End Date', dataType: `date` },
            { field: 'notes', header: 'Notes' }
        ];

        // Set export file name
        this.exportFileName = 'INVOICEBYBANKSURETY';

        // Reset the header row
        this.exportHeader = [];

        // Set header row values for export
        for (const col of this.cols) {
            this.exportHeader.push(col.header);
        }

        const proxyBank = new InvoiceBankSuretyParam();
        proxyBank.bankId = (this.invoiceBankSuretyParam.bankId < 0) ? null : this.invoiceBankSuretyParam.bankId;
        proxyBank.fromDateAp = this.datePipe.transform(this.invoiceBankSuretyParam.fromDateAp, 'MM/dd/yyyy');
        proxyBank.toDateAp = this.datePipe.transform(this.invoiceBankSuretyParam.toDateAp, 'MM/dd/yyyy');

        this.reportService.getInvoiceBankSurety(proxyBank)
            .subscribe((result: InvoiceBankSurety[]) => {
                const decimalFormat = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
                this.results = [];
                this.excelResultSet = [];
                let totalAmount = 0;
                let totalBondAmount = 0;
                let totalInvoice = 0;
                let bankName = ``;

                // Format the date objects to remove timestamps
                result.forEach((res) => {
                    this.commonFunctions.trimTimeFromDatePropertiesInObject(res);
                });

                if (proxyBank.bankId > 0) {
                    for (const ibs of result) {
                        totalInvoice += 1;
                        totalAmount += (ibs.amount === null) ? 0 : ibs.amount;
                        totalBondAmount += (ibs.bondAmount === null) ? 0 : ibs.bondAmount;
                        bankName = ibs.bank;

                        const d_currentAmount = (ibs.bondAmount) ? ibs.bondAmount.toLocaleString('en', decimalFormat) : `0.00`;
                        const d_fee = (ibs.amount) ? ibs.amount.toLocaleString('en', decimalFormat) : `0.00`;
                        const d_rate = (ibs.rate) ? ibs.rate.toLocaleString('en', decimalFormat) : `0.00`;

                        //  result grid results !
                        const newRow = new InvoiceBankSuretyReport();
                        newRow.name = ibs.bank;
                        newRow.company = ibs.company;
                        newRow.community = ibs.community;
                        newRow.bondNumber = ibs.bondNumber;
                        newRow.currentAmount = (ibs.bondAmount) ? parseFloat(ibs.bondAmount.toFixed(2)) : 0.00;
                        newRow.rate = (ibs.rate) ? parseFloat(ibs.rate.toFixed(2)) : 0.00;
                        newRow.fee = (ibs.amount) ? parseFloat(ibs.amount.toFixed(2)) : 0.00;
                        newRow.apDate = ibs.dateAP;
                        newRow.invoiceStartDate = ibs.datePayPeriodStart;
                        newRow.invoiceEndDate = ibs.datePayPeriodEnd;
                        newRow.notes = ibs.description;
                        newRow.bankId = (ibs?.bankId) ? ibs?.bankId : 0;
                        newRow.bondId = (ibs?.bondId) ? ibs?.bondId : 0;
                        this.results.push(newRow);

                        //  excel export result !
                        const newExcel = new InvoiceBankSuretyExcel();
                        newExcel.name = ibs.bank;
                        newExcel.company = ibs.company;
                        newExcel.community = ibs.community;
                        newExcel.bondNumber = ibs.bondNumber;
                        newExcel.currentAmount = +ibs.bondAmount < 0 ? `($${d_currentAmount})` : `$${d_currentAmount}`;
                        newExcel.rate = +ibs.rate < 0 ? `(${d_rate})` : `${d_rate}`;
                        newExcel.fee = +ibs.amount < 0 ? `($${d_fee})` : `$${d_fee}`;
                        newExcel.apDate = ibs.dateAP;
                        newExcel.invoiceStartDate = ibs.datePayPeriodStart;
                        newExcel.invoiceEndDate = ibs.datePayPeriodEnd;
                        newExcel.notes = ibs.description;
                        this.excelResultSet.push(newExcel);
                    }

                    //  lay out the totals !
                    if (totalInvoice > 0) {
                        const d_currentAmount = (totalBondAmount) ? totalBondAmount.toLocaleString('en', decimalFormat) : `0.00`;
                        const d_fee = (totalAmount) ? totalAmount.toLocaleString('en', decimalFormat) : `0.00`;

                        //  report grid result !
                        let newRow = new InvoiceBankSuretyReport();
                        newRow.name = `Total for ${bankName}`;
                        newRow.company = ``;
                        newRow.community = ``;
                        newRow.bondNumber = null;
                        newRow.currentAmount = (totalBondAmount) ? parseFloat(totalBondAmount.toFixed(2)) : 0.00;
                        newRow.rate = null;
                        newRow.fee = (totalAmount) ? parseFloat(totalAmount.toFixed(2)) : 0.00;
                        newRow.apDate = null;
                        newRow.invoiceStartDate = null;
                        newRow.invoiceEndDate = null;
                        newRow.notes = `# of invoices ${totalInvoice}`;
                        newRow.bankId = null;
                        newRow.bondId = null;
                        newRow.groupKey = 14000;
                        this.results.push(newRow);

                        newRow = new InvoiceBankSuretyReport();
                        newRow.name = `Total for Date Range`;
                        newRow.company = ``;
                        newRow.community = ``;
                        newRow.bondNumber = null;
                        newRow.currentAmount = (totalBondAmount) ? parseFloat(totalBondAmount.toFixed(2)) : 0.00;
                        newRow.rate = null;
                        newRow.fee = (totalAmount) ? parseFloat(totalAmount.toFixed(2)) : 0.00;
                        newRow.apDate = null;
                        newRow.invoiceStartDate = null;
                        newRow.invoiceEndDate = null;
                        newRow.notes = `# of invoices ${totalInvoice}`;
                        newRow.bankId = null;
                        newRow.bondId = null;
                        newRow.groupKey = 15000;
                        this.results.push(newRow);

                        //  excel export result !
                        let newExcel = new InvoiceBankSuretyExcel();
                        newExcel.name = `Total for ${bankName}`;
                        newExcel.company = ``;
                        newExcel.community = ``;
                        newExcel.bondNumber = null;
                        newExcel.currentAmount = +totalBondAmount < 0 ? `($${d_currentAmount})` : `$${d_currentAmount}`;
                        newExcel.rate = null;
                        newExcel.fee = +totalAmount < 0 ? `($${d_fee})` : `$${d_fee}`;
                        newExcel.apDate = null;
                        newExcel.invoiceStartDate = null;
                        newExcel.invoiceEndDate = null;
                        newExcel.notes = `# of invoices ${totalInvoice}`;
                        this.excelResultSet.push(newExcel);

                        newExcel = new InvoiceBankSuretyExcel();
                        newExcel.name = `Total for Date Range`;
                        newExcel.company = ``;
                        newExcel.community = ``;
                        newExcel.bondNumber = null;
                        newExcel.currentAmount = +totalBondAmount < 0 ? `($${d_currentAmount})` : `$${d_currentAmount}`;
                        newExcel.rate = null;
                        newExcel.fee = +totalAmount < 0 ? `($${d_fee})` : `$${d_fee}`;
                        newExcel.apDate = null;
                        newExcel.invoiceStartDate = null;
                        newExcel.invoiceEndDate = null;
                        newExcel.notes = `# of invoices ${totalInvoice}`;
                        this.excelResultSet.push(newExcel);
                    }
                } else if (proxyBank.bankId == null) {
                    //  bankId = -1 all banks on the list, means the API will return all it can find !
                    //  loop through the bank list from the ngoninit fn !
                    let drTotalInvoice = 0;
                    let drTotalAmount = 0;
                    let drTotalBondAmount = 0;

                    for (const bnk of this.bankList) {
                        const filtered = result.filter(e => e.bankId === bnk.id);

                        if (filtered.length > 0) {
                            //  now loop through the filtered report list from API !
                            totalInvoice = 0;
                            totalAmount = 0;
                            totalBondAmount = 0;
                            bankName = bnk.name;

                            for (const ibl of filtered) {
                                totalInvoice += 1;
                                totalAmount += (ibl.amount === null) ? 0 : ibl.amount;
                                totalBondAmount += (ibl.bondAmount === null) ? 0 : ibl.bondAmount;

                                //  report result grid !
                                const newRow = new InvoiceBankSuretyReport();
                                newRow.name = ibl.bank;
                                newRow.company = ibl.company;
                                newRow.community = ibl.community;
                                newRow.bondNumber = ibl.bondNumber;
                                newRow.currentAmount = (ibl.bondAmount) ? parseFloat(ibl.bondAmount.toFixed(2)) : 0.00;
                                newRow.rate = (ibl.rate) ? parseFloat(ibl.rate.toFixed(2)) : 0.00;
                                newRow.fee = (ibl.amount) ? parseFloat(ibl.amount.toFixed(2)) : 0.00;
                                newRow.apDate = ibl.dateAP;
                                newRow.invoiceStartDate = ibl.datePayPeriodStart;
                                newRow.invoiceEndDate = ibl.datePayPeriodEnd;
                                newRow.notes = ibl.description;
                                newRow.bankId = (ibl?.bankId) ? ibl?.bankId : 0;
                                newRow.bondId = (ibl?.bondId) ? ibl?.bondId : 0;
                                this.results.push(newRow);

                                //  excel export data !
                                const d_currentAmount = (ibl.bondAmount) ? parseFloat(ibl.bondAmount.toFixed(2)).toLocaleString('en', decimalFormat) : `0.00`;
                                const d_fee = (ibl.amount) ? ibl.amount.toLocaleString('en', decimalFormat) : `0.00`;
                                const d_rate = (ibl.rate) ? ibl.rate.toLocaleString('en', decimalFormat) : `0.00`;

                                const newExcel = new InvoiceBankSuretyExcel();
                                newExcel.name = ibl.bank;
                                newExcel.company = ibl.company;
                                newExcel.community = ibl.community;
                                newExcel.bondNumber = ibl.bondNumber;
                                newExcel.currentAmount = +ibl.bondAmount < 0 ? `($${d_currentAmount})` : `$${d_currentAmount}`;
                                newExcel.rate = +ibl.rate < 0 ? `(${d_rate})` : `${d_rate}`;
                                newExcel.fee = +ibl.amount < 0 ? `($${d_fee})` : `$${d_fee}`;
                                newExcel.apDate = ibl.dateAP;
                                newExcel.invoiceStartDate = ibl.datePayPeriodStart;
                                newExcel.invoiceEndDate = ibl.datePayPeriodEnd;
                                newExcel.notes = ibl.description;
                                this.excelResultSet.push(newExcel);
                            }

                            if (totalInvoice > 0) {
                                //  report result grid !
                                const newRow = new InvoiceBankSuretyReport();
                                newRow.name = `Total for ${bankName}`;
                                newRow.company = ``;
                                newRow.community = ``;
                                newRow.bondNumber = null;
                                newRow.currentAmount = (totalBondAmount) ? parseFloat(totalBondAmount.toFixed(2)) : 0.00;
                                newRow.rate = null;
                                newRow.fee = (totalAmount) ? parseFloat(totalAmount.toFixed(2)) : 0.00;
                                newRow.apDate = null;
                                newRow.invoiceStartDate = null;
                                newRow.invoiceEndDate = null;
                                newRow.notes = `# of invoices ${totalInvoice}`;
                                newRow.bankId = null;
                                newRow.bondId = null;
                                newRow.groupKey = 14000;
                                this.results.push(newRow);

                                //  excel export data !
                                const d_currentAmount = (totalBondAmount) ? totalBondAmount.toLocaleString('en', decimalFormat) : `0.00`;
                                const d_fee = (totalAmount) ? totalAmount.toLocaleString('en', decimalFormat) : `0.00`;

                                const newExcel = new InvoiceBankSuretyExcel();
                                newExcel.name = `Total for ${bankName}`;
                                newExcel.company = ``;
                                newExcel.community = ``;
                                newExcel.bondNumber = null;
                                newExcel.currentAmount = +totalBondAmount < 0 ? `($${d_currentAmount})` : `$${d_currentAmount}`;
                                newExcel.rate = null;
                                newExcel.fee = +totalAmount < 0 ? `($${d_fee})` : `$${d_fee}`;
                                newExcel.apDate = null;
                                newExcel.invoiceStartDate = null;
                                newExcel.invoiceEndDate = null;
                                newExcel.notes = `# of invoices ${totalInvoice}`;
                                this.excelResultSet.push(newExcel);

                                drTotalInvoice += totalInvoice;
                                drTotalBondAmount += totalBondAmount;
                                drTotalAmount += totalAmount;
                            }
                        }
                    }

                    if (drTotalInvoice > 0) {
                        const newRow = new InvoiceBankSuretyReport();
                        newRow.name = `Total for Date Range`;
                        newRow.company = ``;
                        newRow.community = ``;
                        newRow.bondNumber = null;
                        newRow.currentAmount = (drTotalBondAmount) ? parseFloat(drTotalBondAmount.toFixed(2)) : 0.00;
                        newRow.rate = null;
                        newRow.fee = (drTotalAmount) ? parseFloat(drTotalAmount.toFixed(2)) : 0.00;
                        newRow.apDate = null;
                        newRow.invoiceStartDate = null;
                        newRow.invoiceEndDate = null;
                        newRow.notes = `# of invoices ${drTotalInvoice}`;
                        newRow.bankId = null;
                        newRow.bondId = null;
                        newRow.groupKey = 15000;
                        this.results.push(newRow);

                        const d_currentAmount = (drTotalBondAmount) ? drTotalBondAmount.toLocaleString('en', decimalFormat) : `0.00`;
                        const d_fee = (drTotalAmount) ? drTotalAmount.toLocaleString('en', decimalFormat) : `0.00`;

                        const newExcel = new InvoiceBankSuretyExcel();
                        newExcel.name = `Total for Date Range`;
                        newExcel.company = ``;
                        newExcel.community = ``;
                        newExcel.bondNumber = null;
                        newExcel.currentAmount = +drTotalBondAmount < 0 ? `($${d_currentAmount})` : `$${d_currentAmount}`;
                        newExcel.rate = null;
                        newExcel.fee = +drTotalAmount < 0 ? `($${d_fee})` : `$${d_fee}`;
                        newExcel.apDate = null;
                        newExcel.invoiceStartDate = null;
                        newExcel.invoiceEndDate = null;
                        newExcel.notes = `# of invoices ${drTotalInvoice}`;
                        this.excelResultSet.push(newExcel);
                    }
                }

                // This list is to exclude the last rows of data i.e. Totals in the matches count
                this.filteredList = this.results.filter(function (value) {
                    return value.bondId != null;
                });

                //  end of data load for report and excel !
                this.showResults = true;
                if (this.results.length === 0) {
                    this.showNoResultsMessage = true;
                }
            });
    }

    resetParam(): InvoiceBankSuretyParam {
        const param = new InvoiceBankSuretyParam();
        param.bankId = -1;
        return param;
    }

    sendChildMessage(messageArray: string[]) {
        this.messageService.add({ severity: messageArray[0], summary: messageArray[1], detail: messageArray[2] });
    }

    validateDateRange(param: InvoiceBankSuretyParam): any {
        if (param.fromDateAp === '' || param.fromDateAp === null || param.fromDateAp === undefined) {
            return { valid: false, message: `Please enter a from date.` };
        }

        if (param.toDateAp === '' || param.toDateAp === null || param.toDateAp === undefined) {
            return { valid: false, message: `Please enter a to date.` };
        }
        if (param.fromDateAp !== null && param.toDateAp !== null && param.fromDateAp > param.toDateAp) {
            return { valid: false, message: `Please specify a From and To date range with 'From Accounts Payable Date' less than the 'To Accounts Payable Date'` };
        }

        return { valid: true, message: `Meets minimum parameters.` };
    }
}
