import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { switchMap } from 'rxjs/operators';

import { AmountType, Bank, BankWithAmountTypes, Broker } from '../../models';
import { AmountTypeEnum } from '../../models/enums';
import { BankService } from '../../services';

@Component({
    selector: 'app-create-bank',
    templateUrl: './create-bank.component.html',
    styleUrls: ['./create-bank.component.css'],
    providers: [MessageService]
})
export class CreateBankComponent implements OnInit {
    @Input() amountTypes: AmountType[] = [];
    @Input() brokerList: Broker[] = [];
    @Input() displayDialog: boolean = false;
    @Input() modifierId: number = 0;

    @Output() childEvent = new EventEmitter();
    @Output() onDialogClose: EventEmitter<any> = new EventEmitter();
    @Output() sendMessageEmit = new EventEmitter();

    //  global vars!
    bankOrSurety: string = '0';
    bankOrSuretyTypes: any[] = [
        { label: `-- Select Type --`, field: `0` },
        { label: `Bank`, field: `1` },
        { label: `Surety`, field: `2` }
    ];
    bankRecord: BankWithAmountTypes = null;
    isFinancials: boolean = false;
    isSaveready: boolean = false;
    maxAmount: number = 999999999999.99; // 999 billion instead of 922337203685477.58 = Max value for SQL DB Type Money
    rateMaxAmount: number = 10000; // Equals 100% due to (bps)
    selectedAmountTypes: string[] = [];


    constructor(private bankService: BankService,
        private currencyPipe: CurrencyPipe,
        private datePipe: DatePipe,
        private messageService: MessageService) { }

    ngOnInit(): void {
        //  Initialize empty bank record to fill in data as we go !
        //  init set bank record IsSurety flag to false to start !
        this.bankRecord = new BankWithAmountTypes();
        this.bankRecord.modifierId = this.modifierId;
    }

    closeCreateBankModal(): void {
        this.bankRecord = new BankWithAmountTypes();
        this.resetLocalObjects();
        this.onDialogClose.emit(this.displayDialog);
    }

    onActiveChange(event: any, ctrl: any): void {
        if (this.bankRecord.dateIndemnityAgreement === '' || this.bankRecord.dateIndemnityAgreement === undefined ||
            this.bankRecord.dateIndemnityAgreement === null) {
            this.bankRecord.isActive = false;
            event.checked = false;
            ctrl.checked = false;
            this.messageService.add({
                severity: 'error',
                summary: 'Bank Active Error',
                detail: `An Agreement Date is required for a bank or surety to be flagged as Active.`
            });
            return;
        } else {
            this.bankRecord.isActive = true;
            ctrl.checked = true;
        }
    }

    onBankDescriptionChange(event: any): void {
        this.bankRecord.description = event.target.value;
    }

    onBrokerChange(event: any): void {
        this.bankRecord.brokerId = event.target.value;
        this.onFieldChange();
    }

    onCounterSignatureRequirementsChange(event: any): void {
        this.bankRecord.counterSignatureRequirements = event.target.value;
    }

    onDateIndemnityAgreementChange(): void {
        this.bankRecord.isActive = (this.bankRecord.dateIndemnityAgreement) ? true : false;
        this.onFieldChange();
    }

    onDateIndemnityAgreementSelect(): void {
        this.bankRecord.isActive = (this.bankRecord.dateIndemnityAgreement) ? true : false;
        this.onFieldChange();
    }

    onDateIndemnityAgreementActive(ctrl: any): void {
        this.bankRecord.isActive = (this.bankRecord.dateIndemnityAgreement) ? true : false;
        ctrl.checked = (this.bankRecord.dateIndemnityAgreement) ? true : false;
    }

    onFieldChange() {
        if (this.bankRecord.name && this.bankRecord.dateIndemnityAgreement && this.bankRecord.amountTypes && this.bankRecord.commitAmount &&
            (this.bankRecord.commitAmount >= 0 || this.bankRecord.commitAmount <= 0) && this.bankRecord.rate && this.bankRecord.isSurety == false) {
            this.isSaveready = true;
        } else if (this.bankRecord.name && this.bankRecord.dateIndemnityAgreement && this.bankRecord.amountTypes &&
            (this.bankRecord.commitAmount >= 0 || this.bankRecord.commitAmount <= 0) && this.bankRecord.rate &&
            this.bankRecord.isSurety && !this.bankRecord.brokerId) {
            this.isSaveready = false;
        } else if (this.bankRecord.name && this.bankRecord.dateIndemnityAgreement && this.bankRecord.amountTypes &&
            (this.bankRecord.commitAmount >= 0 || this.bankRecord.commitAmount <= 0) && this.bankRecord.rate &&
            this.bankRecord.isSurety && this.bankRecord.brokerId) {
            this.isSaveready = true;
        } else {
            this.isSaveready = false;
        }
    }

    onInputCommitAmount(event: any): void {
        if (event.value > this.maxAmount) {
            this.messageService.add({
                severity: 'error', summary: 'Error',
                detail: 'Commit Amount is out of range. Please set amount to less than ' +
                    this.currencyPipe.transform(this.maxAmount, 'USD', 'symbol', '1.2-2') + '.'
            });
            this.bankRecord.commitAmount = 0;
            return;
        }
        this.bankRecord.commitAmount = event.value;
        this.onFieldChange();
    }

    onInputFinancialGuaranteeCommitAmount(event: any): void {
        if (event.value > this.maxAmount) {
            this.messageService.add({
                severity: 'error', summary: 'Error',
                detail: 'Financial Guarantee Commit Amount is out of range. Please set amount to less than ' +
                    this.currencyPipe.transform(this.maxAmount, 'USD', 'symbol', '1.2-2') + '.'
            });
            this.bankRecord.financialGuaranteeCommitAmount = 0;
            return;
        }
        this.bankRecord.financialGuaranteeCommitAmount = event.value;
    }

    onInputFinancialGuaranteeRate(event: any): void {
        this.bankRecord.financialGuaranteeRate = event.value;
    }

    onInputMinimumFeeCharged(event: any): void {
        if (event.value > this.maxAmount) {
            this.messageService.add({
                severity: 'error', summary: 'Error',
                detail: 'Minimum Fee Charged is out of range. Please set amount to less than ' +
                    this.currencyPipe.transform(this.maxAmount, 'USD', 'symbol', '1.2-2') + '.'
            });
            this.bankRecord.minimumFee = 0;
            return;
        }
    }

    onInputRate(event: any): void {
        if (event.value > this.rateMaxAmount) {
            this.messageService.add({
                severity: 'error', summary: 'Error',
                detail: 'Rate (bps) is out of range. Please set amount to less than ' + this.currencyPipe.transform(this.rateMaxAmount, 'USD', 'symbol', '1.2-2') + '.'
            });
            this.bankRecord.rate = 0;
            return;
        }
        this.bankRecord.rate = event.value;
        this.onFieldChange();
    }

    onInputSingleBondLimitAmount(event: any): void {
        if (event.value > this.maxAmount) {
            this.messageService.add({
                severity: 'error', summary: 'Error',
                detail: 'Single Bond Limit Amount is out of range. Please set amount to less than ' +
                    this.currencyPipe.transform(this.maxAmount, 'USD', 'symbol', '1.2-2') + '.'
            });
            this.bankRecord.minimumFee = 0;
            return;
        }
    }

    onNameChange(event: any): void {
        this.bankRecord.name = event.target.value;
        this.onFieldChange();
    }

    onPowerOfAttorneyChange(event: any): void {
        this.bankRecord.isPowerOfAttorney = event.checked;
    }

    onRevolverChange(event: any): void {
        this.bankRecord.isRevolver = event.checked;
    }

    onSelectedAmountTypeChange(): void {
        this.isFinancials = false;
        this.bankRecord.amountTypes = [];
        for (const amountType of this.selectedAmountTypes) {
            if (Number(amountType) === AmountTypeEnum.FinancialGuarantee) {
                this.isFinancials = true;
                this.bankRecord.financialGuaranteeCommitAmount = (this.bankRecord.financialGuaranteeCommitAmount) ? this.bankRecord.financialGuaranteeCommitAmount : 0;
                this.bankRecord.financialGuaranteeRate = (this.bankRecord.financialGuaranteeRate) ? this.bankRecord.financialGuaranteeRate : 0;
            }
            this.bankRecord.amountTypes.push(Number(amountType));
        }
        this.onFieldChange();
    }

    onTermsChange(event: any): void {
        this.bankRecord.terms = event.target.value;
    }

    onTypeChange(event: any): void {
        this.bankOrSurety = event.target.value;
        if (this.bankOrSurety === '1') {
            this.bankRecord.isSurety = false;
        } else if (this.bankOrSurety === '2') {
            this.bankRecord.isSurety = true;
        } else {
            this.bankRecord.isSurety = null;
        }
        this.onFieldChange();

    }

    onSubmit(): void {
        this.bankService.getBanks(true).pipe(
            switchMap((results: Bank[]) => {
                const result = results.find(x => x.name === this.bankRecord.name);
                //  validate the bank name is exist !
                if (result) {
                    throw `The Bank/Surety name: ${result?.name} already exist. Please pick another.`;
                }

                const validator = this.validatedBank(this.bankRecord);
                if (validator.valid === false) {
                    throw validator.message;
                }
                //  before sending to API, make sure that Broker ID is 0 if IsSurety is false !
                //  on the flip side, if Is Surety however, make sure the IsRevolver is False !
                if (this.bankRecord.isSurety == false) {
                    this.bankRecord.brokerId = 0;
                } else {
                    this.bankRecord.isRevolver = false;
                }
                //  make sure for whatever reason the Financial Guarantee type is not selected at this point then
                //  financial guarantee commit amount and rate will be null !
                if (this.isFinancials == false) {
                    this.bankRecord.financialGuaranteeCommitAmount = null;
                    this.bankRecord.financialGuaranteeRate = null;
                }
                // Convert agreementDate to a string to avoid timezone related issues
                this.bankRecord.dateIndemnityAgreement = this.datePipe.transform(this.bankRecord.dateIndemnityAgreement, 'MM/dd/yyyy');
                return this.bankService.addBankWithAmountTypes(this.bankRecord);
            })
        ).subscribe((result: number) => {
            this.sendMessage(
                'success',
                this.bankRecord.isSurety === true ? `Surety Record Added` : `Bank Record Added`,
                this.bankRecord.isSurety === true ? `Your Surety has been created.` : `Your Bank has been created.`
            );
        }, (err: any) => {
            this.sendMessage('error', 'Invalid Bank Record', err);
            this.sendMessage('error', 'Bank/Surety Record Creation Error', `Your Bank/Surety was not created.`);
        }, () => {
            //  cleanups, inits, last chores to do !
            //  re-init the record
            this.bankRecord = new BankWithAmountTypes();
            this.resetLocalObjects();
            //  clouse out !            
            this.onDialogClose.emit(this.displayDialog);
        });
    }

    resetLocalObjects(): void {
        this.selectedAmountTypes = [];
        this.bankOrSurety = '0';
        this.isFinancials = false;
        this.isSaveready = false;
    };

    sendMessage(severity: string, summary: string, detail: string): void {
        this.sendMessageEmit.emit([severity, summary, detail]);
    }

    validatedBank(bankRecord: Bank): any {
        if (bankRecord.name === null || bankRecord.name === '' || bankRecord.name === undefined) {
            return { valid: false, message: `Please enter a name for this Bank or Surety.` };
        }

        if (bankRecord.commitAmount === null) {
            return { valid: false, message: `Please enter a commited amount.` };
        }

        if (bankRecord.commitAmount < 0) {
            return { valid: false, message: `Please enter a valid commit amount.` };
        }

        if (bankRecord.singleBondLimit < 0) {
            return { valid: false, message: `Please enter a valid single bond limit amount.` };
        }

        if (bankRecord.rate < 0) {
            return { valid: false, message: `Please enter a valid rate value.` };
        }

        if (bankRecord.minimumFee < 0) {
            return { valid: false, message: `Please enter a valid minimum fee amount.` };
        }

        if (bankRecord.financialGuaranteeCommitAmount < 0) {
            return { valid: false, message: `Please enter a valid financial guarantee commit amount.` };
        }

        if (bankRecord.financialGuaranteeRate < 0) {
            return { valid: false, message: `Please enter a valid financial guarantee rate value.` };
        }

        if (bankRecord.commitAmount < bankRecord.financialGuaranteeCommitAmount) {
            return { valid: false, message: `Financial Guarantee Commit Amount must not be more than the Commit Amount.` };
        }

        if (this.bankRecord.singleBondLimit > this.bankRecord.commitAmount) {
            return { valid: false, message: `Single Bond Limit Amount must not exceed the Commit Amount.` };
        }

        if (bankRecord.rate === null) {
            return { valid: false, message: `Please enter a rate.` };
        }

        if (this.bankRecord.amountTypes.length < 1) {
            return { valid: false, message: `Please select at least one Bond/LC type.` };
        }

        if (this.isFinancials) {
            if (this.bankRecord.financialGuaranteeCommitAmount == null) {
                return { valid: false, message: `Since the Financial Guarantee type is selected, then please enter a Financial Guarantee Commit Amount.` };
            }
        }

        if (bankRecord.isSurety === null) {
            return { valid: false, message: `Please select a type.` };
        }

        if (bankRecord.isSurety === true) {
            if (this.bankRecord.brokerId === null || this.bankRecord.brokerId === 0) {
                return { valid: false, message: `Please select a Broker for this Surety.` };
            }
        }

        if (bankRecord.isActive === null) {
            return { valid: false, message: `Please either check or uncheck the active checkbox.` };
        }

        if (bankRecord.isPowerOfAttorney === null) {
            return { valid: false, message: `Please either check or uncheck Power Of Attorney.` };
        }

        if (bankRecord.isRevolver === null) {
            return { valid: false, message: `Please either check or uncheck Bank Revolver.` };
        }

        if (bankRecord.dateIndemnityAgreement === null || bankRecord.dateIndemnityAgreement === '' ||
            bankRecord.dateIndemnityAgreement === undefined) {
            return { valid: false, message: `Please enter an Agreement date.` };
        }

        if (bankRecord.isActive) {
            if (bankRecord.dateIndemnityAgreement === null || bankRecord.dateIndemnityAgreement === '' ||
                bankRecord.dateIndemnityAgreement === undefined) {
                return { valid: false, message: `Cannot activate this bank record if the Agreement date is not entered.` };
            }
        }

        return { valid: true, message: '' };
    }
}
