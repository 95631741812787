import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { User } from 'src/models';
import { SharedService } from './shared.service';

@Injectable()
export class UserService {
    constructor(public http: HttpClient, private sharedService: SharedService) { }

    getUser(userName: string): Observable<User> {
        const opts = this.sharedService.getOptsApplication();
        return this.http.get<User>(environment.baseUrl + `users/${userName}`, opts);
    }
}
