import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import * as XLSX from 'exceljs/dist/exceljs.min.js';
import { MessageService } from 'primeng/api';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { CommonFunctions } from 'src/functions';
import {
    AmountType,
    BankWithAmountTypes,
    Broker,
    CombinedSummaryParam,
    CombinedSummaryReport,
    CombinedSummaryResult,
    ReportColumn,
} from 'src/models';

import {
    AmountTypeService,
    AuthorizationService,
    BankAmountTypeService,
    BankService,
    BrokerService,
    ExcelService,
    ReportService,
} from '../../../services';

@Component({
    selector: 'app-combined-summary',
    templateUrl: './combined-summary.component.html',
    styleUrls: ['./combined-summary.component.css']
})

export class CombinedSummaryComponent implements OnInit {
    amountTypeList: AmountType[] = [];
    bankAverageRow: CombinedSummaryResult;
    bankFilteredList: CombinedSummaryResult[] = [];
    bankFormatedExportList: CombinedSummaryReport[] = [];
    bankReportTitle: string;
    bankResults: CombinedSummaryResult[] = [];
    bankTotalRow: CombinedSummaryResult;
    brokers: Broker[] = [];
    cols: ReportColumn[] = [];
    combinedSummaryParam: CombinedSummaryParam;
    displayBankEditDialog: boolean = false;
    exportFileName: string = '';
    exportHeader: string[] = [];
    selectedBroker: number = -1;
    selectedBank: BankWithAmountTypes = null;
    selectedBankAmountTypes: string[] = [];
    showBankResults: boolean = false;
    showNoBanksResultsMessage: boolean = false;
    showNoSuretyResultsMessage: boolean = false;
    showSuretyResults: boolean = false;
    suretyAverageRow: CombinedSummaryResult;
    suretyFilteredList: CombinedSummaryResult[] = [];
    suretyFormatedExportList: CombinedSummaryReport[] = [];
    suretyReportTitle: string;
    suretyResults: CombinedSummaryResult[] = [];
    suretyTotalRow: CombinedSummaryResult;

    constructor(private amountTypeService: AmountTypeService,
        private authorizationService: AuthorizationService,
        private bankAmountTypeService: BankAmountTypeService,
        private bankService: BankService,
        private brokerService: BrokerService,
        private commonFunctions: CommonFunctions,
        private datePipe: DatePipe,
        private excelService: ExcelService,
        private messageService: MessageService,
        private reportService: ReportService) { }

    ngOnInit(): void {
        this.authorizationService.checkBlcUserAccess();
        this.combinedSummaryParam = new CombinedSummaryParam();

        this.cols = [
            { field: 'isSurety', header: 'Bank/Surety', dataType: 'boolean' },
            { field: 'name', header: 'Name', link: 'bankId' },
            { field: 'bondCount', header: 'Bond/LC Count' },
            { field: 'commitAmount', header: 'Commitment', dataType: 'currency', widthPercentage: 12 },
            { field: 'availability', header: 'Availability', dataType: 'currency', widthPercentage: 12 },
            { field: 'outstandingAmount', header: 'Outstanding Amount', dataType: 'currency', widthPercentage: 12 },
            { field: 'availabilityChangeFromPriorMonth', header: 'Chg from Prior Mo.', dataType: 'currency', widthPercentage: 12 },
            { field: 'rate', header: 'Std Rate' },
            { field: 'financialGuaranteeRate', header: 'FG Rate' },
            { field: 'averageRate', header: 'Avg Rate' },
            { field: 'totalCost', header: 'Total Cost', dataType: `currency`, widthPercentage: 10 },
        ];

        this.amountTypeService.getAmountTypes()
            .subscribe((result: AmountType[]) => {
                this.amountTypeList = result;
                this.amountTypeList.sort((a, b) => a.name.localeCompare(b.name));
            }, () => {
                this.messageService.add({
                    severity: 'error', summary: 'Unexpected Error Occured',
                    detail: 'An error occurred getting Amount Types.'
                });
            });

        this.brokerService.getBrokers()
            .subscribe((result: Broker[]) => {
                const defaultBroker = new Broker();
                defaultBroker.id = -1;
                defaultBroker.name = `-- All --`;
                this.brokers.push(defaultBroker);
                result.sort((a, b) => a.name.localeCompare(b.name));
                for (const mybroker of result) {
                    this.brokers.push(mybroker);
                }
            });
    }

    exportExcel(): void {
        const workbook = new XLSX.Workbook();
        const worksheet = workbook.addWorksheet('Report Results');
        const regexAlpha = /[a-zA-Z]/g;

        if (this.showSuretyResults) {
            // Add title row
            const suretyTitleRow = worksheet.addRow([this.suretyReportTitle]);
            worksheet.mergeCells('A1:K1');
            worksheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' };

            // Set font, size and style in title row
            suretyTitleRow.font = { name: 'Calibri', family: 4, size: 16, underline: 'double', bold: true };

            // Blank Row
            worksheet.addRow([]);

            // Set column widths to fix header row
            worksheet.columns.forEach((column) => {
                column.width = 22;
            });

            // Add Header Row
            const headerRow = worksheet.addRow(this.exportHeader);

            // Wrap and align the header text
            headerRow.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };

            // Set header row height
            headerRow.height = 60;

            // Fill and Border Headers
            headerRow.eachCell((cell) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'D3D3D3' },
                    bgColor: { argb: '' }
                };
                cell.border = { top: { style: 'none' }, left: { style: 'none' }, bottom: { style: 'none' }, right: { style: 'none' } };
                cell.font = { bold: true };
            });

            let isTotalRow = false;

            if (this.suretyFormatedExportList.length > 0) {
                this.suretyFormatedExportList.forEach(dataRow => {
                    const dataStrings: string[] = [];

                    // Convert each prop into a string array
                    Object.values(dataRow).forEach((val: string) => {
                        dataStrings.push(val);
                    });

                    // Add the data row
                    const row = worksheet.addRow(dataStrings);
                    row.eachCell({ includeEmpty: true }, (cell => {
                        cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
                    }));

                    // Check if it is a eoy total row
                    if (row.getCell(2).value !== null) {
                        if (row.getCell(2).value.toString().includes('Totals') || row.getCell(2).value.toString().includes('Grand Total')) {
                            isTotalRow = true;
                        }
                    }

                    // Format the cells
                    row.eachCell((cell) => {
                        // If eoy total row, set to bold
                        if (isTotalRow) {
                            cell.font = { bold: true };
                        } else if (isTotalRow && !regexAlpha.test(cell.value.toString()) && cell.value.toString().includes('(') && cell.value.toString().includes(')')) {
                            cell.font = { color: { argb: 'FF0000' }, bold: true };
                            cell.value = cell.value.replace('-', '');
                        }
                        // If negative value, set font to red
                        if (!regexAlpha.test(cell.value.toString()) && cell.value.toString().includes('(') && cell.value.toString().includes(')')) {
                            cell.font = { color: { argb: 'FF0000' } };
                            cell.value = cell.value.replace('-', '');
                        }
                    });
                    if (isTotalRow) {
                        isTotalRow = false;
                    }
                });
            } else {
                worksheet.addRow(['No records found']);
            }
            // Add a blank row between the first and second tables
            worksheet.addRow();
        }

        if (this.showBankResults) {
            // Add title row
            const bankTitleRow = worksheet.addRow([this.bankReportTitle]);

            const rownumber = bankTitleRow._number;
            worksheet.mergeCells('A' + rownumber + ':K' + rownumber);
            worksheet.getCell('A' + rownumber).alignment = { vertical: 'middle', horizontal: 'center' };

            // Set font, size and style in title row
            bankTitleRow.font = { name: 'Calibri', family: 4, size: 16, underline: 'double', bold: true };

            // Blank Row
            worksheet.addRow([]);

            // Set column widths to fix header row
            worksheet.columns.forEach((column) => {
                column.width = 22;
            });

            // Add Header Row
            const headerRow = worksheet.addRow(this.exportHeader);

            // Wrap and align the header text
            headerRow.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };

            // Set header row height
            headerRow.height = 60;

            // Fill and Border Headers
            headerRow.eachCell((cell) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'D3D3D3' },
                    bgColor: { argb: '' }
                };
                cell.border = { top: { style: 'none' }, left: { style: 'none' }, bottom: { style: 'none' }, right: { style: 'none' } };
                cell.font = { bold: true };
            });

            let isTotalRow = false;

            if (this.bankFormatedExportList.length > 0) {
                this.bankFormatedExportList.forEach(dataRow => {
                    const dataStrings: string[] = [];

                    // Convert each prop into a string array
                    Object.values(dataRow).forEach((val: string) => {
                        dataStrings.push(val);
                    });

                    // Add the data row
                    const row = worksheet.addRow(dataStrings);
                    row.eachCell({ includeEmpty: true }, (cell => {
                        cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
                    }));

                    // Check if it is a eoy total row
                    if (row.getCell(2).value !== null) {
                        if (row.getCell(2).value.toString().includes('Totals') || row.getCell(2).value.toString().includes('Grand Total')) {
                            isTotalRow = true;
                        }
                    }

                    // Format the cells
                    row.eachCell((cell) => {
                        // If eoy total row, set to bold
                        if (isTotalRow) {
                            cell.font = { bold: true };
                        } else if (isTotalRow && !regexAlpha.test(cell.value.toString()) && cell.value.toString().includes('(') && cell.value.toString().includes(')')) {
                            cell.font = { color: { argb: 'FF0000' }, bold: true };
                            cell.value = cell.value.replace('-', '');
                        }
                        // If negative value, set font to red
                        if (!regexAlpha.test(cell.value.toString()) && cell.value.toString().includes('(') && cell.value.toString().includes(')')) {
                            cell.font = { color: { argb: 'FF0000' } };
                            cell.value = cell.value.replace('-', '');
                        }
                    });
                    if (isTotalRow) {
                        isTotalRow = false;
                    }
                });
            } else {
                worksheet.addRow(['No records found']);
            }
        }
        this.excelService.exportAsExcelFile(workbook, this.exportFileName);
    }

    onBankModalEditDialogClose(): void {
        this.displayBankEditDialog = false;
        this.selectedBankAmountTypes = [];
        this.selectedBank = null;
    }

    onGettingBankToView(bankId: number): void {
        try {
            const dataFetchList = [];
            const paramBankId = bankId;
            dataFetchList.push(this.bankService.getBankWithCommitment(paramBankId));
            dataFetchList.push(this.bankAmountTypeService.getBankAmountTypeByBank(paramBankId));
            forkJoin(dataFetchList)
                .subscribe((fetchedData: any[]) => {
                    this.selectedBank = fetchedData[0];
                    const batResults = fetchedData[1];
                    this.selectedBank.amountTypes = [];
                    //  loop through the bank amoutn types result from selected bank !
                    for (const bat of batResults) {
                        this.selectedBankAmountTypes.push(`${bat.amountTypeId}`);
                        this.selectedBank.amountTypes.push(bat.amountTypeId);
                    }
                    //  display the edit bank dialog !
                    this.displayBankEditDialog = true;
                }, () => {
                    if (this.selectedBankAmountTypes == null) {
                        this.messageService.add({
                            severity: 'error',
                            summary: 'Bank Amount Types Not Loaded',
                            detail: `Please be advised, unable to get Bank Amount Types for ${this.selectedBank.name}.`
                        });
                        this.selectedBankAmountTypes = null;
                        this.displayBankEditDialog = true;
                    }
                });
        } catch {
            this.messageService.add({
                severity: 'error',
                summary: 'Unexpected Error Occured',
                detail: 'An error occurred during multiple process after getting the bank or surety record.'
            });
        }
    }

    onLinkClickEvent(event: any): void {
        const resultArray = event.data.split('_');
        const id = resultArray[1];

        this.onGettingBankToView(id);
    }

    onResetClick(): void {
        this.combinedSummaryParam = new CombinedSummaryParam();
    }

    onSearchClick(): void {
        // hide previous results
        this.showBankResults = false;
        this.showSuretyResults = false;
        this.showNoBanksResultsMessage = false;
        this.showNoSuretyResultsMessage = false;
        this.bankTotalRow = new CombinedSummaryResult();
        this.bankAverageRow = null
        this.bankFilteredList = [];
        this.bankFormatedExportList = [];
        this.bankResults = [];
        this.suretyTotalRow = new CombinedSummaryResult();
        this.suretyAverageRow = null;
        this.suretyFilteredList = [];
        this.suretyFormatedExportList = []
        this.suretyResults = [];

        // Set default date if none is provided
        if (this.combinedSummaryParam.asOfDate == (undefined || null)) {
            this.combinedSummaryParam.asOfDate = new Date();
        }
        this.setDatesToUtc();

        // Reset hidden params based on selected type
        if (this.combinedSummaryParam.type == 1) {
            this.combinedSummaryParam.broker = -1;
        } else {
            this.combinedSummaryParam.isRevolver = -1;
        }

        // Set export file name
        this.exportFileName = 'CombinedSummary';

        // Reset the header row
        this.exportHeader = [];

        // Set header row values for export
        for (const col of this.cols) {
            this.exportHeader.push(col.header);
        }

        this.reportService.getCombinedSummaryReport(this.combinedSummaryParam).subscribe((results: CombinedSummaryResult[]) => {
            // Format the date objects to remove timestamps
            results.forEach((result) => {
                this.commonFunctions.trimTimeFromDatePropertiesInObject(result);
            });
            const decimalFormat = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
            let totalFGRate: number = 0;
            let totalAvgRate: number = 0;

            // bond / surety
            if (this.combinedSummaryParam.type == 0 || this.combinedSummaryParam.type == -1) {
                this.suretyReportTitle = 'Bond Combined Summary Report as of ' + this.datePipe.transform(this.combinedSummaryParam.asOfDate, 'MM/dd/yyyy');

                this.suretyResults = results.filter(res => res.isSurety == true);
                this.suretyFilteredList = JSON.parse(JSON.stringify(this.suretyResults));

                if (this.suretyResults.length != 0) {
                    this.suretyTotalRow.name = 'Bond Totals';
                    let availabilitySum: number = 0;

                    this.suretyResults.forEach(surety => {
                        this.commonFunctions.trimTimeFromDatePropertiesInObject(surety);
                        surety.outstandingAmount = surety.commitAmount - Number(surety.availability);
                        surety.rate = parseFloat(surety.rate.toFixed(2));
                        surety.financialGuaranteeRate = parseFloat(surety.financialGuaranteeRate.toFixed(2));
                        surety.averageRate = parseFloat(surety.averageRate.toFixed(2));

                        this.suretyTotalRow.bondCount += surety.bondCount;
                        this.suretyTotalRow.commitAmount += surety.commitAmount;
                        this.suretyTotalRow.outstandingAmount += surety.outstandingAmount;
                        this.suretyTotalRow.availabilityChangeFromPriorMonth += surety.availabilityChangeFromPriorMonth;
                        this.suretyTotalRow.rate += surety.rate;
                        this.suretyTotalRow.financialGuaranteeRate += surety.financialGuaranteeRate;
                        this.suretyTotalRow.averageRate += surety.averageRate;
                        this.suretyTotalRow.totalCost += surety.totalCost;

                        availabilitySum += Number(surety.availability);
                    });

                    this.suretyTotalRow.availability = availabilitySum;
                    this.suretyTotalRow.rate = Number((this.suretyTotalRow.rate / this.suretyResults.length).toFixed(2));
                    this.suretyTotalRow.financialGuaranteeRate = Number((this.suretyTotalRow.financialGuaranteeRate / this.suretyResults.length).toFixed(2));
                    this.suretyTotalRow.averageRate = Number((this.suretyTotalRow.averageRate / this.suretyResults.length).toFixed(2));
                    this.suretyTotalRow.groupKey = 8000;

                    this.suretyAverageRow = {
                        bankId: null,
                        isSurety: null,
                        name: null,
                        bondCount: null,
                        commitAmount: null,
                        availability: 'Average Amount',
                        outstandingAmount: this.suretyTotalRow.outstandingAmount / this.suretyResults.length,
                        availabilityChangeFromPriorMonth: null,
                        rate: null,
                        financialGuaranteeRate: null,
                        averageRate: null,
                        totalCost: null,
                        groupKey: 9000
                    };

                    this.suretyResults.push(this.suretyTotalRow);
                    this.suretyResults.push(this.suretyAverageRow);

                    this.suretyResults.forEach(result => {
                        const exportRes = new CombinedSummaryReport();
                        exportRes.isSurety = result.isSurety !== null ? (result.isSurety ? 'S' : 'B') : '';
                        exportRes.name = result.name;
                        exportRes.bondCount = result.bondCount?.toString();
                        exportRes.commitAmount = result.commitAmount != null ? (result.commitAmount < 0 ? '($' + result.commitAmount.toLocaleString('en', decimalFormat) + ')' : '$' + result.commitAmount.toLocaleString('en', decimalFormat)) : '';
                        exportRes.availability = typeof (result.availability) == 'number' ? (+result.availability < 0 ? '($' + result.availability.toLocaleString('en', decimalFormat) + ')' : '$' + result.availability.toLocaleString('en', decimalFormat)) : result.availability;
                        exportRes.outstandingAmount = result.outstandingAmount < 0 ? '($' + result.outstandingAmount.toLocaleString('en', decimalFormat) + ')' : '$' + result.outstandingAmount.toLocaleString('en', decimalFormat);
                        exportRes.availabilityChangeFromPriorMonth = result.availabilityChangeFromPriorMonth != null ? (result.availabilityChangeFromPriorMonth < 0 ?
                            '($' + result.availabilityChangeFromPriorMonth.toLocaleString('en', decimalFormat) + ')' : '$' + result.availabilityChangeFromPriorMonth.toLocaleString('en', decimalFormat)) : '';
                        exportRes.rate = result.rate != null ? result.rate.toLocaleString('en', decimalFormat) : '';
                        exportRes.financialGuaranteeRate = result.financialGuaranteeRate != null ? result.financialGuaranteeRate.toLocaleString('en', decimalFormat) : '';
                        totalFGRate += result.financialGuaranteeRate;
                        exportRes.averageRate = result.averageRate != null ? result.averageRate.toLocaleString('en', decimalFormat) : '';
                        totalAvgRate += result.averageRate;
                        exportRes.totalCost = result.totalCost != null ? (result.totalCost < 0 ? '($' + result.totalCost.toLocaleString('en', decimalFormat) + ')' : '$' + result.totalCost.toLocaleString('en', decimalFormat)) : '';

                        this.suretyFormatedExportList.push(exportRes)
                    });
                } else {
                    this.showNoSuretyResultsMessage = true;
                }
                this.showSuretyResults = true;
            }

            // LOC / bank
            if (this.combinedSummaryParam.type == 1 || this.combinedSummaryParam.type == -1) {
                this.bankReportTitle = 'Letter of Credit Combined Summary Report as of ' + this.datePipe.transform(this.combinedSummaryParam.asOfDate, 'MM/dd/yyyy');

                this.bankResults = results.filter(res => res.isSurety == false);
                this.bankFilteredList = JSON.parse(JSON.stringify(this.bankResults));

                if (this.bankResults.length != 0) {
                    this.bankTotalRow.name = 'Letter of Credit Totals';
                    let availabilitySum: number = 0;

                    this.bankResults.forEach(bank => {
                        bank.outstandingAmount = bank.commitAmount - Number(bank.availability);
                        bank.rate = bank.rate ? parseFloat(bank.rate.toFixed(2)) : 0;
                        bank.financialGuaranteeRate = bank.financialGuaranteeRate ? parseFloat(bank.financialGuaranteeRate.toFixed(2)) : 0;
                        bank.averageRate = bank.averageRate ? parseFloat(bank.averageRate.toFixed(2)) : 0;

                        this.bankTotalRow.bondCount += bank.bondCount;
                        this.bankTotalRow.commitAmount += bank.commitAmount;
                        this.bankTotalRow.outstandingAmount += bank.outstandingAmount;
                        this.bankTotalRow.availabilityChangeFromPriorMonth += bank.availabilityChangeFromPriorMonth;
                        this.bankTotalRow.rate += bank.rate;
                        this.bankTotalRow.financialGuaranteeRate += bank.financialGuaranteeRate;
                        this.bankTotalRow.averageRate += bank.averageRate;
                        this.bankTotalRow.totalCost += bank.totalCost;

                        availabilitySum += Number(bank.availability);
                    });

                    this.bankTotalRow.availability = availabilitySum;
                    this.bankTotalRow.rate = Number((this.bankTotalRow.rate / this.bankResults.length).toFixed(2));
                    this.bankTotalRow.financialGuaranteeRate = Number((this.bankTotalRow.financialGuaranteeRate / this.bankResults.length).toFixed(2));
                    this.bankTotalRow.averageRate = Number((this.bankTotalRow.averageRate / this.bankResults.length).toFixed(2));
                    this.bankTotalRow.groupKey = 8000;

                    this.bankAverageRow = {
                        bankId: null,
                        isSurety: null,
                        name: null,
                        bondCount: null,
                        commitAmount: null,
                        availability: 'Average Amount',
                        outstandingAmount: this.bankTotalRow.outstandingAmount / this.bankResults.length,
                        availabilityChangeFromPriorMonth: null,
                        rate: null,
                        financialGuaranteeRate: null,
                        averageRate: null,
                        totalCost: null,
                        groupKey: 9000
                    };

                    this.bankResults.push(this.bankTotalRow);
                    this.bankResults.push(this.bankAverageRow);

                    this.bankResults.forEach(result => {
                        const exportRes = new CombinedSummaryReport();
                        exportRes.isSurety = result.isSurety !== null ? (result.isSurety ? 'S' : 'B') : '';
                        exportRes.name = result.name;
                        exportRes.bondCount = result.bondCount?.toString();
                        exportRes.commitAmount = result.commitAmount != null ? (result.commitAmount < 0 ? '($' + result.commitAmount.toLocaleString('en', decimalFormat) + ')' : '$' + result.commitAmount.toLocaleString('en', decimalFormat)) : '';
                        exportRes.availability = typeof (result.availability) == 'number' ? (+result.availability < 0 ? '($' + result.availability.toLocaleString('en', decimalFormat) + ')' : '$' + result.availability.toLocaleString('en', decimalFormat)) : result.availability;
                        exportRes.outstandingAmount = result.outstandingAmount < 0 ? '($' + result.outstandingAmount.toLocaleString('en', decimalFormat) + ')' : '$' + result.outstandingAmount.toLocaleString('en', decimalFormat);
                        exportRes.availabilityChangeFromPriorMonth = result.availabilityChangeFromPriorMonth != null ? (result.availabilityChangeFromPriorMonth < 0 ?
                            '($' + result.availabilityChangeFromPriorMonth.toLocaleString('en', decimalFormat) + ')' : '$' + result.availabilityChangeFromPriorMonth.toLocaleString('en', decimalFormat)) : '';
                        exportRes.rate = result.rate != null ? result.rate.toLocaleString('en', decimalFormat) : '';
                        exportRes.financialGuaranteeRate = result.financialGuaranteeRate != null ? result.financialGuaranteeRate.toLocaleString('en', decimalFormat) : '';
                        totalFGRate += result.financialGuaranteeRate;
                        exportRes.averageRate = result.averageRate != null ? result.averageRate.toLocaleString('en', decimalFormat) : '';
                        totalAvgRate += result.averageRate;
                        exportRes.totalCost = result.totalCost != null ? (result.totalCost < 0 ? '($' + result.totalCost.toLocaleString('en', decimalFormat) + ')' : '$' + result.totalCost.toLocaleString('en', decimalFormat)) : '';

                        this.bankFormatedExportList.push(exportRes)
                    });
                } else {
                    this.showNoBanksResultsMessage = true;
                }
                this.showBankResults = true;
            }

            if (this.showSuretyResults && this.showBankResults) {
                const grandTotalRow: CombinedSummaryReport = new CombinedSummaryReport();
                grandTotalRow.isSurety = null;
                grandTotalRow.name = 'Grand Total:';
                grandTotalRow.bondCount = (this.suretyTotalRow.bondCount + this.bankTotalRow.bondCount).toString();
                grandTotalRow.commitAmount = this.suretyTotalRow.commitAmount + this.bankTotalRow.commitAmount < 0 ?
                    '($' + (this.suretyTotalRow.commitAmount + this.bankTotalRow.commitAmount).toLocaleString('en', decimalFormat) + ')' :
                    '$' + (this.suretyTotalRow.commitAmount + this.bankTotalRow.commitAmount).toLocaleString('en', decimalFormat);
                grandTotalRow.availability = +this.suretyTotalRow.availability + +this.bankTotalRow.availability < 0 ?
                    '($' + (+this.suretyTotalRow.availability + +this.bankTotalRow.availability).toLocaleString('en', decimalFormat) + ')' :
                    '$' + (+this.suretyTotalRow.availability + +this.bankTotalRow.availability).toLocaleString('en', decimalFormat);
                grandTotalRow.outstandingAmount = this.suretyTotalRow.outstandingAmount + this.bankTotalRow.outstandingAmount < 0 ?
                    '($' + (this.suretyTotalRow.outstandingAmount + this.bankTotalRow.outstandingAmount).toLocaleString('en', decimalFormat) + ')' :
                    '$' + (this.suretyTotalRow.outstandingAmount + this.bankTotalRow.outstandingAmount).toLocaleString('en', decimalFormat);
                grandTotalRow.availabilityChangeFromPriorMonth = this.suretyTotalRow.availabilityChangeFromPriorMonth + this.bankTotalRow.availabilityChangeFromPriorMonth < 0 ?
                    '($' + (this.suretyTotalRow.availabilityChangeFromPriorMonth + this.bankTotalRow.availabilityChangeFromPriorMonth).toLocaleString('en', decimalFormat) + ')' :
                    '$' + (this.suretyTotalRow.availabilityChangeFromPriorMonth + this.bankTotalRow.availabilityChangeFromPriorMonth).toLocaleString('en', decimalFormat);
                grandTotalRow.rate = this.suretyTotalRow.rate + this.bankTotalRow.rate < 0 ?
                    '($' + (this.suretyTotalRow.rate + this.bankTotalRow.rate).toLocaleString('en', decimalFormat) + ')' :
                    '$' + (this.suretyTotalRow.rate + this.bankTotalRow.rate).toLocaleString('en', decimalFormat);
                grandTotalRow.financialGuaranteeRate = this.suretyTotalRow.financialGuaranteeRate + this.bankTotalRow.financialGuaranteeRate < 0 ?
                    '(' + (totalFGRate / (this.suretyResults.length + this.bankResults.length)).toLocaleString('en', decimalFormat) + ')' :
                    (totalFGRate / (this.suretyResults.length + this.bankResults.length)).toLocaleString('en', decimalFormat);
                grandTotalRow.averageRate = this.suretyTotalRow.financialGuaranteeRate + this.bankTotalRow.financialGuaranteeRate < 0 ?
                    '(' + (totalAvgRate / (this.suretyResults.length + this.bankResults.length)).toLocaleString('en', decimalFormat) + ')' :
                    (totalAvgRate / (this.suretyResults.length + this.bankResults.length)).toLocaleString('en', decimalFormat);
                grandTotalRow.totalCost = this.suretyTotalRow.totalCost + this.bankTotalRow.totalCost < 0 ?
                    '($' + (this.suretyTotalRow.totalCost + this.bankTotalRow.totalCost).toLocaleString('en', decimalFormat) + ')' :
                    '$' + (this.suretyTotalRow.totalCost + this.bankTotalRow.totalCost).toLocaleString('en', decimalFormat);

                const blankRow: CombinedSummaryReport = {
                    isSurety: null,
                    name: null,
                    bondCount: null,
                    commitAmount: null,
                    availability: null,
                    outstandingAmount: null,
                    availabilityChangeFromPriorMonth: null,
                    rate: null,
                    financialGuaranteeRate: null,
                    averageRate: null,
                    totalCost: null
                };

                this.bankFormatedExportList.push(blankRow);
                this.bankFormatedExportList.push(grandTotalRow);

                const outstandingCalc: number = (this.suretyAverageRow.outstandingAmount + this.bankAverageRow.outstandingAmount) / 2;
                const grandAvgRow: CombinedSummaryReport = {
                    isSurety: null,
                    name: null,
                    bondCount: null,
                    commitAmount: null,
                    availability: 'Average Amount',
                    outstandingAmount: outstandingCalc < 0 ?
                        '($' + outstandingCalc.toLocaleString('en', decimalFormat) + ')' :
                        '$' + outstandingCalc.toLocaleString('en', decimalFormat),
                    availabilityChangeFromPriorMonth: null,
                    rate: null,
                    financialGuaranteeRate: null,
                    averageRate: null,
                    totalCost: null
                };

                this.bankFormatedExportList.push(grandAvgRow);
            }
        });
    }

    setDatesToUtc(): void {
        if (this.combinedSummaryParam.asOfDate) {
            const utcd = this.commonFunctions.formatDateMmddyyyy(new Date(this.combinedSummaryParam.asOfDate).toISOString());
            this.combinedSummaryParam.asOfDate = utcd;
        }
    }
}
