import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Bank, Bond, Community, Company } from 'src/models';
import { BLCTypeEnum, BondStatusEnum } from 'src/models/enums';
import { v4 as uuidv4 } from 'uuid';

@Injectable()
export class CommonFunctions {
    constructor(private datePipe: DatePipe) { }

    convertMarketOrCommunityToNumerics(stringValue: string): number {
        return !isNaN(parseInt(stringValue)) ? parseInt(stringValue) : 0;
    }

    convertToBase64(bytes: Uint8Array) {
        let binary = '';
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }

    formatDateMmddyyyy(date: string) {
        const d = new Date(date);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        const year = d.getFullYear();

        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }

        return [month, day, year].join('/');
    }

    generateUuid() {
        return uuidv4();
    }

    getBondStatus(bond: Bond): any {
        let result = {
            modifiedBond: null,
            isStatusCanceled: false
        };

        result.modifiedBond = bond;

        if (Number(bond.isActive) === 1 && Number(bond.isExpiring) === 1) {
            bond.status = BondStatusEnum.Expiring;
            result.isStatusCanceled = false;
        } else if (Number(bond.isActive) === 1) {
            bond.status = BondStatusEnum.Active;
            result.isStatusCanceled = false;
        } else if (Number(bond.isExpired) === 1) {
            bond.status = BondStatusEnum.Expired;
            result.isStatusCanceled = false;
        } else if (Number(bond.isExpiring) === 1) {
            bond.status = BondStatusEnum.Expiring;
            result.isStatusCanceled = false;
        } else if (Number(bond.isCanceled) === 1) {
            bond.status = BondStatusEnum.Canceled;
            result.isStatusCanceled = true;
        } else if (Number(bond.isPending) === 1) {
            bond.status = BondStatusEnum.Pending;
            result.isStatusCanceled = false;
        }

        result.modifiedBond = bond;
        return result;
    }

    getBondType(isLetterOfCredit: boolean): BLCTypeEnum {
        if (isLetterOfCredit === false) {
            return BLCTypeEnum.Bond;
        } else {
            return BLCTypeEnum.LetterOfCredit;
        }
    }

    getByteArray(file) {
        return new Promise(function (resolve, reject) {
            const reader = new FileReader();
            reader.onload = function (event) {
                resolve(event.target.result);
            };
            reader.onerror = reject; // call reject if error
            reader.readAsArrayBuffer(file);
        });
    }

    groupBy(objectArray: any[], property: string) {
        return objectArray.reduce(function (acc, obj) {
            var key = obj[property];
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(obj);
            return acc;
        }, {});
    }

    isBondTypeLetterOfCredit(bondType: number): boolean {
        if (bondType === 0) {
            return false;
        } else {
            return true;
        }
    }

    isDateValid(dateString: string) {
        // First check for the pattern
        if (!/^\d{1,2}[\-\s\/]\d{1,2}[\-\s\/]\d{4}$/.test(dateString)) {
            return false;
        }

        // Parse the date parts to integers
        const parts = dateString.split('/');
        const day = parseInt(parts[1], 10);
        const month = parseInt(parts[0], 10);
        const year = parseInt(parts[2], 10);

        // Check the ranges of month and year
        if (year < 1000 || year > 3000 || month == 0 || month > 12) {
            return false;
        }

        const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

        // Adjust for leap years
        if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0)) {
            monthLength[1] = 29;
        }

        // Check the range of the day
        return day > 0 && day <= monthLength[month - 1];
    }

    isFloat(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    isPositiveInteger(str: string) {
        if (typeof str !== 'string') {
            return false;
        }

        if (str.includes('.')) {
            return false;
        }

        const num = Number(str);

        if (Number.isInteger(num) && num > 0) {
            return true;
        }

        return false;
    }

    isMMDDYYYYDate(s) {
        if (s === null || s === '' || s === undefined) {
            return false;
        }

        if (! /^\d\d\/\d\d\/\d\d\d\d$/.test(s)) {
            const testVal = this.datePipe.transform(s, 'MM/dd/yyyy');
            if (! /^\d\d\/\d\d\/\d\d\d\d$/.test(testVal)) {
                return false;
            } else {
                // ensure transform did not pad zeros
                if (new Date(testVal).getFullYear() > 1969) {
                    s = testVal;
                } else {
                    return false;
                }
            }
        }

        const parts = s.split('/').map((p) => parseInt(p, 10));
        parts[0] -= 1;
        const d = new Date(parts[2], parts[0], parts[1]);
        return d.getMonth() === parts[0] && d.getDate() === parts[1] && d.getFullYear() === parts[2];
    }

    setSingleBondLimit(bankList: Bank[], bondData: Bond): any {
        const result = bankList.find(e => e.id == bondData.bankId);
        if (result.singleBondLimit != null) {
            return { sbl: result.singleBondLimit.toFixed(2) };
        } else {
            return { sbl: `` };
        }
    }

    sortAndFormatCommunities(communityList: Community[]): Community[] {
        // Convert EDH Community Number string to a number for sorting
        communityList.forEach(comm => {
            try {
                comm.communityNumberConverted = +comm.communityNumber;
            } catch (ex) {
                // CommunityNumber could not be parsed, do nothing 
            }
        });

        // Sort by converted Community Number
        communityList = communityList.sort((a, b) => a.communityNumberConverted - b.communityNumberConverted);

        return communityList;
    }


    sortAndFormatCompanies(companyList: Company[]): Company[] {
        // Convert EDH Market Number string to a number for sorting
        companyList.forEach(comp => {
            try {
                comp.convertedMarketNumber = +comp.marketNumber;
            } catch (ex) {
                // MarketNumber could not be parsed, do nothing 
            }
        });

        // Sort by converted Market Number
        companyList = companyList.sort((a, b) => a.convertedMarketNumber - b.convertedMarketNumber);

        return companyList;
    }

    trimTimeFromDatePropertiesInObject(object: any): any {
        const keys = Object.keys(object);

        for (const key in keys) {
            const prop = keys[key];
            
            if (prop.toLowerCase().indexOf('date') > -1) {
                if (object[prop]?.toString() !== '0001-01-01T00:00:00') {
                    object[prop] = object[prop]?.toString().split('T')[0];
                }
                else {
                    object[prop] = null;
                }
            }
        }
        return object;
    }
}
