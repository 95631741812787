import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { Community } from '../../models';
import { SharedService } from './shared.service';

@Injectable()
export class CommunityService {

    constructor(public http: HttpClient, private sharedService: SharedService) { }

    getCommunities(companyNumber: string): Observable<Community[]> {
        const opts = this.sharedService.getOptsApplication();
        return this.http.get<Community[]>(environment.baseUrl + `communities/byCompanyNumber/${companyNumber}`, opts);
    }

    getCommunityByCommunityNumber(communityNumber: string): Observable<Community> {
        const opts = this.sharedService.getOptsApplication();
        return this.http.get<Community>(environment.baseUrl + `communities/byCommunityNumber/${communityNumber}`, opts);
    }
}
