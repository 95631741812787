export class Broker {
    constructor() {
        this.id = null;
        this.name = '';
        this.dateCreated = '';
        this.dateModified = '';
        this.modifierId = 0;
    }
    id: number;
    name: string;
    dateCreated: string | Date;
    dateModified: string | Date;
    modifierId: number;
}
