import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { Invoice, InvoiceUploadParam, InvoiceUploadResult } from './../../models';
import { SharedService } from './shared.service';

@Injectable()
export class InvoiceService {

    constructor(public http: HttpClient, private sharedService: SharedService) { }

    addInvoice(invoice: Invoice): Observable<Invoice> {
        const opts = this.sharedService.getOptsJson();
        const body = JSON.stringify(invoice);
        return this.http.post<Invoice>(environment.baseUrl + `invoice/createInvoice?`, body, opts);
    }

    checkInvoicesForUpload(invoiceUploadParam: InvoiceUploadParam): Observable<InvoiceUploadResult[]> {
        const opts = this.sharedService.getOptsJson();
        const body = JSON.stringify(invoiceUploadParam);
        return this.http.post<InvoiceUploadResult[]>(environment.baseUrl + `invoice/checkInvoices`, body, opts);
    }

    getInvoice(id: number): Observable<Invoice> {
        const opts = this.sharedService.getOptsApplication();
        return this.http.get<Invoice>(environment.baseUrl + `invoice/${id}`, opts);
    }

    importInvoices(fileId: number): Observable<number> {
        const opts = this.sharedService.getOptsApplication();
        return this.http.get<number>(environment.baseUrl + `invoice/import/${fileId}`, opts);
    }

    removeInvoice(id: number): Observable<void> {
        const opts = this.sharedService.getOptsJson();
        const removeUrl = `${environment.baseUrl}invoice/${id}`;
        return this.http.delete<void>(removeUrl, opts);
    }

    updateInvoice(invoiceupdate: Invoice): Observable<Invoice> {
        const opts = this.sharedService.getOptsJson();
        const body = JSON.stringify(invoiceupdate);
        return this.http.put<Invoice>(environment.baseUrl + `invoice/updateInvoice?`, body, opts);
    }
}
