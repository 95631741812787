import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';

import { SECURE_LOCAL_STORAGE } from '../classes/app.constants';
import { ISecureStorage } from '../classes/interfaces/isecurestorage';
import { Token } from '../models';
import { ClaimsEnum } from '../models/enums';
import { config } from './../app/authConfig';

@Injectable()
export class AuthorizationService {
    isFullAccessUser: boolean = false;
    isLimitedAccessUser: boolean = false;
    isReadOnlyUser: boolean = false;

    private cachedRoles: string[] = null;
    private cachedUserName: string = null;
    private storageKey: string = "blcAuthToken";
    private token: Token = null;

    constructor(private oAuthService: OAuthService, private router: Router, @Inject(SECURE_LOCAL_STORAGE) private storage: ISecureStorage) {
        console.log('AuthorizationService:constructor()');

        this.oAuthService.configure(config);
        this.oAuthService.timeoutFactor = 0.3;
        this.oAuthService.setupAutomaticSilentRefresh();
        this.oAuthService.setStorage(localStorage);
        this.oAuthService.loadDiscoveryDocumentAndTryLogin().then(() => {
            if (!this.authenticated()) {
                this.login();
            }
        });
    }

    authenticated(): boolean {
        console.log('AuthorizationService:authenticated()-' + this.oAuthService.hasValidIdToken());

        return !!this.oAuthService.hasValidIdToken();
    }

    checkBlcUserAccess(isFullAccessRequired: boolean = false): void {
        if ((isFullAccessRequired && this.isBlcExceptionsUser()) || (!this.isBlcFullAccessUser() && !this.isBlcExceptionsUser() && !this.isBlcReadOnlyUser())) {
            alert('You are not authorized to use this page due to your access level.');
            this.router.navigate(['unauthorized']);
        }
    }

    dropCache(): void {
        this.cachedRoles = null;
        this.cachedUserName = null;
    }

    getAccessToken(): string {
        return this.oAuthService.getAccessToken();
    }

    getUsername(): string {                
        if (this.cachedUserName == null) {            
            this.cachedUserName = this.oAuthService.getIdentityClaims()?.['preferred_username'] ?? '';
        }

        return this.cachedUserName;
    }

    isBlcExceptionsUser(): boolean {
        let retval = false;
        this.isLimitedAccessUser = false;

        if (!this.cachedRoles || this.cachedRoles.length == 0) {
            this.cachedRoles = this.oAuthService.getIdentityClaims()?.['roles'] ?? [];
        }

        this.cachedRoles.forEach(element => {
            if (element?.includes(ClaimsEnum.blcexceptions)) {
                retval = true;
                this.isLimitedAccessUser = true;
            }
        });

        return retval;
    }

    isBlcFullAccessUser(): boolean {
        let retval = false;
        this.isFullAccessUser = false;

        if (!this.cachedRoles || this.cachedRoles.length == 0) {
            this.cachedRoles = this.oAuthService.getIdentityClaims()?.['roles'] ?? [];
        }

        this.cachedRoles.forEach(element => {
            if (element?.includes(ClaimsEnum.blcfullaccess)) {
                retval = true;
                this.isFullAccessUser = true;
            }
        });

        return retval;
    }

    isBlcReadOnlyUser(): boolean {
        let retval = false;
        this.isReadOnlyUser = false;

        if (!this.cachedRoles || this.cachedRoles.length == 0) {
            this.cachedRoles = this.oAuthService.getIdentityClaims()?.['roles'] ?? [];
        }

        this.cachedRoles.forEach(element => {
            if (element?.includes(ClaimsEnum.blcreadonly)) {
                retval = true;
                this.isReadOnlyUser = true;
            }
        });

        return retval;
    }

    isInRole(): boolean {
        let retval = false;

        if (!this.cachedRoles || this.cachedRoles.length == 0) {
            this.cachedRoles = this.oAuthService.getIdentityClaims()?.['roles'] ?? [];
        }

        this.cachedRoles.forEach(element => {
            if (element?.includes(ClaimsEnum.blcfullaccess) || element?.includes(ClaimsEnum.blcexceptions) || element?.includes(ClaimsEnum.blcreadonly)) {
                retval = true;
            }
        });

        return retval;
    }

    login(): void {
        this.oAuthService.initLoginFlow();        
    }

    logout(): void {
        this.oAuthService.logOut();        
    }
}
